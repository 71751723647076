import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { Box, Button, Chip, Stack } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import { useState } from 'react';

// https://mui.com/x/react-data-grid/style/ style class cell colors

function logTranslator({ row }) {
  const {
    table_name,
    action,
    new_data,
    old_data,
    affected_display_name,
    discord_display_name,
  } = row;
  switch (table_name) {
    case 'Guild_User_Manage':
      switch (action) {
        case 'INSERT': {
          // User Added to Guild
          return `Added ${affected_display_name} to guild`;
        }
        case 'UPDATE': {
          // DKP amount changed
          const dkp_calc = old_data.dkp - new_data.dkp;
          if (dkp_calc === 0) {
            return `${affected_display_name} new craft role ${new_data.in_game_craft_role}`;
          }
          if (dkp_calc > 0) {
            return `${affected_display_name} lost ${dkp_calc} DKP Total = ${new_data.dkp}`;
          } else {
            return `${affected_display_name} gained ${Math.abs(dkp_calc)} DKP Total = ${new_data.dkp}`;
          }
        }
        case 'DELETE':
          // User removed from guild
          return `Removed ${affected_display_name} from guild`;
      }
      break;
    case 'Guild_Events':
      switch (action) {
        case 'INSERT':
          // Event created
          return `${discord_display_name} created event - ${new_data.title}`;

        case 'UPDATE':
          // Event updated
          return `${discord_display_name} updated event - ${new_data.title}`;
        case 'DELETE':
          // Event deleted
          return `${discord_display_name} deleted event - ${old_data.title}`;
      }
      break;

    case 'Guild_Event_Attendance':
      switch (action) {
        case 'INSERT':
          // Attendance / Interest registered
          return `${affected_display_name} signed up to event`;
        case 'UPDATE':
          // Attendance marked
          return `${affected_display_name} finished an event`;
        case 'DELETE':
          // Attendance removed
          return `${affected_display_name} attendance removed`;
      }
      break;
    case 'Guild_Admins':
      switch (action) {
        case 'INSERT':
          // Guild Admin created
          return `${affected_display_name} added admin role`;
        case 'UPDATE':
          // Case shouldn't exist
          break;
        case 'DELETE':
          // Admin deleted
          return `${affected_display_name} removed admin role`;
      }
      break;
    case 'Guild_Applications':
      switch (action) {
        case 'INSERT':
          // Guild application submitted
          return `${affected_display_name} created guild application`;
        case 'UPDATE':
          // Application responded
          return `${discord_display_name} responded to application - ${new_data.status}`;
        case 'DELETE':
          // Application deleted
          return `${affected_display_name}'s application deleted`;
      }
      break;
    case 'Guilds':
      switch (action) {
        case 'INSERT':
          // Case won't exist
          break;
        case 'UPDATE':
          // Guild updated
          return `${discord_display_name} updated guild info`;
        case 'DELETE':
          // Case won't exist
          break;
      }
      break;
  }
}

const CustomToolbar = ({ setFilterModel }) => {
  return (
    <Stack sx={{ p: 1 }} direction="row">
      <Button
        variant="outlined"
        startIcon={<RestartAltIcon />}
        onClick={() => setFilterModel({ items: [] })} // clear filter
      >
        Reset Filters
      </Button>
    </Stack>
  );
};

export default function GuildLogsChart({ data }) {
  const [filterModel, setFilterModel] = useState({
    items: [],
  });
  if (!data) {
    return null;
  }

  const columns = [
    {
      field: 'created_at',
      headerName: 'Event Date',
      headerAlign: 'left',
      align: 'left',
      flex: 0.5,
      minWidth: 220,
      renderCell: (params) =>
        dayjs(params.value).format('MMMM D, YYYY [at] h:mm A'),
    },
    {
      field: 'action',
      headerName: 'Action',
      flex: 0.2,
      minWidth: 100,
      renderCell: (params) =>
        params.value ? (
          <Chip
            label={params.value}
            color={
              (params.value === 'INSERT' && 'success') ||
              (params.value === 'UPDATE' && 'warning') ||
              (params.value === 'DELETE' && 'error') ||
              'default'
            }
            variant="outlined"
          />
        ) : (
          'not provided'
        ),
      // type: 'string',
    },

    {
      field: 'discord_display_name',
      headerName: 'Discord Name',
      flex: 0.5,
      minWidth: 100,
      renderCell: (params) => params.value || 'System',

      // type: 'string',
    },
    {
      field: 'table_name',
      headerName: 'Type',
      flex: 0.5,
      minWidth: 100,
      renderCell: (params) =>
        `${logTranslator({
          row: params.row,
        })}`,
    },
    {
      field: 'discord_id',
      headerName: 'Discord ID',
      flex: 0.5,
      minWidth: 100,
      renderCell: (params) => params.value || 'N/A',
    },
  ];

  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        tableLayout: 'fixed',
        // display: 'table',
      }}
    >
      <DataGrid
        align="center"
        // hideFooter
        // checkboxSelection
        // @ts-ignore
        columns={columns}
        getRowId={(item) => item.id}
        rows={data}
        initialState={{
          sorting: {
            sortModel: [{ field: 'created_at', sort: 'desc' }],
          },
          pagination: { paginationModel: { pageSize: 20 } },
        }}
        pageSizeOptions={[10, 20, 50]}
        slotProps={{ toolbar: { setFilterModel } }}
        slots={{
          toolbar: CustomToolbar,
        }}
        filterModel={filterModel}
        onFilterModelChange={(newModel) => setFilterModel(newModel)}

        // getRowClassName={(params) =>
        //   params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
        // }
        // disableColumnResize
        // density="compact"

        // slotProps={{
        //   filterPanel: {
        //     filterFormProps: {
        //       logicOperatorInputProps: {
        //         variant: 'outlined',
        //         size: 'small',
        //       },
        //       columnInputProps: {
        //         variant: 'outlined',
        //         size: 'small',
        //         sx: { mt: 'auto' },
        //       },
        //       operatorInputProps: {
        //         variant: 'outlined',
        //         size: 'small',
        //         sx: { mt: 'auto' },
        //       },
        //       valueInputProps: {
        //         InputComponentProps: {
        //           variant: 'outlined',
        //           size: 'small',
        //         },
        //       },
        //     },
        //   },
        // }}
      />
    </Box>
  );
}
