import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import BackpackIcon from '@mui/icons-material/Backpack';
import BookmarksIcon from '@mui/icons-material/Bookmarks';
import GroupIcon from '@mui/icons-material/Group';
import HomeIcon from '@mui/icons-material/Home';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import SportsMmaIcon from '@mui/icons-material/SportsMma';
import Bard from 'assets/icons/Bard.png';
import Cleric from 'assets/icons/Cleric.png';
import Fighter from 'assets/icons/Fighter.png';
import Mage from 'assets/icons/Mage.png';
import Ranger from 'assets/icons/Ranger.png';
import Rogue from 'assets/icons/Rogue.png';
import Tank from 'assets/icons/Tank.png';

export const STRINGS = {
  iconMap: {
    MMA: { name: 'MMA', Icon: SportsMmaIcon },
    FIRE: { name: 'FIRE', Icon: LocalFireDepartmentIcon },
    COURT: { name: 'COURT', Icon: AccountBalanceIcon },
    HOUSE: { name: 'HOUSE', Icon: HomeIcon },
    BOOK: { name: 'BOOK', Icon: BookmarksIcon },
    BAG: { name: 'BAG', Icon: BackpackIcon },
    GROUP: { name: 'GROUP', Icon: GroupIcon },
  },
  sessionStorageMap: {
    userSettings: 'userSettings',
    guildData: 'guildData',
    lastGuildFetch: 'lastGuildFetch',
    appData: 'appData',
  },
  classColorMap: {
    FIGHTER: '#C16766',
    TANK: '#8CD2EA',
    MAGE: '#596CE1',
    CLERIC: '#FBF986',
    ROGUE: '#b89af0',
    RANGER: '#607849',
    BARD: '#a76498',
    SUMMONER: '#EABF74',
  },
  attendanceColorMap: {
    SIGNED_UP: 'info',
    ATTENDED: 'success',
    MIA: 'error',
  },
  attendanceContextMap: {
    SIGNED_UP: 'Signed Up',
    ATTENDED: 'Attended',
    MIA: 'MIA',
  },
  eventStatusColorMap: {
    PENDING: 'warning',
    COMPLETED: 'success',
  },
  classIconMap: {
    FIGHTER: Fighter,
    TANK: Tank,
    MAGE: Mage,
    CLERIC: Cleric,
    ROGUE: Rogue,
    RANGER: Ranger,
    BARD: Bard,
  },
  classNameMap: {
    FIGHTER: 'Fighter',
    TANK: 'Tank',
    MAGE: 'Mage',
    CLERIC: 'Cleric',
    RANGER: 'Ranger',
    BARD: 'Bard',
    // ROGUE: 'Rogue',
    // SUMMONER: 'Summoner',
  },
  classRoleMap: {
    DPS: 'DPS',
    SUPPORT: 'SUPPORT',
    TANK: 'TANK',
  },
  craftingRoleMap: {
    ARCANEENGINEERING: {
      name: 'Arcane Engineering',
      icon: '🔮',
      color: '#6A0DAD', // Arcane purple
    },
    ARMORSMITHING: {
      name: 'Armor Smithing',
      icon: '🛡️',
      color: '#C0C0C0', // Metallic silver
    },
    CARPENTRY: {
      name: 'Carpentry',
      icon: '🪚',
      color: '#8B4513', // Wooden brown
    },
    LEATHERWORKING: {
      name: 'Leatherworking',
      icon: '🟫',
      color: '#A0522D', // Leather tan
    },
    JEWELER: {
      name: 'Jeweler',
      icon: '💎',
      color: '#1E90FF', // Gem blue
    },
    SCRIBE: {
      name: 'Scribe',
      icon: '✍️',
      color: '#2F4F4F', // Ink gray
    },
    TAILORING: {
      name: 'Tailoring',
      icon: '🧵',
      color: '#FF69B4', // Thread pink
    },
    WEAPONSMITHING: {
      name: 'Weapon Smithing',
      icon: '⚔️',
      color: '#B22222', // Blade red
    },
  },
  LINKS: {
    DISCORD: {
      OFFICIAL_LINK_INVITE: 'https://discord.gg/q4KRbEKvuK',
      ICON_LINK: (SERVER_ID, ICON_ID) =>
        `https://cdn.discordapp.com/icons/${SERVER_ID}/${ICON_ID}.webp`,
      BANNER_LINK: (SERVER_ID, BANNER_ID) =>
        `https://cdn.discordapp.com/banners/${SERVER_ID}/${BANNER_ID}.webp?size=240`,
      INVITE_LINK_CHECK: (ID) =>
        `https://discord.com/api/v8/invites/${ID}?with_counts=true`,
      SERVER_ID: '1295324299892363294',
      BASE_URL: 'discord://discordapp.com',
      CHANNEL: {
        SUPPORT: '1304373562873872405',
      },
    },
  },
  PATHS: {
    PERMS: {
      MEMBERS: ['GUILD_MEMBER', 'ADMIN', 'OWNER'],
      GOALS: ['GUILD_MEMBER', 'ADMIN', 'OWNER'],
      CRAFTS: ['GUILD_MEMBER', 'ADMIN', 'OWNER'],
      RAID: ['GUILD_MEMBER', 'ADMIN', 'OWNER'],
      EVENTS: ['GUILD_MEMBER', 'ADMIN', 'OWNER'],
      LOGS: ['ADMIN', 'OWNER'],
      AUCTIONS: ['GUILD_MEMBER', 'ADMIN', 'OWNER'],
      APPLICATIONS: ['MEMBER', 'ADMIN', 'OWNER'],
      TRADES: ['GUILD_MEMBER', 'ADMIN', 'OWNER'],
      CONFIG: ['OWNER'],
    },
    page404: '/error/404',
    goals: '/goals',
    crafts: '/crafts',
    maintenance: '/maintenance',
    home: '/home',
    members: '/members',

    logs: '/logs',
    auctions: '/auctions',
    config: '/config',
    raid: { root: `/raid`, details: (raid_id) => `/raid/${raid_id}` },
    events: {
      root: `/events`,
      details: (event_id) => `/events/${event_id}`,
      attendance: (event_id) => `/events/${event_id}/attendance`,
    },
    trades: {
      root: `/trades`,
      details: (trade_id) => `/trades/${trade_id}`,
    },
    applications: {
      root: `/applications`,
      apply: (guild_discord_server_id) =>
        `/applications/${guild_discord_server_id}`,
    },
  },
};

export const RULES = {
  canSeeGuildApplications: (role) =>
    ['OWNER', 'ADMIN'].includes(role.toUpperCase()),
};
