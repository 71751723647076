import { Card, Stack } from '@mui/material';
import { FullCalendarView } from 'components/calendar/FullCalendarView';

const Events = () => {
  return (
    <Stack
      sx={{
        display: 'flex',
        width: '100%',
        height: '100%',
        flexDirection: 'column',
        // gap: '8px',
        // flexGrow: 1,
        backgroundColor: 'transparent',
      }}
    >
      <Card
        variant="outlined"
        style={{
          backgroundColor: 'transparent',
          border: 'none',
          // border: '1px solid red',
          height: '100%',
          width: '100%',
        }}
      >
        <FullCalendarView />
      </Card>
    </Stack>
  );
};

export default Events;
