import { alpha } from '@mui/material';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { supabase } from 'auth/supabaseClient';
import AppNavbar from 'components/AppNavbar';
import ErrorFallback from 'components/other/ErrorFallback';
import SideMenu from 'components/SideMenu';
import { useApp } from 'context/AppContext';
import { useAuth } from 'context/AuthContext';
import { useData } from 'context/DataContext';
import 'dayjs/locale/en-gb'; // UK Locale Example
import DevOverlay from 'dev/DevOverlay';
import useInactivity from 'hooks/use-inactivity';
import 'index.css';
import { useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { BrowserRouter } from 'react-router-dom';

import { postQueryToAshesCodex } from 'api/ashes-codex';
import MaintenanceGuard from 'components/guards/MaintenanceGuard';
import AppRoutes from 'routes/routes';
import AppTheme from 'shared-theme/AppTheme';
import { isDev } from 'utils/config';
import {
  chartsCustomizations,
  dataGridCustomizations,
  datePickersCustomizations,
  treeViewCustomizations,
} from './theme/customizations';

const xThemeComponents = {
  ...chartsCustomizations,
  ...dataGridCustomizations,
  ...datePickersCustomizations,
  ...treeViewCustomizations,
};

const TestComponent = (refThis) => {
  // throw new Error('This is a test error!');
  const ref = refThis.here.map();
  return <>{ref}</>;
};

export default function App(props) {
  const { userSettings, realTime } = useData();
  const { userLocale } = useApp();
  const { user, signOut } = useAuth();
  const isInactive = useInactivity(isDev ? 600000 : 60000);

  useEffect(() => {
    if (!user || userSettings === null || isInactive) {
      return;
    }

    const changes = realTime();

    // Cleanup subscription on component unmount
    return () => {
      supabase.removeChannel(changes);
    };
  }, [user, userSettings, isInactive]); // Empty dependency array ensures it runs once on mount

  useEffect(() => {
    if (isInactive) {
      signOut();
      supabase.removeAllChannels();
    }

    return () => {};
  }, [isInactive]);

 

  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      adapterLocale={userLocale.toLowerCase()}
    >
      <AppTheme {...props} themeComponents={xThemeComponents}>
        <CssBaseline enableColorScheme />
        <Box id="firstWrapper" sx={{ display: 'flex', flex: 1 }}>
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <BrowserRouter>
              <MaintenanceGuard>
                {/* <TestComponent /> */}
                <SideMenu />
                <AppNavbar /> {/* Responsive nav */}
                {/* Main content */}
                <Box
                  style={{
                    height: '100vh',
                    padding: '16px',
                  }}
                  // component="main"
                  sx={(theme) => ({
                    // flexGrow: 1,
                    flex: 1,
                    // @ts-ignore
                    backgroundColor: theme.vars
                      ? // @ts-ignore
                        `rgba(${theme.vars.palette.background.defaultChannel} / 1)`
                      : alpha(theme.palette.background.default, 1),
                    overflow: 'hidden',
                  })}
                >
                  <AppRoutes />
                </Box>
              </MaintenanceGuard>
            </BrowserRouter>
          </ErrorBoundary>
        </Box>
      </AppTheme>
      {isDev && <DevOverlay />}
    </LocalizationProvider>
  );
}
