import { supabase } from 'auth/supabaseClient';

export async function sendEventEmbed({
  peopleToNotify,
  event_title,
  event_location_link,
  event_description,
  dkp_reward,
  mandatory_bool,
  event_start,
  created_by,
  created_at,
  channelToSend,
  event_id,
}) {
  const response = await supabase.functions.invoke('postEventEmbed', {
    body: {
      peopleToNotify,
      event_title,
      event_description,
      dkp_reward,
      mandatory_bool,
      event_start,
      created_by,
      created_at,
      event_id,
      channelToSend,
      event_location_link,
    },
  });

  if (response.error) {
    console.error('Error invoking function:', response.error.message);
    return null;
  } else {
    return response.data;
  }
}
