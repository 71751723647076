import { BarChart } from '@mui/x-charts';
import { Card } from '@mui/material';
import { STRINGS } from 'components/config';

const { classColorMap } = STRINGS;

const chartSetting = {
  //   xAxis: [
  //     {
  //       label: 'rainfall (mm)',
  //     },
  //   ],
  //   height: 400,
};

export const CustomBarStackChart = ({ data, applyFilter }) => {
  const colorArray = data.map(({ label }) => classColorMap[label]);

  return (
    <Card
      variant="outlined"
      sx={{
        display: 'flex',
        flex: 0.3,
        flexDirection: 'column',
        // gap: '8px',
        flexGrow: 1,
      }}
    >
      <BarChart
        margin={{ left: 60 }}
        barLabel={(item) => item.value?.toString()}
        borderRadius={6}
        dataset={data}
        onAxisClick={(e, axis) =>
          applyFilter({
            columnField: 'in_game_class',
            searchValue: axis.axisValue,
          })
        }
        xAxis={[
          {
            tickMinStep: 1,
          },
        ]}
        yAxis={[
          {
            tickMinStep: 1,
            scaleType: 'band',
            dataKey: 'label',
            colorMap: {
              type: 'ordinal',
              colors: colorArray,
            },
          },
        ]}
        series={[{ dataKey: 'value' }]}
        layout="horizontal"
        {...chartSetting}
      />
    </Card>
  );
};
