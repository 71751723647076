import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Stepper,
  Step,
  StepLabel,
  Card,
  CardContent,
  Button,
  Divider,
} from '@mui/material';
import { styled } from '@mui/system';
import {
  PeopleRounded as PeopleRoundedIcon,
  Groups as GroupsIcon,
  CalendarMonth as CalendarMonthIcon,
  ViewTimeline as ViewTimelineIcon,
  AssignmentRounded as AssignmentRoundedIcon,
} from '@mui/icons-material';
import { RiAuctionFill } from 'react-icons/ri';
import { FaList, FaDiscord } from 'react-icons/fa';
import StepConnector, {
  stepConnectorClasses,
} from '@mui/material/StepConnector';
import { STRINGS } from './config';

const { LINKS } = STRINGS;

// Qonto Connector
const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#FFA726',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#FB8C00',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: '#e0e0e0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

// Step Icon Component
const QontoStepIconRoot = styled('div')(({ ownerState }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: ownerState.active || ownerState.completed ? '#FFA726' : '#e0e0e0',
}));

function QontoStepIcon({ icon, active, completed, className }) {
  return (
    <QontoStepIconRoot ownerState={{ active, completed }} className={className}>
      {icon}
    </QontoStepIconRoot>
  );
}
const fontSize = 'medium';
const faSize = '28px';
// Steps data with icons and subtitles
const steps1 = [
  {
    label: 'Applications',
    subtitle: 'Handle new member requests with ease',
    icon: <AssignmentRoundedIcon fontSize={fontSize} />,
  },
  {
    label: 'Members',
    subtitle: 'Keep track of your guild’s roster and manage roles',
    icon: <PeopleRoundedIcon fontSize={fontSize} />,
  },
  {
    label: 'Goals',
    subtitle: 'Set team objectives and track your progress together',
    icon: <PeopleRoundedIcon fontSize={fontSize} />,
  },
  {
    label: 'Raid Management',
    subtitle: 'Plan and execute raids efficiently',
    icon: <GroupsIcon fontSize={fontSize} />,
  },
  {
    label: 'Events',
    subtitle:
      'Schedule, manage, reward (dkp) and remind your team about important events',
    icon: <CalendarMonthIcon fontSize={fontSize} />,
  },
];

const steps2 = [
  {
    label: 'Crafts',
    subtitle: 'Organise crafting needs and materials for your team',
    icon: <ViewTimelineIcon fontSize={fontSize} />,
  },
  {
    label: 'Auctions',
    subtitle: 'Run in-guild auctions to distribute loot fairly',
    icon: <RiAuctionFill fontSize={'large'} />,
  },
  {
    label: 'Trades',
    subtitle: 'Facilitate item exchanges between members seamlessly',
    icon: <RiAuctionFill fontSize={'large'} />,
  },
  {
    label: 'Logs',
    subtitle: 'Track activity and ensure accountability within the guild',
    icon: <FaList fontSize={fontSize} />,
  },
];

const HomePage = () => {
  // State for active steps
  const [activeStep1, setActiveStep1] = useState(1);
  const [activeStep2, setActiveStep2] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setActiveStep1((prev) => (prev + 1) % steps1.length);
      setActiveStep2((prev) => (prev + 1) % steps2.length);
    }, 3000); // Update every 2 seconds

    return () => clearInterval(timer); // Cleanup on unmount
  }, []);

  return (
    <Box>
      {/* About Section */}
      <Box
        sx={{
          padding: 4,
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          textAlign: 'center',
          width: '100%',
        }}
      >
        <Box
          sx={{
            width: '40%',
            flexDirection: 'column',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography variant="h4">AOC GM</Typography>
          <Divider style={{ width: '30%' }} />
          <Divider sx={{ width: '30%', mb: 1 }} />
          <Typography variant="button" gutterBottom>
            ASHES OF CREATION GUILD MANGER
          </Typography>
          <Typography variant="body" color="text.secondary">
            {`Whether you're leading a large community (raids) or a small teams (parties), our platform provides you with the tools to organise, collaborate, and succeed. From managing events and tracking goals to facilitating trades and auctions, AOC GM has you covered.`}
          </Typography>
        </Box>
      </Box>

      {/* Features Section with Steppers */}
      <Box
        sx={{
          padding: 4,
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Divider flexItem sx={{ mt: 2, mb: 4 }} />
        <Typography variant="h4" gutterBottom>
          Features
        </Typography>
        {/* <Divider sx={{ mt: 0, mb: 2, width: '10%' }} /> */}
        {/* Stepper 1 */}
        <Stepper
          alternativeLabel
          connector={<QontoConnector />}
          activeStep={activeStep1}
        >
          {steps1.map((step, index) => (
            <Step key={step.label}>
              <StepLabel
                StepIconComponent={(props) => (
                  <QontoStepIcon {...props} icon={step.icon} />
                )}
              >
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                  {step.label}
                </Typography>
                <Typography
                  variant="caption"
                  color="text.secondary"
                  sx={{ display: 'block', marginTop: '4px' }}
                >
                  {step.subtitle}
                </Typography>
              </StepLabel>
            </Step>
          ))}
        </Stepper>

        <Box sx={{ marginY: 4 }} />

        {/* Stepper 2 */}
        <Stepper
          alternativeLabel
          connector={<QontoConnector />}
          activeStep={activeStep2}
        >
          {steps2.map((step, index) => (
            <Step key={step.label}>
              <StepLabel
                StepIconComponent={(props) => (
                  <QontoStepIcon {...props} icon={step.icon} />
                )}
              >
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                  {step.label}
                </Typography>
                <Typography
                  variant="caption"
                  color="text.secondary"
                  sx={{ display: 'block', marginTop: '4px' }}
                >
                  {step.subtitle}
                </Typography>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>

      {/* Footer - Get in Touch Card */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: 4,
          marginBottom: 4,
          padding: 4,
        }}
        id="get-in-touch"
      >
        <Card
          sx={{
            width: 300,
            textAlign: 'center',
            transition: 'all 0.3s ease',
            '&:hover': {
              transform: 'scale(1.05)',
              boxShadow: 10,
            },
            boxShadow: 5,
          }}
        >
          <CardContent>
            <Typography variant="h6" gutterBottom>
              Get in Touch
            </Typography>
            <Typography variant="body2" color="text.secondary" gutterBottom>
              Have questions or want to learn more?
            </Typography>
            <Button
              variant="contained"
              color="primary"
              startIcon={<FaDiscord />}
              href={LINKS.DISCORD.OFFICIAL_LINK_INVITE}
              sx={{ marginTop: 2 }}
            >
              Join the Discord
            </Button>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

export default HomePage;
