// src/context/AuthContext.jsx
import { supabase } from 'auth/supabaseClient';
import { STRINGS } from 'components/config';
import { useAuth } from 'context/AuthContext';
import { createContext, useContext, useState } from 'react';
import { useLogger } from 'utils/logger';
import {
  clearSessionStorage,
  getInitialData,
  writeToSessionStorage,
} from 'utils/sessionStorage';
import { handleSupabaseRequest } from 'utils/supabase';
import { generateShortUUID } from 'utils/uuid';

const { sessionStorageMap } = STRINGS;

const DataContext = createContext({});

const supabaseTableMap = {
  guildEventAttendance: 'Guild_Event_Attendance',
  guildUserManage: 'Guild_User_Manage',
  guilds: 'Guilds',
  userSensitive: 'User_Sensitive',
  users: 'Users',
};

// Table Utils

export const DataProvider = ({ children }) => {
  const { user } = useAuth();
  const logger = useLogger();

  const [fetching, setFetching] = useState(false);
  const [userSettings, setUserSettings] = useState(() =>
    getInitialData(sessionStorageMap.userSettings)
  );

  const [guildData, setGuildData] = useState(() =>
    getInitialData(sessionStorageMap.guildData)
  );

  const [siteSettings, setsiteSettings] = useState(
    getInitialData('siteSettings')
  );

  function writesiteSettings(status) {
    setsiteSettings(status);
    writeToSessionStorage({
      location: 'siteSettings',
      data: status,
    });
  }

  ///////////////////////////// Table Util /////////////////////////////////////////
  async function insertRowTable({
    table,
    data,
    errorToLog,
    returnData = false, // Default to false if not provided
  }) {
    const response = await supabase
      .from(table) // Change "profiles" to your actual table name
      .insert([{ ...data }]) // Ensure data is wrapped in an array
      .select(); // Select all columns of the inserted row(s)

    if (response.error === null) {
      // If returnData is true, include the inserted data in the response
      if (returnData) {
        return { success: true, data: response.data };
      } else {
        return { success: true };
      }
    } else {
      const errorCode = await generateShortUUID();
      logger.database.error(
        `${errorToLog} | ${response.error.message} | Error Code - ${errorCode}`
      );
      return { success: false, errorCode: errorCode };
    }
  }

  async function basicDelete({ table, fieldToMatch, matchData, errorToLog }) {
    const response = await supabase
      .from(table)
      .delete()
      .eq(fieldToMatch, matchData);

    if (response.error === null) {
      // if (wipeStorage) {
      //   clearSessionStorage();
      // }
      // if (userRefresh) {
      //   await fetchUserSettings();
      // }
      // await checkFetchGuildData();
      return { success: true };
    } else {
      const errorCode = generateShortUUID();
      logger.database.error(
        `${errorToLog} | ${response.error.message} | Error Code - ${errorCode}`
      );
      return { success: false, errorCode };
    }
  }
  async function basicUpdate({
    table,
    fieldToMatch,
    matchData,
    data,
    errorToLog,
    userRefresh = false,
  }) {
    const response = await supabase
      .from(table)
      .update(data)
      .eq(fieldToMatch, matchData);

    if (response.error === null) {
      // if (wipeStorage) {
      //   clearSessionStorage();
      // }
      if (userRefresh) {
        await fetchUserSettings();
      }
      // if (guildRefresh) {
      //   await checkFetchGuildData();
      // }

      return { success: true };
    } else {
      const errorCode = generateShortUUID();
      logger.database.error(
        `${errorToLog} | ${response.error.message} | Error Code - ${errorCode}`
      );
      return { success: false, errorCode };
    }
  }
  ///////////////////////////// Table Util /////////////////////////////////////////
  ///////////////////////////// Real Time /////////////////////////////////////////
  // Guild

  async function handleGuildMemberChange(payload) {
    const guildDataFetch = getInitialData(sessionStorageMap.guildData);
    async function handleLeaveGuild() {
      clearSessionStorage();
      await fetchUserSettings();
    }
    switch (payload.eventType) {
      case 'INSERT': {
        // New Member need to grab info
        const guildMembers = guildDataFetch.Guild_User_Manage;
        const response = await supabase
          .from('Users')
          .select()
          .eq('discord_id', payload.new.discord_id)
          .single();

        if (response.error === null) {
          guildMembers.push({ ...payload.new, Users: response.data });
        } else {
          guildMembers.push({ ...payload.new });
        }
        writeGuildData({ data: guildDataFetch });
        return;
      }
      case 'UPDATE': {
        // Can only be dkp
        const guildMembers = guildDataFetch.Guild_User_Manage;
        const obj = guildMembers.find(
          (obj) => obj.discord_id === payload.new.discord_id
        );

        if (obj) {
          obj.dkp = payload.new.dkp; // Update the age property
          obj.in_game_craft_role = payload.new.in_game_craft_role; // Update the age property
        }

        writeGuildData({ data: guildDataFetch });
        return;
      }
      case 'DELETE': {
        // If delete is self handle leaving guild
        if (payload.old.discord_id === user.user_metadata.provider_id) {
          supabase.removeAllChannels();
          await handleLeaveGuild();

          return;
        } else {
          // Else find member and remove
          const guildMembers = guildDataFetch.Guild_User_Manage;
          const index = guildMembers.findIndex(
            (obj) => obj.discord_id === payload.old.discord_id
          );
          // If the object is found, remove it from the array
          if (index !== -1) {
            guildMembers.splice(index, 1);
          }
          const newData = {
            ...guildDataFetch,
            Guild_User_Manage: guildMembers,
          };
          writeGuildData({ data: newData });
          return;
        }
      }
    }
  }

  async function handleGuildApplications(payload) {
    const guildDataFetch = getInitialData(sessionStorageMap.guildData);

    switch (payload.eventType) {
      case 'INSERT': {
        // New Application
        const guildApplications = guildDataFetch.Guild_Applications;
        guildApplications.push({ ...payload.new });
        writeGuildData({ data: guildDataFetch });
        return;
      }
      case 'UPDATE': {
        // Update application
        const guildApplications = guildDataFetch.Guild_Applications;
        const obj = guildApplications.find(
          (obj) => obj.guild_application_id === payload.new.guild_application_id
        );

        if (obj) {
          Object.assign(obj, payload.new);
        }
        writeGuildData({ data: guildDataFetch });

        return;
      }
      case 'DELETE': {
        // If delete is self handle leaving guild
        // Else find member and remove
        const guildApplications = guildDataFetch.Guild_Applications;
        const index = guildApplications.findIndex(
          (obj) => obj.guild_application_id === payload.old.guild_application_id
        );
        // If the object is found, remove it from the array
        if (index !== -1) {
          guildApplications.splice(index, 1);
        }

        writeGuildData({ data: guildDataFetch });
        return;
      }
    }
  }

  async function handleGuildEvents(payload) {
    const guildDataFetch = getInitialData(sessionStorageMap.guildData);

    switch (payload.eventType) {
      case 'INSERT': {
        // New event
        const guildEvents = guildDataFetch.Guild_Events;
        guildEvents.push({ ...payload.new, Guild_Event_Attendance: [] });
        writeGuildData({ data: guildDataFetch });
        return;
      }
      case 'UPDATE': {
        // Update Event

        const guildEvents = guildDataFetch.Guild_Events;

        const objIndex = guildEvents.findIndex(
          (obj) => obj.event_id === payload.new.event_id
        );

        if (objIndex !== -1) {
          guildEvents[objIndex] = {
            ...guildEvents[objIndex], // Keep existing properties
            ...payload.new, // Merge new properties
            Guild_Event_Attendance:
              guildEvents[objIndex].Guild_Event_Attendance, // Preserve old attendance
          };
        }

        writeGuildData({ data: guildDataFetch });

        return;
      }
      case 'DELETE': {
        const guildEvents = guildDataFetch.Guild_Events;
        const index = guildEvents.findIndex(
          (obj) => obj.event_id === payload.old.event_id
        );

        if (index !== -1) {
          guildEvents.splice(index, 1);
        }
        const newData = {
          ...guildDataFetch,
          Guild_Events: guildEvents,
        };
        writeGuildData({ data: newData });
        return;
      }
    }
  }

  async function handleGuildEventAttendance(payload) {
    const guildDataFetch = getInitialData(sessionStorageMap.guildData);

    switch (payload.eventType) {
      case 'INSERT': {
        // New event attendance
        const guildEvents = guildDataFetch.Guild_Events;
        const foundEvent = guildEvents.find(
          (event) => event.event_id === payload.new.event_id
        );
        if (!foundEvent.Guild_Event_Attendance) {
          foundEvent.Guild_Event_Attendance = [];
        }
        foundEvent.Guild_Event_Attendance.push({ ...payload.new });
        writeGuildData({ data: guildDataFetch });
        return;
      }
      case 'UPDATE': {
        // Not possible
        const guildEvents = guildDataFetch.Guild_Events;
        const foundEvent = guildEvents.find(
          (event) => event.event_id === payload.new.event_id
        );
        if (!foundEvent) return;
        const index = foundEvent.Guild_Event_Attendance.findIndex(
          (obj) => obj.id === payload.new.id
        );
        if (index !== -1) {
          foundEvent.Guild_Event_Attendance[index] = {
            ...foundEvent.Guild_Event_Attendance[index],
            ...payload.new,
          }; // Merge existing object with updated data
        }
        writeGuildData({ data: guildDataFetch });

        return;
      }
      case 'DELETE': {
        const guildEvents = guildDataFetch.Guild_Events;
        const index = guildEvents.findIndex(
          (obj) => obj.event_id === payload.old.event_id
        );

        if (index !== -1) {
          // find attendance record
          const indexEvent = guildEvents[
            index
          ].Guild_Event_Attendance.findIndex(
            (obj) => obj.id === payload.old.id
          );
          if (indexEvent !== -1) {
            guildEvents[index].Guild_Event_Attendance.splice(index, 1);
          }
        }

        writeGuildData({ data: guildDataFetch });
        return;
      }
    }
  }

  async function handleGuildDataLog(payload) {
    const guildDataFetch = getInitialData(sessionStorageMap.guildData);

    switch (payload.eventType) {
      case 'INSERT': {
        // New event attendance
        const guildLogs = guildDataFetch.Guild_Data_Log;

        guildLogs.push({ ...payload.new });
        writeGuildData({ data: guildDataFetch });
        return;
      }
      case 'UPDATE': {
        // Not possible

        return;
      }
      case 'DELETE': {
        // Not possible

        return;
      }
    }
  }

  async function handleTradeItems(payload) {
    const guildDataFetch = getInitialData(sessionStorageMap.guildData);

    switch (payload.eventType) {
      case 'INSERT': {
        // New event attendance
        const guildTrades = guildDataFetch.Guild_Trade_Items;

        guildTrades.push({ ...payload.new });
        writeGuildData({ data: guildDataFetch });
        return;
      }
      case 'UPDATE': {
        // Not possible

        return;
      }
      case 'DELETE': {
        const guildTrades = guildDataFetch.Guild_Trade_Items;

        const index = guildTrades.findIndex(
          (obj) => obj.trade_id === payload.old.trade_id
        );
        // If the object is found, remove it from the array
        if (index !== -1) {
          guildTrades.splice(index, 1);
        }
        writeGuildData({ data: guildDataFetch });

        return;
      }
    }
  }

  async function handleGuildInfoChange(payload) {
    const guildDataFetch = getInitialData(sessionStorageMap.guildData);

    switch (payload.eventType) {
      case 'INSERT': {
        // Hmmm
      }
      case 'UPDATE': {
        writeGuildData({ data: { ...guildDataFetch, ...payload.new } });

        return;
      }
      case 'DELETE': {
        // Guild Deleted
        supabase.removeAllChannels();
        clearSessionStorage();
        await fetchUserSettings();
        return;
      }
    }
  }
  async function handleGuildAdmins(payload) {
    const guildDataFetch = getInitialData(sessionStorageMap.guildData);

    switch (payload.eventType) {
      case 'INSERT': {
        // Handle Self Upgrade
        const guildAdmins = guildDataFetch.Guild_Admins;

        guildAdmins.push({ ...payload.new });
        writeGuildData({ data: guildDataFetch });
        if (payload.new.discord_id === user.user_metadata.provider_id) {
          await fetchUserSettings();
        }
        return;
      }
      case 'UPDATE': {
        // Not Possible
        return;
      }
      case 'DELETE': {
        {
          const guildAdmins = guildDataFetch.Guild_Admins;

          const index = guildAdmins.findIndex(
            (obj) => obj.discord_id === payload.old.discord_id
          );
          // If the object is found, remove it from the array
          if (index !== -1) {
            guildAdmins.splice(index, 1);
          }
          writeGuildData({ data: guildDataFetch });
          if (payload.old.discord_id === user.user_metadata.provider_id) {
            await fetchUserSettings();
          }
          return;
        }
      }
    }
  }

  async function handleGuildPartyMembers(payload) {
    const guildDataFetch = getInitialData(sessionStorageMap.guildData);

    switch (payload.eventType) {
      case 'INSERT': {
        // Handle Self Upgrade
        const guildRaids = guildDataFetch.Guild_Raids;
        const foundRaid = guildRaids.find(
          (obj) => obj.raid_id === payload.new.raid_id
        );
        const foundParty = foundRaid.Guild_Parties.find(
          (obj) => obj.party_id === payload.new.party_id
        );
        foundParty.Guild_Party_Members.push({ ...payload.new });

        writeGuildData({ data: guildDataFetch });

        return;
      }
      case 'UPDATE': {
        // Party to Party
        const guildRaids = guildDataFetch.Guild_Raids;

        // Remove from old party
        outerLoop: for (let i = 0; i < guildRaids.length; i++) {
          const guildParties = guildRaids[i]?.Guild_Parties || [];
          for (let j = 0; j < guildParties.length; j++) {
            const members = guildParties[j]?.Guild_Party_Members || [];
            const index = members.findIndex(
              (member) => member.id === payload.new.id
            );

            if (index !== -1) {
              members.splice(index, 1); // Remove the member with the matching id
              break outerLoop; // Exit both loops
            }
          }
        }
        const foundRaid = guildRaids.find(
          (obj) => obj.raid_id === payload.new.raid_id
        );
        const foundParty = foundRaid.Guild_Parties.find(
          (obj) => obj.party_id === payload.new.party_id
        );
        foundParty.Guild_Party_Members.push({ ...payload.new });

        writeGuildData({ data: guildDataFetch });

        return;
      }
      case 'DELETE': {
        {
          const guildRaids = guildDataFetch.Guild_Raids;
          // const foundRaid = guildRaids.find(
          //   (obj) => obj.raid_id === payload.old.raid_id
          // );
          // const foundParty = foundRaid.Guild_Parties.find(
          //   (obj) => obj.party_id === payload.old.party_id
          // );
          // const index = foundParty.findIndex(
          //   (obj) => obj.discord_id === payload.old.discord_id
          // );
          // // If the object is found, remove it from the array
          // if (index !== -1) {
          //   foundParty.splice(index, 1);
          // }
          outerLoop: for (let i = 0; i < guildRaids.length; i++) {
            const guildParties = guildRaids[i]?.Guild_Parties || [];
            for (let j = 0; j < guildParties.length; j++) {
              const members = guildParties[j]?.Guild_Party_Members || [];
              const index = members.findIndex(
                (member) => member.id === payload.old.id
              );

              if (index !== -1) {
                members.splice(index, 1); // Remove the member with the matching id
                break outerLoop; // Exit both loops
              }
            }
          }
          writeGuildData({ data: guildDataFetch });

          return;
        }
      }
    }
  }

  async function handleRaids(payload) {
    const guildDataFetch = getInitialData(sessionStorageMap.guildData);

    switch (payload.eventType) {
      case 'INSERT': {
        // Handle new Raid
        const guildRaids = guildDataFetch.Guild_Raids;

        guildRaids.push({ ...payload.new, Guild_Parties: [] });

        writeGuildData({ data: guildDataFetch });

        return;
      }
      case 'UPDATE': {
        // Handle Raid update
        const guildRaids = guildDataFetch.Guild_Raids;
        const objIndex = guildRaids.findIndex(
          (obj) => obj.raid_id === payload.new.raid_id
        );

        if (objIndex !== -1) {
          guildRaids[objIndex] = {
            ...guildRaids[objIndex], // Keep existing properties
            ...payload.new, // Merge new properties
            Guild_Parties: guildRaids[objIndex].Guild_Parties, // Preserve old attendance
          };
        }

        writeGuildData({ data: guildDataFetch });

        return;
      }
      case 'DELETE': {
        {
          const guildRaids = guildDataFetch.Guild_Raids;
          const index = guildRaids.findIndex(
            (obj) => obj.raid_id === payload.old.raid_id
          );

          if (index !== -1) {
            guildRaids.splice(index, 1);
          }
          writeGuildData({ data: guildDataFetch });

          return;
        }
      }
    }
  }

  async function handleParties(payload) {
    const guildDataFetch = getInitialData(sessionStorageMap.guildData);

    switch (payload.eventType) {
      case 'INSERT': {
        // Handle new Party
        const guildRaids = guildDataFetch.Guild_Raids;
        const foundRaid = guildRaids.find(
          (obj) => obj.raid_id === payload.new.raid_id
        );
        foundRaid.Guild_Parties.push({
          ...payload.new,
          Guild_Party_Members: [],
        });

        writeGuildData({ data: guildDataFetch });

        return;
      }
      case 'UPDATE': {
        // Handle Party Update Not doing
        // writeGuildData({ data: guildDataFetch });

        return;
      }
      case 'DELETE': {
        const guildRaids = guildDataFetch.Guild_Raids;

        // Handle Delete Party
        outerLoop: for (let i = 0; i < guildRaids.length; i++) {
          const guildParties = guildRaids[i]?.Guild_Parties || [];
          const index = guildParties.findIndex(
            (party) => party.party_id === payload.old.party_id
          );
          if (index !== -1) {
            guildParties.splice(index, 1); // Remove the member with the matching id
            break outerLoop; // Exit both loops
          }
        }
        writeGuildData({ data: guildDataFetch });
        return;
      }
    }
  }

  async function handleGuildGoals(payload) {
    const guildDataFetch = getInitialData(sessionStorageMap.guildData);

    switch (payload.eventType) {
      case 'INSERT': {
        // Handle new Goal
        const guildGoals = guildDataFetch.Guild_Goals;

        guildGoals.push({ ...payload.new });

        writeGuildData({ data: guildDataFetch });

        return;
      }
      case 'UPDATE': {
        // Handle Goal update
        const guildGoals = guildDataFetch.Guild_Goals;

        const objIndex = guildGoals.findIndex(
          (obj) => obj.id === payload.new.id
        );

        if (objIndex !== -1) {
          guildGoals[objIndex] = {
            ...guildGoals[objIndex], // Keep existing properties
            ...payload.new, // Merge new properties
          };
        }

        writeGuildData({ data: guildDataFetch });

        return;
      }
      case 'DELETE': {
        // Handle Goal Delete
        const guildGoals = guildDataFetch.Guild_Goals;

        const objIndex = guildGoals.findIndex(
          (obj) => obj.id === payload.old.id
        );

        if (objIndex !== -1) {
          guildGoals.splice(objIndex, 1);
        }

        writeGuildData({ data: guildDataFetch });

        return;
      }
    }
  }

  async function handleGuildCraft(payload) {
    const guildDataFetch = getInitialData(sessionStorageMap.guildData);

    switch (payload.eventType) {
      case 'INSERT': {
        // Handle new Goal
        const guildCrafts = guildDataFetch.Guild_Crafts;

        guildCrafts.push({ ...payload.new });

        writeGuildData({ data: guildDataFetch });

        return;
      }
      case 'UPDATE': {
        // Handle Goal update
        const guildCrafts = guildDataFetch.Guild_Crafts;

        const objIndex = guildCrafts.findIndex(
          (obj) => obj.id === payload.new.id
        );

        if (objIndex !== -1) {
          guildCrafts[objIndex] = {
            ...guildCrafts[objIndex], // Keep existing properties
            ...payload.new, // Merge new properties
          };
        }

        writeGuildData({ data: guildDataFetch });

        return;
      }
      case 'DELETE': {
        // Handle Goal Delete
        const guildCrafts = guildDataFetch.Guild_Crafts;

        const objIndex = guildCrafts.findIndex(
          (obj) => obj.id === payload.old.id
        );

        if (objIndex !== -1) {
          guildCrafts.splice(objIndex, 1);
        }

        writeGuildData({ data: guildDataFetch });

        return;
      }
    }
  }

  // Guild
  // No Guild
  async function handleJoinedGuild() {
    await fetchUserSettings();
    await fetchGuildData();
  }

  async function handlePersonalApplications(payload) {
    switch (payload.eventType) {
      case 'INSERT': {
        const newData = {
          ...userSettings,
          Guild_Applications: { ...payload.new },
        };

        writeUserData({ data: newData });
        return;
      }
      case 'DELETE': {
        const newData = {
          ...userSettings,
          Guild_Applications: null,
        };

        writeUserData({ data: newData });
        return;
      }
    }
  }
  // No Guild

  function realTime() {
    if (userSettings.guild_discord_server_id === null) {
      // Not in guild
      const watcher = supabase
        .channel('watching_guild')
        .on(
          'postgres_changes',
          {
            schema: 'public', // Subscribes to the "public" schema in Postgres
            event: '*', // Listen to all changes
            table: 'Guild_User_Manage',
            filter: `discord_id=eq.${user.user_metadata.provider_id}`,
          },
          (payload) => {
            handleJoinedGuild();
            watcher.unsubscribe();
          }
        )
        .on(
          'postgres_changes',
          {
            schema: 'public', // Subscribes to the "public" schema in Postgres
            event: '*', // Listen to all changes
            table: 'Guild_Applications',
            filter: `discord_id=eq.${user.user_metadata.provider_id}`,
          },
          (payload) => {
            handlePersonalApplications(payload);
          }
        )
        .on(
          'postgres_changes',
          {
            schema: 'public', // Subscribes to the "public" schema in Postgres
            event: 'UPDATE', // Listen to all changes
            table: 'Site_Settings',
          },
          (payload) => {
            writesiteSettings(payload.new);
          }
        )
        .subscribe((status, err) => {
          if (err) {
            console.error('Subscription error:', err);
          }
        });
      return watcher;
    } else {
      const watcher = supabase
        .channel('watching_guild_in')
        .on(
          'postgres_changes',
          {
            schema: 'public', // Subscribes to the "public" schema in Postgres
            event: '*', // Listen to all changes
            table: 'Guild_User_Manage',
            filter: `guild_discord_server_id=eq.${userSettings.guild_discord_server_id}`,
          },
          (payload) => {
            handleGuildMemberChange(payload);
          }
        )
        .on(
          'postgres_changes',
          {
            schema: 'public', // Subscribes to the "public" schema in Postgres
            event: '*', // Listen to all changes
            table: 'Guild_Applications',
            filter: `guild_discord_server_id=eq.${userSettings.guild_discord_server_id}`,
          },
          (payload) => {
            handleGuildApplications(payload);
          }
        )
        .on(
          'postgres_changes',
          {
            schema: 'public', // Subscribes to the "public" schema in Postgres
            event: '*', // Listen to all changes
            table: 'Guild_Events',
            filter: `guild_discord_server_id=eq.${userSettings.guild_discord_server_id}`,
          },
          (payload) => {
            handleGuildEvents(payload);
          }
        )
        .on(
          'postgres_changes',
          {
            schema: 'public', // Subscribes to the "public" schema in Postgres
            event: '*', // Listen to all changes
            table: 'Guild_Event_Attendance',
            filter: `guild_discord_server_id=eq.${userSettings.guild_discord_server_id}`,
          },
          (payload) => {
            handleGuildEventAttendance(payload);
          }
        )
        .on(
          'postgres_changes',
          {
            schema: 'public', // Subscribes to the "public" schema in Postgres
            event: '*', // Listen to all changes
            table: 'Guild_Data_Log',
            filter: `guild_discord_server_id=eq.${userSettings.guild_discord_server_id}`,
          },
          (payload) => {
            handleGuildDataLog(payload);
          }
        )
        .on(
          'postgres_changes',
          {
            schema: 'public', // Subscribes to the "public" schema in Postgres
            event: '*', // Listen to all changes
            table: 'Guild_Trade_Items',
            filter: `guild_discord_server_id=eq.${userSettings.guild_discord_server_id}`,
          },
          (payload) => {
            handleTradeItems(payload);
          }
        )
        .on(
          'postgres_changes',
          {
            schema: 'public', // Subscribes to the "public" schema in Postgres
            event: '*', // Listen to all changes
            table: 'Guilds',
            filter: `guild_discord_server_id=eq.${userSettings.guild_discord_server_id}`,
          },
          (payload) => {
            handleGuildInfoChange(payload);
          }
        )
        .on(
          'postgres_changes',
          {
            schema: 'public', // Subscribes to the "public" schema in Postgres
            event: '*', // Listen to all changes
            table: 'Guild_Admins',
            filter: `guild_discord_server_id=eq.${userSettings.guild_discord_server_id}`,
          },
          (payload) => {
            handleGuildAdmins(payload);
          }
        )
        .on(
          'postgres_changes',
          {
            schema: 'public', // Subscribes to the "public" schema in Postgres
            event: '*', // Listen to all changes
            table: 'Guild_Party_Members',
            filter: `guild_discord_server_id=eq.${userSettings.guild_discord_server_id}`,
          },
          (payload) => {
            handleGuildPartyMembers(payload);
          }
        )
        .on(
          'postgres_changes',
          {
            schema: 'public', // Subscribes to the "public" schema in Postgres
            event: '*', // Listen to all changes
            table: 'Guild_Parties',
            filter: `guild_discord_server_id=eq.${userSettings.guild_discord_server_id}`,
          },
          (payload) => {
            handleParties(payload);
          }
        )
        .on(
          'postgres_changes',
          {
            schema: 'public', // Subscribes to the "public" schema in Postgres
            event: '*', // Listen to all changes
            table: 'Guild_Raids',
            filter: `guild_discord_server_id=eq.${userSettings.guild_discord_server_id}`,
          },
          (payload) => {
            handleRaids(payload);
          }
        )
        .on(
          'postgres_changes',
          {
            schema: 'public', // Subscribes to the "public" schema in Postgres
            event: '*', // Listen to all changes
            table: 'Guild_Goals',
            filter: `guild_discord_server_id=eq.${userSettings.guild_discord_server_id}`,
          },
          (payload) => {
            handleGuildGoals(payload);
          }
        )
        .on(
          'postgres_changes',
          {
            schema: 'public', // Subscribes to the "public" schema in Postgres
            event: '*', // Listen to all changes
            table: 'Guild_Crafts',
            filter: `guild_discord_server_id=eq.${userSettings.guild_discord_server_id}`,
          },
          (payload) => {
            handleGuildCraft(payload);
          }
        )
        .on(
          'postgres_changes',
          {
            schema: 'public', // Subscribes to the "public" schema in Postgres
            event: 'UPDATE', // Listen to all changes
            table: 'Site_Settings',
          },
          (payload) => {
            writesiteSettings(payload.new);
          }
        )
        .subscribe((status, err) => {
          if (err) {
            console.error('Subscription error:', err);
          }
        });
      return watcher;
    }
  }
  ///////////////////////////// Real Time /////////////////////////////////////////

  ///////////////////////////// Auto Fetch /////////////////////////////////////////
  function writeLastGuildFetch(dateNow) {
    return writeToSessionStorage({
      location: sessionStorageMap.lastGuildFetch,
      data: { lastFetch: dateNow },
    });
  }

  function writeGuildData({ data }) {
    if (data === null) {
      sessionStorage.removeItem(sessionStorageMap.guildData);
      setGuildData(data);
    } else {
      setGuildData(data);
      writeToSessionStorage({
        location: sessionStorageMap.guildData,
        data,
      });
    }
  }

  async function getLastUpdated(userSettFetch) {
    const response = await supabase
      .from('Guilds') // Specify the table
      .select('last_updated') // Specify the field to select (e.g., 'name')
      .eq('guild_discord_server_id', userSettFetch.guild_discord_server_id) // Optionally, add a condition (e.g., where id = 1)
      .single();
    if (response.error === null) {
      return response.data.last_updated;
    } else {
      return null;
    }
  }

  async function fetchGuildData(dateNow, guild_id) {
    const response = await supabase
      .from('Guilds')
      .select(
        `
      *,
      Guild_User_Manage ( *, Users( * ) ),
      Guild_Admins ( * ),
      Guild_Events ( *, Guild_Event_Attendance ( * ) ),
      Guild_Data_Log ( * ),
      Guild_Applications ( * ),
      Guild_Raids ( *, Guild_Parties ( *, Guild_Party_Members ( * ) )),
      Guild_Trade_Items ( * ),
      Guild_Goals ( * ),
      Guild_Crafts ( * )

      `
      )
      .eq('guild_discord_server_id', guild_id)
      .limit(1)
      .single();
    if (response.error === null) {
      // Kicked from guild
      if (response.data.Guild_User_Manage.length === 0) {
        clearSessionStorage();
        return await fetchUserSettings();
      }
      writeLastGuildFetch(dateNow);
      return writeGuildData({ data: response.data });
    } else {
      // ('Failed to fetch guild data');
    }
  }
  async function checkFetchGuildData() {
    const lastGuildFetch = getInitialData(sessionStorageMap.lastGuildFetch);
    const userSettFetch = getInitialData(sessionStorageMap.userSettings);
    if (userSettFetch?.guild_discord_server_id) {
      if (lastGuildFetch) {
        const lastUpdate = await getLastUpdated(userSettFetch);
        if (lastUpdate !== null) {
          if (lastUpdate > lastGuildFetch.lastFetch) {
            return await fetchGuildData(
              lastUpdate,
              userSettFetch?.guild_discord_server_id
            );
          }
        } else {
          clearSessionStorage();
          await fetchUserSettings();
        }
      } else {
        return await fetchGuildData(
          Math.floor(Date.now() / 1000),
          userSettFetch?.guild_discord_server_id
        );
      }
    } else {
      if (userSettFetch?.discord_id) {
        const response = await supabase
          .from('Guild_User_Manage') // Specify the table
          .select('guild_discord_server_id') // Specify the field to select (e.g., 'name')
          .eq('discord_id', userSettFetch.discord_id) // Optionally, add a condition (e.g., where id = 1)
          .limit(1);
        if (response.error === null && response.data.length > 0) {
          return await fetchUserSettings();
        }
      }
    }
  }

  function writeUserData({ data }) {
    setUserSettings(data);
    writeToSessionStorage({ location: 'userSettings', data: data });
  }

  async function createUserInDB() {
    const response = await insertRowTable({
      table: 'User_Sensitive',
      data: {
        discord_id: user.user_metadata.provider_id,
        auth_uid: user.id,

        // Add any other fields here as necessary
      },
      errorToLog: 'Failed to insert to User Sensitive',
    });

    if (response.success) {
      const response = await insertRowTable({
        table: 'Users',
        data: {
          discord_id: user.user_metadata.provider_id,
          discord_display_name: user.user_metadata.full_name,
          discord_avatar: user.user_metadata.avatar_url,

          // Add any other fields here as necessary
        },
        errorToLog: 'Failed to insert Users table',
      });
      if (response.success) {
        return;
      }
    } else {
      return;
    }
  }

  // Grab User Settings and Guild Info from table
  async function fetchUserSettings() {
    await checkSite(true);
    setFetching(true);
    if (!user) {
      setFetching(false);
      return false;
    }
    const { success, data } = await handleSupabaseRequest(
      () =>
        supabase
          .from('Users')
          .select(
            `*, Guild_User_Manage (*), Guild_Admins (*), Guild_Applications (*), Guilds (guild_display_name)`
          )
          .eq('discord_id', user.user_metadata.provider_id)
          .maybeSingle(),
      'Failed to fetch user settings',
      logger
    );

    if (success && data !== null) {
      const { Guild_User_Manage, Guild_Admins, Guilds, ...userData } = data;
      let user_role = 'member';
      if (Guild_User_Manage !== null) {
        user_role = 'guild_member';
      }
      if (Guild_Admins !== null) {
        user_role = 'admin';
      }
      if (Guilds !== null) {
        user_role = 'owner';
      }

      writeUserData({
        data: {
          ...userData,
          guild_discord_server_id:
            Guild_User_Manage?.guild_discord_server_id || null,
          user_role,
        },
      });
      if (Guild_User_Manage === null) {
        writeGuildData({ data: null });
      } else {
        await checkFetchGuildData();
      }

      // TODO: Check guild data
    } else {
      // Handle errors or create user in DB
      await createUserInDB();
      await new Promise((resolve) => setTimeout(resolve, 1000));
      await fetchUserSettings();
    }
    setFetching(false);
  }

  ///////////////////////////// Auto Fetch /////////////////////////////////////////

  ///////////////////////////// Guild Functionality /////////////////////////////////////////

  async function deleteGuild() {
    if (userSettings.user_role !== 'owner') {
      const errorCode = generateShortUUID();
      logger.database.warn('Failed to delete guild, not owner');
      return { success: false, errorCode: errorCode };
    }
    supabase.removeAllChannels();
    const response = await basicDelete({
      table: 'Guilds',
      fieldToMatch: 'owner_discord_id',
      matchData: user.user_metadata.provider_id,
      errorToLog: 'Failed to delete guild -',
    });
    if (!response.success) return response;
    setGuildData(null);
    clearSessionStorage();

    await fetchUserSettings();
    return response;
  }

  async function createGuild(form) {
    const { guild_discord_server_id } = form;

    const response1 = await insertRowTable({
      table: 'Guilds',
      data: {
        ...form,
        owner_discord_id: user.user_metadata.provider_id,
        verified: false,
      },
      errorToLog: 'Failed to insert into Guilds',
    });
    if (!response1.success) {
      return response1;
    }
    const response2 = await insertRowTable({
      table: 'Guild_User_Manage',
      data: {
        guild_discord_server_id,
        discord_id: user.user_metadata.provider_id,
        dkp: 1,
      },
      errorToLog: 'Failed to insert user to Guild_User_Manage',
    });

    return response2;
  }

  async function updateGuildInfo(form) {
    // Check if user has the right role
    // Ensure guild ID is available
    if (guildData?.guild_discord_server_id) {
      const updateData = () => {
        return Object.keys(form).reduce((acc, key) => {
          if (form[key] !== null) {
            acc[key] = form[key];
          }
          return acc;
        }, {});
      };
      const patchData = updateData();

      if (Object.keys(patchData).length > 0) {
        return await basicUpdate({
          table: 'Guilds',
          fieldToMatch: 'guild_discord_server_id',
          matchData: guildData.guild_discord_server_id,
          data: patchData,
          errorToLog: 'Failed to update guild:',
        });
      } else {
        // ('No fields to update');
        return { success: false, errorCode: '' };
      }
    } else {
      // ('Failed to update guild - guild info not loaded');
      return { success: false, errorCode: '' };
    }
  }
  ///////////////////////////// Guild Functionality /////////////////////////////////////////

  ///////////////////////////// Guild Applications /////////////////////////////////////////

  async function deleteGuildApplication({ guild_application_id }) {
    return await basicDelete({
      table: 'Guild_Applications',
      fieldToMatch: 'guild_application_id',
      matchData: guild_application_id,
      errorToLog: 'Failed to delete guild application - ',
    });
  }

  async function createGuildApplication({
    guild_discord_server_id,
    in_game_class_role,
    in_game_class,
    in_game_name,
  }) {
    const response = await insertRowTable({
      table: 'Guild_Applications',
      data: {
        guild_discord_server_id,
        in_game_class_role,
        in_game_class,
        in_game_name,
        discord_id: user.user_metadata.provider_id,
        discord_display_name: user.user_metadata.full_name,
      },
      errorToLog: 'Failed to create guild application - ',
    });
    return response;
  }

  async function respondGuildApplication({
    guild_application_id,
    status,
    discord_id,
  }) {
    // Status pending/accepted/denied

    const response = await basicUpdate({
      table: 'Guild_Applications',
      matchData: guild_application_id,
      fieldToMatch: 'guild_application_id',
      errorToLog: 'Error responding to guild application - ',
      data: { status },
    });
    if (!response.success) {
      return response;
    } else {
      // If response accepts them adds member to roster
      if (status === 'ACCEPTED') {
        const response2 = await insertRowTable({
          table: 'Guild_User_Manage',
          data: {
            guild_discord_server_id: guildData.guild_discord_server_id,
            discord_id,
          },
          errorToLog: 'Error responding to guild application adding member-',
        });
        return response2;
      }
      return response;
    }
  }

  ///////////////////////////// Guild Applications /////////////////////////////////////////

  ///////////////////////////// Guild Utility /////////////////////////////////////////

  async function kickGuildMember({ discord_id }) {
    return await basicDelete({
      table: 'Guild_User_Manage',
      fieldToMatch: 'discord_id',
      matchData: discord_id,
      errorToLog: 'Error kicking user -',
    });
  }

  async function deleteAdmin({ discord_id }) {
    return await basicDelete({
      table: 'Guild_Admins',
      fieldToMatch: 'discord_id',
      matchData: discord_id,
      errorToLog: 'Error removing admin -',
    });
  }

  async function createAdmin({ discord_id }) {
    return await insertRowTable({
      table: 'Guild_Admins',
      data: {
        discord_id,
        guild_discord_server_id: guildData.guild_discord_server_id,
      },
      errorToLog: 'Error promoting user -',
    });
  }

  async function adjustMemberDKP({ discord_id, dkp }) {
    return await basicUpdate({
      table: 'Guild_User_Manage',
      matchData: discord_id,
      fieldToMatch: 'discord_id',
      errorToLog: 'Failed to update dkp',
      data: { dkp },
    });
  }

  ///////////////////////////// Guild Utility /////////////////////////////////////////

  ///////////////////////////// Guild Event Utility /////////////////////////////////////////
  async function createGuildEvent(form) {
    const {
      title,
      event_description,
      start,
      event_location_link,
      chips,
      dkp_reward,
      end,
    } = form;
    const response = await insertRowTable({
      table: 'Guild_Events',
      data: {
        title,
        event_description,
        start,
        event_location_link,
        chips,
        dkp_reward,
        end,
        guild_discord_server_id: guildData.guild_discord_server_id,
      },
      errorToLog: 'Failed to create event - ',
      returnData: true,
    });

    return response;
  }

  async function updateGuildEvent(form) {
    const { event_id, Guild_Event_Attendance, allDay, ...rest } = form;

    const response = await basicUpdate({
      table: 'Guild_Events',
      data: {
        ...rest,
      },
      fieldToMatch: 'event_id',
      matchData: event_id,
      errorToLog: 'Failed to update event -',
    });

    return response;
  }

  async function deleteGuildEvent({ event_id }) {
    return await basicDelete({
      table: 'Guild_Events',
      fieldToMatch: 'event_id',
      matchData: event_id,
      errorToLog: 'Failed to delete event -',
    });
  }

  async function registerEventInterest({ event_id }) {
    const response = await insertRowTable({
      table: supabaseTableMap.guildEventAttendance,
      data: {
        discord_id: user.user_metadata.provider_id,
        event_id,
        status: 'SIGNED_UP',
        guild_discord_server_id: guildData.guild_discord_server_id,
      },
      errorToLog: 'Failed to register interest - ',
    });

    return response;
  }

  async function updateCrafterRole({ discord_id, crafterRole }) {
    return await basicUpdate({
      table: 'Guild_User_Manage',
      fieldToMatch: 'discord_id',
      matchData: discord_id,
      errorToLog: 'Failed to update craft role',
      data: { in_game_craft_role: crafterRole },
    });
  }

  ///////////////////////////// Guild Event Utility /////////////////////////////////////////

  ///////////////////////////// Trade Utility /////////////////////////////////////////
  async function createTradeItem(form) {
    return insertRowTable({
      table: 'Guild_Trade_Items',
      data: {
        ...form,
        guild_discord_server_id: guildData.guild_discord_server_id,
        discord_id: userSettings.discord_id,
      },
      errorToLog: 'Failed to create trade item',
    });
  }

  async function deleteTradeItem({ trade_id }) {
    return basicDelete({
      table: 'Guild_Trade_Items',
      fieldToMatch: 'trade_id',
      matchData: trade_id,
      errorToLog: 'Failed to delete trade item',
    });
  }
  ///////////////////////////// Trade Utility /////////////////////////////////////////

  ///////////////////////////// User Utility /////////////////////////////////////////

  async function updateUserSettings(form) {
    const updateData = () => {
      return Object.keys(form).reduce((acc, key) => {
        if (form[key] !== null) {
          acc[key] = form[key];
        }
        return acc;
      }, {});
    };
    const patchData = updateData();

    return await basicUpdate({
      table: supabaseTableMap.users,
      fieldToMatch: 'discord_id',
      matchData: user.user_metadata.provider_id,
      errorToLog: 'Failed to update user settings',
      data: patchData,
      userRefresh: true,
    });
  }

  async function deleteUserAccount() {
    return await basicDelete({
      table: supabaseTableMap.userSensitive,
      fieldToMatch: 'auth_uid',
      matchData: user.id,
      errorToLog: 'Failed to delete user from database',
    });
  }

  async function leaveGuild() {
    return await basicDelete({
      table: 'Guild_User_Manage',
      fieldToMatch: 'discord_id',
      matchData: user.user_metadata.provider_id,
      errorToLog: 'Failed to leave guild -',
    });
  }
  ///////////////////////////// User Utility /////////////////////////////////////////

  ///////////////////////////// User Utility /////////////////////////////////////////

  //TODO: Create / Delete / Update Party

  //TODO: Create / Delete / Update Raid

  async function createRaid({ raid_name, raid_description }) {
    return await insertRowTable({
      table: 'Guild_Raids',
      data: {
        raid_name,
        raid_description,
        guild_discord_server_id: guildData.guild_discord_server_id,
      },
      errorToLog: 'Failed to create Raid',
    });
  }

  async function deleteRaid({ raid_id }) {
    return await basicDelete({
      table: 'Guild_Raids',
      matchData: raid_id,
      fieldToMatch: 'raid_id',
      errorToLog: 'Failed to delete Raid',
    });
  }

  async function updateRaid({ raid_id, data }) {
    return await basicUpdate({
      table: 'Guild_Raids',
      matchData: raid_id,
      fieldToMatch: 'raid_id',
      data: data,
      errorToLog: 'Failed to update Raid',
    });
  }

  async function createParty({ party_name, raid_id, party_number }) {
    return await insertRowTable({
      table: 'Guild_Parties',
      data: {
        party_name,
        raid_id,
        party_number,
        guild_discord_server_id: guildData.guild_discord_server_id,
      },
      errorToLog: 'Failed to create party',
    });
  }

  async function deleteParty({ party_id }) {
    return await basicDelete({
      table: 'Guild_Parties',
      matchData: party_id,
      fieldToMatch: 'party_id',
      errorToLog: 'Failed to delete party',
    });
  }

  async function updateParty({ party_name, party_id, party_number }) {
    return await basicUpdate({
      table: 'Guild_Parties',
      matchData: party_id,
      fieldToMatch: 'party_id',
      data: {
        party_name,
        party_number,
      },
      errorToLog: 'Failed to update party',
    });
  }

  async function addMemberToParty({ party_id, discord_id, raid_id }) {
    return await insertRowTable({
      table: 'Guild_Party_Members',

      data: {
        party_id,
        guild_discord_server_id: guildData.guild_discord_server_id,
        discord_id,
        raid_id,
      },
      errorToLog: 'Failed to add user to party',
    });
  }

  // Moving from one party to another
  async function updateMemberParty({ id, party_id }) {
    return await basicUpdate({
      table: 'Guild_Party_Members',
      fieldToMatch: 'id',
      matchData: id,
      data: {
        party_id,
      },
      errorToLog: 'Failed to add user to party',
    });
  }

  async function removeMemberFromParty({ id }) {
    return await basicDelete({
      table: 'Guild_Party_Members',
      matchData: id,
      fieldToMatch: 'id',
      errorToLog: 'Failed to remove member from party',
    });
  }

  async function createGoal({ label, goal, icon }) {
    return await insertRowTable({
      table: 'Guild_Goals',
      data: {
        label,
        icon,
        goal,
        guild_discord_server_id: guildData.guild_discord_server_id,
      },
      errorToLog: 'Failed to insert goal',
    });
  }

  async function updateGoal({ id, data }) {
    return await basicUpdate({
      table: 'Guild_Goals',
      data: {
        ...data,
      },
      fieldToMatch: 'id',
      matchData: id,
      errorToLog: 'Failed to update goal',
    });
  }

  async function deleteGoal({ id }) {
    return await basicDelete({
      table: 'Guild_Goals',
      fieldToMatch: 'id',
      matchData: id,
      errorToLog: 'Failed to delete goal',
    });
  }

  ///////////////////////////// User Utility /////////////////////////////////////////

  async function checkSite(force = false) {
    if (siteSettings === null || force) {
      const response = await supabase
        .from('Site_Settings') // Specify the table
        .select(' ( * ) ') // Specify the field to select (e.g., 'name')
        .eq('id', '1') // Optionally, add a condition (e.g., where id = 1)
        .single();
      if (response.error === null) {
        writesiteSettings(response.data);
        return { success: true, data: response.data };
      } else {
        writesiteSettings({ active: false });

        return { success: false };
      }
    } else {
      return siteSettings;
    }
  }

  return (
    <DataContext.Provider
      value={{
        userSettings,
        fetching,
        siteSettings,
        leaveGuild,

        respondGuildApplication,

        guildData,

        createGuild,
        createGuildEvent,
        createGuildApplication,
        createAdmin,
        createRaid,
        createParty,
        createTradeItem,
        createGoal,

        updateGuildInfo,
        updateGuildEvent,
        updateUserSettings,
        updateRaid,
        updateParty,
        updateCrafterRole,
        updateMemberParty,
        updateGoal,

        deleteGuild,
        deleteGuildEvent,
        deleteUserAccount,
        deleteGuildApplication,
        deleteAdmin,
        deleteRaid,
        deleteParty,
        deleteTradeItem,
        deleteGoal,

        kickGuildMember,

        registerEventInterest,

        checkFetchGuildData,

        adjustMemberDKP,

        addMemberToParty,
        removeMemberFromParty,

        checkSite,
        fetchUserSettings,
        realTime,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export const useData = () => useContext(DataContext);
