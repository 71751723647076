import { STRINGS } from 'components/config';

const {
  LINKS: { DISCORD },
} = STRINGS;

export function getDiscordInviteLinkCode(guild_discord_invite_link) {
  return guild_discord_invite_link.split('.gg/')[1];
}

async function populateDiscordInfo(inviteUrl) {
  const response = await fetch(inviteUrl);
  const data = await response.json();
  return data;
}

export async function fetchGuildInfoWithCodeFullLink(
  guild_discord_invite_link
) {
  const code = getDiscordInviteLinkCode(guild_discord_invite_link);
  if (code) {
    const inviteUrl = code && DISCORD.INVITE_LINK_CHECK(code);
    return await populateDiscordInfo(inviteUrl);
  }
  return null;
}

export async function fetchGuildInfoWithCodeOnly(code) {
  const inviteUrl = DISCORD.INVITE_LINK_CHECK(code);
  return await populateDiscordInfo(inviteUrl);
}
