import {
  Box,
  Card,
  Avatar,
  Badge,
  CardContent,
  Typography,
} from '@mui/material';
import { fontWeight, styled } from '@mui/system';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#5865F2', // Discord color
    color: '#fff',
    borderRadius: '4px',
    fontSize: '0.75rem',
    padding: '0 4px',
    minWidth: 'unset',
    height: '18px',
    fontWeight: 'bold',
  },
}));

const GuideBotUI = ({ bot_avatar, guild_avatar }) => {
  return (
    <Box display="flex" alignItems="center" gap={1} p={1} borderRadius="8px">
      <Avatar
        alt="guild_avatar"
        src={guild_avatar}
        sx={{ width: 40, height: 40 }}
      />
      <MoreHorizIcon />
      <Avatar
        alt="bot_avatar"
        src={bot_avatar}
        sx={{
          width: 40,
          height: 40,
        }}
      />

      <StyledBadge badgeContent="APP" />
    </Box>
  );
};

const DiscordBotCard = ({
  botName,
  botDescription,
  botImage,
  inviteLink,
  guildImage,
}) => {
  return (
    <Card
      sx={{
        width: '100%',
        // margin: 'auto',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
        borderRadius: '16px',
        display: 'flex',
        flexDirection: 'column',
        transition: 'transform 0.3s',
        '&:hover': {
          transform: 'scale(1.05)',
          cursor: 'pointer',
        },
      }}
      onClick={() => window.open(inviteLink, '_blank')}
    >
      <GuideBotUI guild_avatar={guildImage} bot_avatar={botImage} />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <CardContent>
          <Typography variant="button" component="div" gutterBottom>
            {botName}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {botDescription}
          </Typography>
        </CardContent>
      </Box>
    </Card>
  );
};

export default DiscordBotCard;
