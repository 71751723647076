import { v4 as uuidv4 } from 'uuid';

export const generateShortUUID = () => {
  // Generate a UUID using uuidv4
  let uuid = uuidv4();

  // Truncate the UUID to the first 8 characters (remove hyphens first)
  const shortUUID = uuid.replace(/-/g, '').substring(0, 8).toUpperCase();

  return shortUUID;
};
