// @ts-ignore
import { Stack } from '@mui/material';
import GuildLogsChart from 'components/charts/GuildLogsChart';
// @ts-ignore

import { useData } from 'context/DataContext';

export const GuildDataLog = () => {
  // @ts-ignore
  const { guildData } = useData();

  const flattenedData = guildData.Guild_Data_Log.map((item) => {
    return {
      ...item,
      ...item.Users,
    };
  }).map((item) => {
    return item;
  });

  if (!guildData.Guild_Data_Log) {
    return null;
  }

  return (
    <Stack
      direction="row"
      sx={{
        width: '100%',
        height: '100%',
      }}
    >
      <GuildLogsChart data={flattenedData} />
    </Stack>
  );
};
