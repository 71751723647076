import DeleteIcon from '@mui/icons-material/Delete';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import {
  Box,
  Card,
  Divider,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import { findGuildUser } from 'components/guildHelpers/functions';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { PlayerCard } from './PlayerPool';
const PLAYER_CARD_WIDTH = 100;
const PLAYER_CARD_HEIGHT = 50;
const PARTY_PADDING = 2;
const PARTY_WIDTH = 190;
const PARTY_HEIGHT = 800;

const PartyGroup = ({
  guildData,
  parties,
  updatePartyName,
  isDragDisabled,
  handleDeleteParty,
  isManagement,
}) => (
  <Droppable droppableId="partyGroup" type="party" direction="horizontal">
    {(provided) => (
      <Box
        ref={provided.innerRef}
        {...provided.droppableProps}
        sx={{
          display: 'flex',
          gap: 2,
          height: '100%',
          pl: 2,
          // overflowX: 'auto', // Allow horizontal scrolling for many parties
        }}
        //style={{ border: '1px solid red' }}
      >
        {Object.entries(parties).map(([partyId, party], index) => (
          <Draggable
            isDragDisabled={true}
            draggableId={partyId}
            index={index}
            key={partyId}
          >
            {(provided) => (
              <Card
                // elevation={12}
                ref={provided.innerRef}
                {...provided.draggableProps}
                sx={{
                  flex: '0 0 auto',
                  width: PARTY_WIDTH,
                  borderRadius: 2,
                  p: PARTY_PADDING,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: PARTY_PADDING,
                  height: '100%',
                  // height: PARTY_HEIGHT,
                  // bgcolor: 'grey',
                  // bgcolor: '#f9f9f9',
                }}
              >
                <Box>
                  <TextField
                    disabled={!isManagement}
                    value={party.party_name}
                    onChange={(e) => updatePartyName(partyId, e.target.value)}
                    variant="outlined"
                    size="small"
                    sx={{
                      flexGrow: 1,
                      '& .MuiInputBase-input': {
                        textAlign: 'center',
                      },
                      // bgcolor: '#e0e0e0',
                    }}
                  />
                </Box>

                {/* Party Header with Drag Handle */}
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mb: 1,
                    p: 1,
                    // bgcolor: '#e0e0e0',
                  }}
                >
                  {/* Drag Handle Icon */}
                  {isManagement && !isDragDisabled && (
                    <Box
                      {...provided.dragHandleProps}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: 24,
                        height: 24,
                        borderRadius: 1,
                        // mr: 1,
                        // bgcolor: '#d9d9d9',
                      }}
                    >
                      <DragIndicatorIcon fontSize="small" />
                      {/* Party Count */}
                    </Box>
                  )}

                  <Typography
                    id="party.members"
                    variant="h6"
                    sx={{ fontSize: 16 }}
                  >
                    {`${party.Guild_Party_Members.length}/8`}
                  </Typography>
                  {isManagement && !isDragDisabled && (
                    <IconButton
                      onClick={() => {
                        handleDeleteParty(party.party_id);
                      }}
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  )}

                  {/* Editable Party Name */}
                </Box>
                <Divider flexItem />
                {/* Party Members */}
                <Droppable droppableId={partyId}>
                  {(provided) => (
                    <Box
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      sx={{
                        width: '100%',
                        flexGrow: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: PARTY_PADDING,
                      }}
                    >
                      {party.Guild_Party_Members.map((player, index) => (
                        <Draggable
                          isDragDisabled={isDragDisabled}
                          draggableId={player.discord_id}
                          index={index}
                          key={player.discord_id}
                        >
                          {/* {(provided) => (
                            <Card
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              sx={{
                                width: PLAYER_CARD_WIDTH,
                                height: PLAYER_CARD_HEIGHT,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                bgcolor: '#ffecb3',
                              }}
                            >
                              <CardContent>
                                <Typography variant="body2" align="center">
                                  {player.name}
                                </Typography>
                              </CardContent>
                            </Card>
                          )} */}
                          {(provided) => (
                            <PlayerCard
                              provided={provided}
                              player={findGuildUser({
                                guildData: guildData,
                                discord_id: player.discord_id,
                              })}
                            />
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </Box>
                  )}
                </Droppable>
              </Card>
            )}
          </Draggable>
        ))}
        {provided.placeholder}
      </Box>
    )}
  </Droppable>
);

export default PartyGroup;
