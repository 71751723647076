// @ts-nocheck
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import { Button, Chip, IconButton } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import MuiDrawer, { drawerClasses } from '@mui/material/Drawer';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import CreateGuildForm from 'components/guildHelpers/CreateGuildForm';
import GuildOptionsMenu from 'components/menu/GuildOptionsMenu';
import OptionsMenu from 'components/menu/OptionsMenu';
import MenuContent from 'components/MenuContent';
import UserProfileForm from 'components/userHelpers/UserProfileForm';
import { useAuth } from 'context/AuthContext';
import { useData } from 'context/DataContext';
import { useToast } from 'context/ToastContext';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { UserSettingsModal } from './userHelpers/UserSettingsModal';

const GuildSmallInfoSection = () => {
  const { userSettings, leaveGuild, guildData } = useData();
  const toast = useToast();
  const {
    verified,
    guild_display_name,
    guild_avatar,
    guild_discord_invite_link,
  } = guildData;
  const { user_role } = userSettings;
  const isOwnerOfGuild = ['OWNER'].includes(
    userSettings.user_role.toUpperCase()
  );

  const handleLeaveGuild = async () => {
    const response = await leaveGuild();
    if (response?.success) {
      // setAppPage(PAGE.HOME); // replace with router
      return null;
    }
    if (!response?.success) {
      return toast.error(`Failed to leave - ${response.errorCode}`);
    }
    return null;
  };

  const handleClickGuildDiscordLink = () =>
    window.open(guild_discord_invite_link);
  return (
    <Stack
      direction="column"
      sx={{
        alignContent: 'space-between',
        alignItems: 'flex-start',
      }}
    >
      <Stack
        direction="row"
        sx={{
          width: '100%',
          p: 2,
          gap: 1,
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        {/* LEFT */}
        <Stack
          direction="row"
          sx={{
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <IconButton
            style={{ borderRadius: '30px', height: '52px', width: '52px' }}
          >
            <Avatar
              onClick={handleClickGuildDiscordLink}
              alt={guild_display_name?.charAt(0)}
              src={guild_avatar}
              sizes="small"
              sx={{
                // backgroundColor: params.value.color,
                width: '46px',
                height: '46px',
                fontSize: '0.85rem',
                // cursor:'mo'
                outline: 'none !important',
              }}
            />
          </IconButton>

          <Stack
            direction="column"
            sx={{
              gap: 1,
              alignItems: 'center',
            }}
          >
            <Box sx={{ mr: 'auto', pl: 1 }}>
              <Typography
                variant="h6"
                sx={{ fontWeight: 500, lineHeight: '16px', pl: 1, pb: 0.5 }}
              >
                {guild_display_name}
              </Typography>

              {verified ? (
                <Chip label="Verified" color="success" variant="outlined" />
              ) : (
                <Chip label="Not Verified" color="error" variant="outlined" />
              )}
            </Box>
          </Stack>
        </Stack>
      </Stack>

      <Stack
        direction="row"
        sx={{
          width: '100%',
          pl: 2,
          pr: 2,
          pb: 1,
          gap: 1,
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography variant="caption" sx={{ color: 'text.secondary' }}>
          ROLE - {user_role.toUpperCase()}
        </Typography>

        {!isOwnerOfGuild && (
          <Tooltip title={'Leave Guild'} placement="bottom">
            <IconButton aria-label="Example" onClick={handleLeaveGuild}>
              <LogoutIcon />
            </IconButton>
          </Tooltip>
        )}

        {/* NEED OWNER PERMS */}
        {isOwnerOfGuild && <GuildOptionsMenu />}
      </Stack>
    </Stack>
  );
};

const drawerWidth = 240;

const Drawer = styled(MuiDrawer)({
  width: drawerWidth,
  flexShrink: 0,
  boxSizing: 'border-box',
  mt: 10,
  [`& .${drawerClasses.paper}`]: {
    width: drawerWidth,
    boxSizing: 'border-box',
  },
});

export default function SideMenu() {
  const { user, logInWithDiscord } = useAuth();
  const { guildData, userSettings } = useData();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [userProfileOpen, setUserProfileOpen] = useState(false);
  const [userSettingsOpen, setUserSettingsOpen] = useState(false);
  const toast = useToast();

  const handleClickOpenGuildForm = () => {
    setOpen(true);
  };

  const handleCloseGuildForm = () => {
    setOpen(false);
  };

  const handleClickOpenUserProfile = () => setUserProfileOpen(true);

  const handleClickOpenUserSettings = () => setUserSettingsOpen(true);
  const handleCloseUserSettings = () => setUserSettingsOpen(false);

  const handleCloseUserProfile = () => {
    if (sessionStorage.getItem('blockFlagProfile') === 'true') {
      return null;
    }
    return setUserProfileOpen(false);
  };

  useEffect(() => {
    if (userSettings) {
      if (
        !userSettings?.in_game_name &&
        !userSettings?.in_game_class_role &&
        !userSettings?.in_game_class
      ) {
        setUserProfileOpen(true);
        toast.warning('Please fill in your profile');
        sessionStorage.setItem('blockFlagProfile', true);
      } else {
        sessionStorage.setItem('blockFlagProfile', false);
        setUserProfileOpen(false);
      }
    }

    return () => {};
  }, [userSettings]);

  if (!user) {
    return (
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: 'none', md: 'block' },
          [`& .${drawerClasses.paper}`]: {
            backgroundColor: 'background.paper',
          },
        }}
      >
        <Stack
          direction="row"
          sx={{
            p: 2,
            gap: 1,
            alignItems: 'center',
            borderTop: '1px solid',
            borderColor: 'divider',
          }}
        >
          <Button
            variant="outlined"
            startIcon={<LoginIcon />}
            onClick={() => {
              const params = new URLSearchParams(location.search);
              const redirectUrl = params.get('redirect_url') || '/';
              logInWithDiscord(redirectUrl);
            }}
          >
            Log In
          </Button>
        </Stack>
      </Drawer>
    );
  }

  const userDetails =
    guildData && guildData.Guild_User_Manage
      ? guildData.Guild_User_Manage.find(
          (item) => item.discord_id === user.user_metadata.provider_id
        )
      : null;

  const dkp = userDetails?.dkp || 0;
  return (
    <Drawer
      variant="permanent"
      sx={{
        display: { xs: 'none', md: 'block' },
        [`& .${drawerClasses.paper}`]: {
          backgroundColor: 'background.paper',
        },
      }}
    >
      {/* <SelectContent /> */}
      {guildData && guildData?.created_at ? (
        <GuildSmallInfoSection />
      ) : (
        <Box
          sx={{
            display: 'flex',
            mt: 'calc(var(--template-frame-height, 0px) + 4px)',
            p: 1.5,
          }}
        >
          <Button
            variant="outlined"
            color="primary"
            onClick={handleClickOpenGuildForm}
          >
            Create New Guild
          </Button>
        </Box>
      )}

      {/* User Profile */}
      <CreateGuildForm
        open={open}
        handleCloseGuildForm={handleCloseGuildForm}
        guildData={guildData}
      />

      <UserProfileForm
        open={userProfileOpen}
        action={'SAVE'}
        title={'EDIT PLAYER PROFILE'}
        onClose={handleCloseUserProfile}
      />
      <UserSettingsModal
        open={userSettingsOpen}
        title="SETTINGS"
        onClose={handleCloseUserSettings}
      />

      {/* User Profile */}
      <Divider />
      <MenuContent />
      {/* <CardAlert /> */}
      <Stack
        direction="row"
        sx={{
          p: 2,
          gap: 1,
          alignItems: 'center',
          borderTop: '1px solid',
          borderColor: 'divider',
        }}
      >
        <Avatar
          sizes="small"
          alt="Avatar"
          src={user?.user_metadata?.picture}
          sx={{ width: 36, height: 36 }}
        />
        <Box sx={{ mr: 'auto' }}>
          <Typography
            variant="body2"
            sx={{ fontWeight: 500, lineHeight: '16px' }}
          >
            {user?.user_metadata?.custom_claims?.global_name ||
              user?.user_metadata?.full_name}
          </Typography>
          <Typography variant="caption" sx={{ color: 'text.secondary' }}>
            DKP {dkp}
          </Typography>
        </Box>
        <OptionsMenu
          handleClickOpenUserProfile={handleClickOpenUserProfile}
          handleClickOpenUserSettings={handleClickOpenUserSettings}
        />
      </Stack>
    </Drawer>
  );
}
