import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import App from 'App';
import theme from './theme';
// import App from './App';
import { AppProvider } from 'context/AppContext';
import { AuthProvider } from 'context/AuthContext';
import { DataProvider } from 'context/DataContext';
import { ToastProvider } from 'context/ToastContext';
import { createRoot } from 'react-dom/client';
import { LoggerProvider } from 'utils/logger';
import ScrollingBanner from 'components/other/Banner';

console.log(
  '%c' +
    '       AA       OOOO      CCCCCCCC         GGGGGGGG    MM    MM\n' +
    '      A  A    OO    OO   CC               GG           MMM  MMM\n' +
    '     AAAAAA  OO      OO CC               GG   GGGGGG   MM MM MM\n' +
    '    AA    AA OO      OO CC               GG     GG     MM    MM\n' +
    '   AA      AA OO    OO   CC               GG     G     MM    MM\n' +
    '  AA        AA  OOOO      CCCCCCCC         GGGGGG      MM    MM',
  'color: white; font-weight: bold; font-size: 14px;'
);

const rootElement = document.getElementById('root');
// @ts-ignore
const root = createRoot(rootElement);

root.render(
  // <React.StrictMode>
  <ThemeProvider theme={theme}>
    {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
    {/* <CssBaseline /> */}
    <ToastProvider>
      <AppProvider>
        <AuthProvider>
          <LoggerProvider>
            <DataProvider>
              <App />
              <ScrollingBanner />
            </DataProvider>
          </LoggerProvider>
        </AuthProvider>
      </AppProvider>
    </ToastProvider>
  </ThemeProvider>
  // </React.StrictMode>,
);
