import UserProfileForm from 'components/userHelpers/UserProfileForm';
import { useData } from 'context/DataContext';
import { useParams } from 'hooks/use-params';
import { useState } from 'react';

export const GuildApplicationForm = () => {
  const [userProfileOpen, setUserProfileOpen] = useState(true);

  const params = useParams();
  const { guild_discord_server_id, guild_discord_invite_link } = params;

  const handleCloseUserProfile = () => {
    setUserProfileOpen(false);
  };

  if (!guild_discord_server_id && !guild_discord_invite_link) {
    return null;
  }

  return (
    <UserProfileForm
      open={userProfileOpen}
      onClose={handleCloseUserProfile}
      action="APPLY"
      title="GUILD APPLICATION FORM"
      params={params}
    />
  );
};
