// @ts-nocheck
import GuildApplicationsGrid from 'components/charts/GuildApplicationsGrid';
import { RULES } from 'components/config';
import { useData } from 'context/DataContext';

const GuildApplications = () => {
  const { guildData, userSettings } = useData();
  const mode = userSettings.user_role.toUpperCase();

  let data = [];
  const isNotPartOfGuild = userSettings.user_role.toUpperCase() === 'MEMBER';

  const hasRightToViewGuildApplications = RULES.canSeeGuildApplications(
    userSettings.user_role
  );

  if (userSettings.Guild_Applications && isNotPartOfGuild) {
    // non guild members vew
    data.push(userSettings.Guild_Applications);
  } else if (guildData?.Guild_Applications?.length) {
    // owner/admin view
    data = [...guildData.Guild_Applications];
  }
  // if (
  //   !guildData &&
  //   userSettings.user_role.toUpperCase() === 'MEMBER' &&
  //   !userSettings.GuildApplications
  // ) {
  //   (async function () {})();
  //   return null;
  // }

  // if (
  //   !guildData.Guild_Applications &&
  //   userSettings.user_role.toUpperCase() == 'OWNER'
  // ) {
  //   (async function () {})();
  //   return null;
  // }

  return <GuildApplicationsGrid data={data} mode={mode} />;
};

export default GuildApplications;
