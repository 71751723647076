import { Box } from '@mui/material';
import CustomDialog from 'components/customDialog/customDialog';
import { useToast } from 'context/ToastContext';
import ColorModeIconDropdown from 'shared-theme/ColorModeIconDropdown';

export const UserSettingsModal = ({ open, onClose, title }) => {
  return (
    <CustomDialog open={open} title={title} onClose={onClose}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '30vh',
        }}
      >
        <ColorModeIconDropdown />
      </Box>
    </CustomDialog>
  );
};
