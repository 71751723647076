import {
  Box,
  Card,
  CardContent,
  Typography,
  Avatar,
  Grid2,
  Stack,
  Divider,
} from '@mui/material';
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
} from '@mui/lab';
import WavingHandIcon from '@mui/icons-material/WavingHand';
import { STRINGS } from 'components/config';
import { useData } from 'context/DataContext';
import {
  findGuildUser,
  getCraftingRoleKeys,
} from 'components/guildHelpers/functions';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import { renderAvatar } from 'internals/data/gridData';

const { craftingRoleMap } = STRINGS;

const craftingKeys = getCraftingRoleKeys(craftingRoleMap);

const craftColorDict = {
  OPEN: '#ffda05',
  ACCEPTED: '#37ff29',
};

const GuildCrafters = ({ group }) => {
  return (
    <CardContent>
      <Stack
        style={{
          flexDirection: 'row',
          height: '60px',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {group.guildCrafters?.length
          ? group.guildCrafters?.map((ea) => {
              const params = {
                row: {
                  discord_display_name: ea?.in_game_name,
                  discord_id: ea?.discord_id,
                },
                value: ea?.discord_avatar,
              };
              return (
                <Stack
                  sx={{ mb: 1, mr: 2, alignItems: 'center' }}
                  key={ea?.in_game_name}
                >
                  {renderAvatar(params)}
                  <Typography key={ea}>{ea?.in_game_name}</Typography>
                </Stack>
              );
            })
          : 'No Crafters Assigned'}
      </Stack>
      <Divider sx={{ mb: 0.5 }} />
      <Typography variant="subtitle1" sx={{ fontSize: 14 }}>
        {group.craftingType}
      </Typography>
    </CardContent>
  );
};

const GuildCrafts = () => {
  const { guildData } = useData();
  // Filtering and organizing data
  if (!guildData) {
    return null;
  }

  const openAndAcceptedRequests = guildData?.Guild_Crafts?.filter(
    (item) => item?.status === 'OPEN' || item.status === 'ACCEPTED'
  );

  const groupedByCraftingType = craftingKeys.map((key) => {
    // Filter requests by crafting type
    const relevantRequests = openAndAcceptedRequests?.filter(
      (item) => item?.crafting_type === key
    );

    // Find users with matching in_game_craft_role
    const guildCrafters = guildData?.Guild_User_Manage?.filter(
      (user) => user?.in_game_craft_role?.toUpperCase() === key?.toUpperCase()
    ).map((user) => user.Users); // Map to user objects
    return {
      craftingType: key,
      requests: relevantRequests,
      guildCrafters: guildCrafters, // Append guild crafters grouped by craft role
    };
  });

  return (
    <Box sx={{ overflow: 'hidden' }}>
      <Grid2 container spacing={1}>
        {groupedByCraftingType?.map((group, index) => (
          <Grid2 item xs={1} key={index} style={{ width: '400px' }}>
            {/* Crafters */}
            <Card sx={{ marginBottom: 1, textAlign: 'center' }}>
              <GuildCrafters group={group} />
            </Card>

            {/* Timeline */}
            <Card>
              <CardContent
                sx={{ padding: 1 }}
                style={{ height: '270px', overflow: 'auto' }}
              >
                <Timeline
                  sx={{
                    padding: 0,
                    '& .MuiTimelineItem-root:before': { display: 'none' },
                  }}
                >
                  {group?.requests?.map((request, requestIndex) => {
                    const discNameOfUserWhoRequested = findGuildUser({
                      guildData,
                      discord_id: request.asking_discord_id,
                    })?.Users;

                    const discNameOfUserWhoCrafter = findGuildUser({
                      guildData,
                      discord_id: request.crafter_discord_id,
                    })?.Users;

                    return (
                      <TimelineItem key={requestIndex}>
                        <TimelineSeparator>
                          {request.status === 'OPEN' && (
                            <WavingHandIcon
                              sx={{ mt: 0.5, mb: 0.5 }}
                              style={{
                                color: craftColorDict[request.status],
                              }}
                            />
                          )}
                          {request.status === 'ACCEPTED' && (
                            <ThumbUpIcon
                              sx={{ mt: 0.5, mb: 0.5 }}
                              style={{
                                color: craftColorDict[request.status],
                              }}
                            />
                          )}
                          {/* <TimelineDot
                            // color={'primary'}
                            style={{
                              backgroundColor: craftColorDict[request.status],
                            }}
                          /> */}
                          {requestIndex < group.requests.length - 1 && (
                            <TimelineConnector />
                          )}
                        </TimelineSeparator>
                        <TimelineContent sx={{ marginBottom: 1 }}>
                          <Stack>
                            <Typography variant="button">
                              {`status - ${request.status}`}
                            </Typography>
                            {request.status === 'ACCEPTED' && (
                              <Typography variant="button">
                                {`ACCEPTED BY - ${discNameOfUserWhoCrafter?.discord_display_name}`}
                              </Typography>
                            )}
                            <Typography
                              variant="caption"
                              sx={{ color: 'gray' }}
                            >
                              {new Date(request.created_at).toLocaleString()}
                            </Typography>
                            <Typography variant="button">
                              {`Item - ${request.item_name}`}
                            </Typography>
                            <Typography variant="button">
                              {`Quantity - ${request.quantity}`}
                            </Typography>
                            <Typography variant="button">
                              {`Request by - ${discNameOfUserWhoRequested?.discord_display_name}`}
                            </Typography>
                          </Stack>
                        </TimelineContent>
                      </TimelineItem>
                    );
                  })}
                </Timeline>
              </CardContent>
            </Card>
          </Grid2>
        ))}
      </Grid2>
    </Box>
  );
};

export default GuildCrafts;
