import { useEffect, useState } from 'react';

import { STRINGS } from 'components/config';
import { LoadingScreen } from 'components/other/LoadingScreen';
import { useAuth } from 'context/AuthContext';
import { useData } from 'context/DataContext';
import { useRouter } from 'hooks/use-router';
const { PATHS } = STRINGS;

// ----------------------------------------------------------------------

export function MaintenanceGuard({ children }) {
  const router = useRouter();

  const { checkSite, siteSettings } = useData();
  const { signOut } = useAuth();

  const [isChecking, setIsChecking] = useState(true);

  const checkPermissions = async () => {
    // if (isDev) {
    //   setIsChecking(false);
    //   return;
    // }

    if (siteSettings === null) {
      const response = await checkSite();
      if (response.active === false) {
        router.push(PATHS.maintenance);
        signOut();
      }
      setIsChecking(false);
      return;
    } else if (siteSettings.active === false) {
      router.push(PATHS.maintenance);
      signOut();
      setIsChecking(false);
      return;
    } else {
      setIsChecking(false);
      return;
    }
  };

  useEffect(() => {
    checkPermissions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteSettings]);

  if (isChecking) {
    return <LoadingScreen text={'Loading Maintenance'} />;
  }

  return <>{children}</>;
}

export default MaintenanceGuard;
