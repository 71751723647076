import { generateShortUUID } from "utils/uuid";

export const handleSupabaseRequest = async (requestFn, errorToLog, logger) => {
    try {
      const response = await requestFn();
      if (response.error) {
        const errorCode = generateShortUUID();
        logger.database.error(`${errorToLog} | ${response.error.message} | Error Code - ${errorCode}`);
        return { success: false, errorCode };
      }
      return { success: true, data: response.data };
    } catch (error) {
      const errorCode = generateShortUUID();
      logger.database.error(`${errorToLog} | ${error.message} | Error Code - ${errorCode}`);
      return { success: false, errorCode };
    }
  };