// src/context/AuthContext.jsx
import { createContext, useContext, useEffect, useState } from 'react';
import { isDev } from 'utils/config';
import { supabase } from '../auth/supabaseClient';

// @ts-ignore
const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const redirect = isDev ? `${window.location.origin}` : `https://aocgm.com`;

  useEffect(() => {
    const getSession = async () => {
      const {
        data: { session },
      } = await supabase.auth.getSession();
      setUser(session?.user ?? null);
    };

    getSession();

    // Listen to auth state changes
    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setUser(session?.user ?? null);
      setLoading(false);
    });

    // Clean up the subscription
    return () => {
      subscription?.unsubscribe();
    };
  }, []);

  const logInWithDiscord = async (redirectUrl = null) => {
    const { error } = await supabase.auth.signInWithOAuth({
      provider: 'discord',
      options: {
        redirectTo: `${redirect}${redirectUrl || '/'}`,
      },
    });
    setLoading(false);
  };

  const signOut = async () => {
    const { error } = await supabase.auth.signOut();
    sessionStorage.clear();
    if (error) console.error(error);
    return null;
  };

  return (
    <AuthContext.Provider value={{ user, loading, logInWithDiscord, signOut }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
