import { AppBar, Box, Toolbar, Typography } from '@mui/material';
import { useData } from 'context/DataContext';
import { useState } from 'react';

const ScrollingBanner = () => {
  const [speed, setSpeed] = useState(22); // Default animation speed (seconds)
  const { siteSettings } = useData();
  const handleSpeedChange = (_, newValue) => {
    setSpeed(newValue);
  };

  return (
    <>
      {siteSettings?.banner_content !== null &&
        siteSettings?.banner_content !== undefined &&
        siteSettings !== null && (
          <AppBar
            position="fixed"
            sx={{
              top: 0,
              zIndex: 1300,
            }}
            style={{
              backgroundColor: 'rgba(12,16,23,0.5)',
              pointerEvents: 'none',
            }}
          >
            <Toolbar style={{ backgroundColor: 'transparent' }}>
              <Box
                sx={{
                  width: '100%',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                }}
                style={{ backgroundColor: 'transparent' }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    display: 'inline-block',
                    animation: `scrollText ${speed}s linear infinite`,
                    color: 'white',
                  }}
                >
                  {siteSettings.banner_content}
                </Typography>
              </Box>
            </Toolbar>
            <style>
              {`
            @keyframes scrollText {
              0% {
                transform: translateX(100%);
              }
              100% {
                transform: translateX(-100%);
              }
            }
          `}
            </style>
          </AppBar>
        )}
      {/* Scrolling Banner */}

      {/* Speed Slider (for demonstration purposes) */}
      {/* <Box
        sx={{
          position: 'fixed',
          top: '60px',
          left: '10px',
          backgroundColor: 'white',
          padding: '10px',
          borderRadius: '8px',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
          zIndex: 1200,
        }}
      >
        <Typography variant="subtitle1">Adjust Speed</Typography>
        <Slider
          value={speed}
          min={5}
          max={30}
          step={1}
          onChange={handleSpeedChange}
          valueLabelDisplay="auto"
        />
      </Box> */}
    </>
  );
};

export default ScrollingBanner;
