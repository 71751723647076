import { LoadingScreen } from 'components/other/LoadingScreen';
import { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';

const RoleGuard = ({ allowedRoles, userRole, children }) => {
  const [isChecking, setIsChecking] = useState(true);

  const loading = userRole === undefined;

  const checkPermissions = async () => {
    if (loading) {
      return;
    }

    if (!allowedRoles.includes(userRole)) {
      return <Navigate to={'/home'} replace />;
    }

    setIsChecking(false);
  };

  useEffect(() => {
    checkPermissions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  if (isChecking) {
    return <LoadingScreen text="Loading Role" />;
  }

  return children;
};

export default RoleGuard;
