import LockIcon from '@mui/icons-material/Lock';
import {
  Box,
  Card,
  CardContent,
  Stack,
  Switch,
  Tooltip,
  Typography,
} from '@mui/material';
import { amber } from '@mui/material/colors';
import { findGuildUser } from 'components/guildHelpers/functions';
import { useData } from 'context/DataContext';
import { renderAvatar } from 'internals/data/gridData';
import { useEffect, useState } from 'react';

const UserEditing = ({ userIdEditing, handleToggleFunc, loggedInUser }) => {
  const { guildData } = useData();
  const [editMode, setEditMode] = useState(
    userIdEditing === null ? false : true
  );

  useEffect(() => {
    setEditMode(userIdEditing === null ? false : true);

    return () => {};
  }, [userIdEditing]);

  const handleToggle = () => {
    const newMode = !editMode;
    setEditMode(newMode);

    handleToggleFunc(newMode);
  };

  const matchedUserData = findGuildUser({
    guildData,
    discord_id: userIdEditing,
  })?.Users;
  const params = {
    row: {
      discord_display_name: matchedUserData?.in_game_name,
      discord_id: matchedUserData?.discord_id,
    },
    value: matchedUserData?.discord_avatar,
  };
  return (
    <Card
      sx={{
        width: 180,
        mb: 1,
        maxHeight: '200px',
        minHeight: '100px',
      }}
    >
      <Stack
        id="stackEditBox"
        alignItems="center"
        justifyContent="space-between"
        flexDirection={'row'}
        mb={1}
        style={{}}
      >
        <Stack style={{ width: '100%' }}>
          <Stack
            style={{
              width: '100%',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            {matchedUserData && renderAvatar(params)}
            <Tooltip
              title={matchedUserData?.discord_display_name || ''}
              placement="top"
              arrow
            >
              <Typography
                variant="h6"
                sx={{ ml: 1 }}
                style={{
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                }}
              >
                {matchedUserData && matchedUserData?.discord_display_name}
              </Typography>
            </Tooltip>
          </Stack>

          <Stack
            style={{
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Typography variant="h6" style={{ fontSize: '16px' }}>
              {matchedUserData && 'is currently editing'}
            </Typography>
            {!!userIdEditing && (
              <LockIcon
                sx={{ color: amber[700] }}
                titleAccess="Warning: Read-only mode"
              />
            )}
          </Stack>
        </Stack>
      </Stack>
      <Stack
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection={'row'}
        sx={{ mb: 2 }}
      >
        <Typography variant="button">VIEW</Typography>
        <Switch
          disabled={userIdEditing && loggedInUser !== userIdEditing}
          checked={editMode}
          onChange={handleToggle}
          color="primary"
          inputProps={{ 'aria-label': 'Toggle edit mode' }}
        />
        <Typography variant="button">EDIT</Typography>
      </Stack>
    </Card>
  );
};

export default UserEditing;
