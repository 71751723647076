export const getInitialData = (location) => {
  const rawData = sessionStorage.getItem(location);
  try {
    const parsedData = rawData ? JSON.parse(rawData) : null;
    if (parsedData && Object.keys(parsedData).length === 0) return null;
    return parsedData;
  } catch {
    console.error(`Failed to parse sessionStorage data for ${location}`);
    return null;
  }
};

export const writeToSessionStorage = ({ location, data }) => {
  try {
    sessionStorage.setItem(location, JSON.stringify(data));
  } catch (error) {
    console.error(
      `Failed to write data to sessionStorage for ${location}`,
      error
    );
  }
};

export const clearSessionStorage = () => {
  sessionStorage.clear();
};
