import { HowToReg } from '@mui/icons-material';
import ColorLensIcon from '@mui/icons-material/ColorLens'; // Import an icon
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Box,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Stack,
  Switch,
  TextField,
} from '@mui/material';
import { MobileDateTimePicker } from '@mui/x-date-pickers';
import ColorPickerDialog from 'components/ColorPickerDialog';
import { STRINGS } from 'components/config';
import CustomDialog from 'components/customDialog/customDialog';
import IconPickerDialog from 'components/IconPickerDialog';
import Embedded from 'components/other/Embedded';
import CustomNumberInput from 'components/ui/CustomNumberInput';
import { useData } from 'context/DataContext';
import { useToast } from 'context/ToastContext';
import dayjs from 'dayjs';
import { useRouter } from 'hooks/use-router';
import { useEffect, useState } from 'react';
import { sendEventEmbed } from 'utils/discord';

const defaultEvent = {
  title: '',
  event_description: '',
  start: '',
  end: '',
  allDay: false,
  event_location_link: '',
  dkp_reward: 1,
  mandatory_bool: true,
  backgroundColor: '#A8DADC',
  event_icon: 'MMA',
};

const { PATHS, iconMap } = STRINGS;

export const EventForm = ({ eventInfo, onClose, open, isManagement }) => {
  // @ts-ignore
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleOpenDialog = () => setDialogOpen(true);
  const handleCloseDialog = () => setDialogOpen(false);

  const [dialogOpenIcon, setDialogOpenIcon] = useState(false);

  const handleOpenDialogIcon = () => setDialogOpenIcon(true);
  const handleCloseDialogIcon = () => setDialogOpenIcon(false);

  const handleIconChange = (icon) => {
    setForm((prev) => ({ ...prev, event_icon: icon.name }));
  };

  const handleColorChange = (color) => {
    setForm((prev) => ({ ...prev, backgroundColor: color }));
  };

  const [form, setForm] = useState(defaultEvent);
  const selectedIcon = iconMap[form.event_icon];
  const { Icon } = selectedIcon;
  const [notifyDiscord, setNotifyDiscord] = useState(true);
  const isEditing = form?.event_id;
  const [isBlocking, setIsBlocking] = useState(false);
  const router = useRouter();
  const [errors, setErrors] = useState({});
  const {
    createGuildEvent,
    updateGuildEvent,
    deleteGuildEvent,
    registerEventInterest,
    userSettings,
    guildData,
  } = useData();

  const isRegistered = eventInfo?.Guild_Event_Attendance?.some(
    (event) => event.discord_id === userSettings.discord_id
  );

  // @ts-ignore

  const toast = useToast();

  const eventOver = !(
    new Date() <=
    new Date(form.end).setMinutes(new Date(form.end).getMinutes() + 5)
  );

  const handleClose = () => {
    setForm(defaultEvent);
    onClose();
  };

  const handleDateChange = (e, name) => {
    setForm((prev) => ({ ...prev, [name]: e.$d }));
    if (errors[name]) {
      setErrors((prev) => ({ ...prev, [name]: '' }));

      //   setSelectedDate(newDate); // Update the state with the new date
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm((prev) => ({ ...prev, [name]: value }));
    if (errors[name]) {
      setErrors((prev) => ({ ...prev, [name]: '' }));
    }
  };

  const handleNumberInput = (name, newValue) => {
    // const { name, value } = e.target;
    setForm((prev) => ({ ...prev, [name]: newValue }));
    // if (errors[name]) {
    //   setErrors((prev) => ({ ...prev, [name]: '' }));
    // }
  };

  const handleToggle = (e) => {
    const { name, checked } = e.target;
    setForm((prev) => ({ ...prev, [name]: checked }));
    if (errors[name]) {
      setErrors((prev) => ({ ...prev, [name]: '' }));
    }
  };

  const validate = () => {
    const newErrors = {};

    if (!form.title) newErrors.title = 'In game name is required';

    return newErrors;
  };

  const handleClick = async (e) => {
    e.preventDefault();
    const newErrors = validate();

    if (Object.keys(newErrors).length === 0) {
      setIsBlocking(true);
      if (isEditing) {
        const response = await updateGuildEvent({ ...form });
        if (response) {
          toast.success('Success');

          handleClose();
        } else {
          toast.error('Failed');
        }
      } else {
        const response = await createGuildEvent({ ...form });
        if (response.success) {
          toast.success('Success');
          if (notifyDiscord) {
            const discord_message_id = await handleSendDiscordNotification(
              response.data[0].event_id
            );
            if (discord_message_id) {
              const response_update = await updateGuildEvent({
                event_id: response.data[0].event_id,
                discord_message_id,
              });
            }
          }

          handleClose();
        } else {
          toast.error(`Failed - ${response.errorCode}`);
        }
      }

      setIsBlocking(false);
    } else {
      return setErrors(newErrors);
    }
  };

  const handleDelete = async () => {
    setIsBlocking(true);
    const response = await deleteGuildEvent({ event_id: form.event_id });
    if (response) {
      toast.success('Success');
      handleClose();
    } else {
      toast.error('Failed');
    }
    setIsBlocking(false);
    return;
  };

  const handleEventRegister = async () => {
    setIsBlocking(true);
    const response = await registerEventInterest({ event_id: form.event_id });
    if (response.success) {
      handleClose();
      toast.success('Success');
    } else {
      toast.error(`Failed - ${response.errorCode}`);
    }
    setIsBlocking(false);
  };

  const handleSendDiscordNotification = async (event_id) => {
    return sendEventEmbed({
      peopleToNotify: null,
      event_title: form.title,
      event_description: form.event_description,
      event_location_link: form.event_location_link,
      channelToSend: guildData.guild_discord_channel_event_notifications,
      mandatory_bool: form.mandatory_bool,
      event_id: event_id,
      dkp_reward: form.dkp_reward,
      event_start: form.start,
      created_at: new Date(),
      created_by: userSettings.discord_display_name,
    });
  };

  useEffect(() => {
    setForm({ ...form, ...eventInfo });

    return () => {};
  }, [eventInfo, open]);

  return (
    <CustomDialog
      open={open}
      onClose={() => {
        handleClose();
      }}
      title={
        <Stack spacing={2} alignItems="center" width="100%">
          {/* Top row with text centered and button at the end */}
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
            width="100%"
          >
            {isEditing && (
              <IconButton
                sx={{ mr: 1 }}
                onClick={() => {
                  router.push(PATHS.events.attendance(form.event_id));
                }}
              >
                {/* Replace with your desired icon */}
                <HowToReg />
              </IconButton>
            )}
            {isManagement && (
              <>
                <IconButton
                  sx={{ mr: 1 }}
                  onClick={handleOpenDialog}
                  style={{
                    backgroundColor: form.backgroundColor,
                    color: '#fff',
                    border: '2px solid #000', // Optional: for better visibility
                  }}
                >
                  <ColorLensIcon />
                </IconButton>
                <IconButton
                  onClick={handleOpenDialogIcon}
                  style={{
                    backgroundColor: form.backgroundColor,

                    border: '2px solid #000', // Optional: for better visibility
                  }}
                >
                  <Icon style={{ fontSize: 32 }} />
                </IconButton>
              </>
            )}
          </Stack>

          {/* Second row with additional text */}
          {/* {isEditing && (
            <Typography variant="h6" align="center">
              {`Signups - ${eventInfo?.Guild_Event_Attendance?.length || 0}`}
            </Typography>
          )} */}
        </Stack>
      }
      actions={
        <>
          {isManagement && isEditing && (
            <LoadingButton
              variant="contained"
              color="inherit"
              fullWidth
              sx={{ mt: 2 }}
              loading={isBlocking}
              onClick={handleDelete}
            >
              Delete
            </LoadingButton>
          )}
          {isManagement && (
            <LoadingButton
              onClick={handleClick}
              variant="contained"
              color="inherit"
              fullWidth
              sx={{ mt: 2 }}
              loading={isBlocking}
            >
              Save
            </LoadingButton>
          )}
          {isEditing && (
            <LoadingButton
              variant="contained"
              color="inherit"
              fullWidth
              sx={{ mt: 2 }}
              loading={isBlocking}
              onClick={handleEventRegister}
              disabled={eventOver || isRegistered}
            >
              {eventOver
                ? isRegistered
                  ? 'Registered'
                  : 'Event Over'
                : isRegistered
                  ? 'Registered'
                  : 'Register Interest'}
            </LoadingButton>
          )}
        </>
      }
    >
      <form
        // @ts-ignore

        onSubmit={(e) => handleClick(e)}
      >
        <TextField
          slotProps={{
            input: {
              readOnly: !isManagement,
            },
          }}
          label="Event Name"
          variant="standard"
          fullWidth
          name="title"
          value={form.title}
          onChange={handleChange}
          // @ts-ignore
          error={!!errors.title}
          // @ts-ignore
          helperText={errors.title}
          margin="normal"
        />
        <TextField
          slotProps={{
            input: {
              readOnly: !isManagement,
            },
          }}
          label="Event Description"
          variant="standard"
          fullWidth
          name="event_description"
          value={form.event_description}
          onChange={handleChange}
          // @ts-ignore
          error={!!errors.event_description}
          // @ts-ignore
          helperText={errors.event_description}
          margin="normal"
        />

        {isManagement && (
          <TextField
            label="Event Location Link"
            variant="standard"
            fullWidth
            name="event_location_link"
            value={form.event_location_link}
            onChange={handleChange}
            // @ts-ignore
            error={!!errors.event_location_link}
            // @ts-ignore
            helperText={errors.event_location_link}
            margin="normal"
            slotProps={{
              input: {
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => {
                        window.open(
                          'https://ashescodex.com/map',
                          '_blank',
                          'noopener,noreferrer'
                        );
                      }}
                      sx={{
                        ml: 0.5,
                        mr: -0.3,
                        mb: 0.5,
                        width: 30,
                        height: 30,
                      }}
                    >
                      <InsertLinkIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              },
            }}
          />
        )}
        {form.event_location_link && (
          <Embedded url={form.event_location_link} />
        )}

        {/* TODO: Make only numbers */}
        {/* <TextField
          // slotProps={{
          //   inputLabel: {
          //     shrink: true,
          //   },
          // }}
          type="number"
          // inputProps={{ inputProps: { min: 0, max: 99999 } }}
          label="DKP Reward"
          variant="standard"
          fullWidth
          name="dkp_reward"
          value={form.dkp_reward}
          onChange={handleChange}
          // @ts-ignore
          error={!!errors.event_location_link}
          // @ts-ignore
          helperText={errors.event_location_link}
          margin="normal"
        /> */}
        <CustomNumberInput
          label="DKP Reward"
          min={0}
          max={99999}
          name="dkp_reward"
          id="dkp_reward"
          valeType="DKP"
          value={form.dkp_reward}
          onChange={handleNumberInput}
          variant="standard"
          // errors={!!errors.dkp_reward}
        />

        {isManagement && (
          <FormControlLabel
            control={
              <Switch
                color="warning"
                name="mandatory_bool"
                checked={form.mandatory_bool}
                onChange={handleToggle}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
            label="Mandatory"
          />
        )}

        {isManagement && !isEditing && (
          <FormControlLabel
            control={
              <Switch
                color="warning"
                name="notifyDiscord"
                checked={notifyDiscord}
                onChange={(e) => {
                  setNotifyDiscord(e.target.checked);
                }}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
            label="Notify Discord"
          />
        )}

        {isManagement && (
          <FormControlLabel
            control={
              <Switch
                color="warning"
                name="allDay"
                checked={form.allDay}
                onChange={handleToggle}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
            label="All day"
          />
        )}

        <Box
          sx={{
            display: 'flex', // Align items in a row
            gap: 2, // Add spacing between date pickers
            alignItems: 'center', // Align vertically
            flexWrap: 'nowrap', // Prevent wrapping
            mt: 1,
          }}
        >
          <MobileDateTimePicker
            readOnly={!isManagement}
            formatDensity="spacious"
            label="Start Time" // Label for the picker
            value={dayjs(form.start)} // Set the value of the picker
            onChange={(e) => handleDateChange(e, 'start')} // Handle change event
          />
          {!form.allDay && (
            <MobileDateTimePicker
              readOnly={!isManagement}
              sx={{ ml: 1 }}
              label="End Time" // Label for the picker
              value={dayjs(form.end)} // Set the value of the picker
              onChange={(e) => handleDateChange(e, 'end')} // Handle change event
            />
          )}
        </Box>
      </form>
      <ColorPickerDialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        onSelectColor={handleColorChange}
        initialColor={form.backgroundColor}
      />
      <IconPickerDialog
        open={dialogOpenIcon}
        onClose={handleCloseDialogIcon}
        onSelectIcon={handleIconChange}
      />
    </CustomDialog>
  );
};

export default EventForm;
