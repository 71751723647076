import { useEffect } from 'react';
import DarkModeIcon from '@mui/icons-material/DarkModeRounded';
import LightModeIcon from '@mui/icons-material/LightModeRounded';
import { useColorScheme } from '@mui/material/styles';
import { Button, ButtonGroup } from '@mui/material';
import SettingsBrightnessIcon from '@mui/icons-material/SettingsBrightness';

export default function ColorModeIconDropdown(props) {
  const { mode, systemMode, setMode } = useColorScheme();

  // useEffect(() => {
  //   const userSettings = localStorage.getItem('colorThemeMode');
  //   if (userSettings) {
  //     // @ts-ignore
  //     setMode(userSettings);
  //   } else {
  //     setMode('system');
  //   }

  //   return () => {};
  // }, []);

  const handleMode = (targetMode) => () => {
    // localStorage.setItem('colorThemeMode', targetMode);
    setMode(targetMode);
  };

  return (
    <>
      <ButtonGroup aria-label="ColorThemeMode">
        <Button
          variant={mode === 'system' ? 'contained' : 'outlined'}
          onClick={handleMode('system')}
          startIcon={<SettingsBrightnessIcon />}
        >
          System
        </Button>
        <Button
          variant={mode === 'light' ? 'contained' : 'outlined'}
          onClick={handleMode('light')}
          startIcon={<LightModeIcon />}
        >
          Light
        </Button>
        <Button
          variant={mode === 'dark' ? 'contained' : 'outlined'}
          onClick={handleMode('dark')}
          startIcon={<DarkModeIcon />}
        >
          Dark
        </Button>
      </ButtonGroup>
    </>
  );
}
