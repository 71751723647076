import { useEffect, useState } from 'react';

import { LoadingScreen } from 'components/other/LoadingScreen';
import { useAuth } from 'context/AuthContext';
import { useData } from 'context/DataContext';

// ----------------------------------------------------------------------

export function UserSettingGuard({ children }) {
  const { user, loading } = useAuth();
  const { userSettings, fetchUserSettings, fetching } = useData();

  const [isChecking, setIsChecking] = useState(true);

  const checkPermissions = async () => {
    if (loading) {
      return;
    }
    if (!loading && userSettings === null && !fetching) {
      await fetchUserSettings();
      return;
    }
    if (userSettings === null) {
      return;
    }

    setIsChecking(false);
  };

  useEffect(() => {
    checkPermissions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, loading, userSettings]);

  if (isChecking) {
    return <LoadingScreen text="Loading Settings" />;
  }

  return <>{children}</>;
}

export default UserSettingGuard;
