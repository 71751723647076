import LoadingButton from '@mui/lab/LoadingButton';
import {
  Avatar,
  FormControlLabel,
  Stack,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { STRINGS } from 'components/config';
import CustomDialog from 'components/customDialog/customDialog';
import { useData } from 'context/DataContext';
import { useToast } from 'context/ToastContext';
import { useEffect, useState } from 'react';
import { fetchGuildInfoWithCodeFullLink } from './discordInfoFetch';

const {
  LINKS: { DISCORD },
} = STRINGS;

const defaultForm = {
  guild_display_name: '',
  guild_description: '',
  guild_discord_invite_link: '',
  guild_avatar: '',
  guild_discord_server_id: '',
  guild_accepting_applications: true,
};

const CreateGuildForm = ({ open, handleCloseGuildForm, isEditing }) => {
  // @ts-ignore
  const { guildData } = useData();

  const [form, setForm] = useState(defaultForm);
  const [isBlocking, setIsBlocking] = useState(false);
  const [errors, setErrors] = useState({});
  const [isDisabled, setIsDisabled] = useState(false);
  const [approxMembers, setApproxMembers] = useState(null);
  const toast = useToast();
  // @ts-ignore
  const { createGuild, updateGuildInfo } = useData();
  const fetchDiscordInfo = async (guild_discord_invite_link) => {
    if (guild_discord_invite_link) {
      const result = await fetchGuildInfoWithCodeFullLink(
        guild_discord_invite_link
      );
      try {
        if (result?.guild) {
          const { name, id, icon, description } = result.guild;
          const avatar_link = id && icon && DISCORD.ICON_LINK(id, icon);
          // TODO: could add banner
          setApproxMembers(result?.approximate_member_count || null);
          setForm({
            guild_display_name: name,
            guild_description: description || '',
            guild_discord_invite_link: guild_discord_invite_link || '',
            guild_avatar: avatar_link || '',
            guild_discord_server_id: id,
            guild_accepting_applications: true,
          });
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
    setTimeout(() => {
      setIsDisabled(false);
    }, 1000);
  };

  const handleToggle = (e) => {
    const { name, checked } = e.target;
    setForm((prev) => ({ ...prev, [name]: checked }));
    if (errors[name]) {
      setErrors((prev) => ({ ...prev, [name]: '' }));
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'guild_discord_invite_link' && guildData === null) {
      // some bug with guildData {}
      setIsDisabled(true);
      fetchDiscordInfo(value); //fill discord data fetch
    }
    setForm((prev) => ({ ...prev, [name]: value }));
    if (errors[name]) {
      setErrors((prev) => ({ ...prev, [name]: '' }));
    }
  };

  const validate = () => {
    const newErrors = {};
    if (!form.guild_display_name)
      newErrors.guild_display_name = 'Guild name is required';
    if (!form.guild_description)
      newErrors.guild_description = 'A Description is required';
    if (!form.guild_discord_invite_link)
      newErrors.guild_discord_invite_link = 'Discord Link is required';
    if (!form.guild_discord_server_id)
      newErrors.guild_discord_server_id = 'Discord server Id is required';
    return newErrors;
  };

  const handleClick = async (e, path) => {
    e.preventDefault();
    const newErrors = validate();

    if (Object.keys(newErrors).length === 0) {
      setIsBlocking(true);
      if (path === 'CREATE') {
        const response = await createGuild({
          ...form,
          guild_avatar: form?.guild_avatar || null,
        });
        setIsBlocking(false);
        if (!response.success) {
          toast.error(`Failed to create, guild limit reached - ${response.errorCode}`);
        } else {
          // @ts-ignore
          toast.success('Guild Created');
        }
      }

      if (path === 'EDIT') {
        const response = await updateGuildInfo({ ...form });
        setIsBlocking(false);
        if (!response.success) {
          toast.error(`Failed to update - ${response.errorCode}`);
        }
      }

      handleCloseGuildForm();
    } else {
      return setErrors(newErrors);
    }
  };

  // useEffect(() => {
  //   if (isEditing) {
  //     const formData = {};

  //     Object.keys(guildData).forEach((key) => {
  //       if (
  //         typeof guildData[key] !== 'object' ||
  //         Array.isArray(guildData[key])
  //       ) {
  //         // Exclude nested objects
  //         formData[key] = guildData[key];
  //       }
  //     });
  //     setForm({ ...formData });
  //   }
  //   return () => {};
  // }, [isEditing]);

  return (
    <CustomDialog
      open={open}
      onClose={() => {
        handleCloseGuildForm();
        setForm(defaultForm);
        setApproxMembers(null);
        setIsDisabled(false);
      }}
      title="CREATE"
      actions={
        <LoadingButton
          type="submit"
          form="GuildCreation"
          variant="contained"
          color="inherit"
          fullWidth
          sx={{ mt: 2 }}
          loading={isBlocking}
        >
          CREATE GUILD
        </LoadingButton>
      }
    >
      <form
        id="GuildCreation"
        // @ts-ignore

        onSubmit={(e) => handleClick(e, 'CREATE')}
      >
        {approxMembers && (
          <Typography variant="button" align="center" style={{ fontSize: 12 }}>
            {`Approx Members: ${approxMembers}`}
          </Typography>
        )}

        <TextField
          label="Full Discord Invite Link - Example https://discord.gg/mg564S6"
          variant="standard"
          fullWidth
          name="guild_discord_invite_link"
          value={form.guild_discord_invite_link}
          disabled={isDisabled}
          onChange={handleChange}
          // @ts-ignore
          error={!!errors.guild_discord_invite_link}
          // @ts-ignore
          helperText={errors.guild_discord_invite_link}
          margin="normal"
        />
        <TextField
          label="Guild Name"
          variant="standard"
          fullWidth
          name="guild_display_name"
          value={form.guild_display_name}
          onChange={handleChange}
          // @ts-ignore
          error={!!errors.guild_display_name}
          // @ts-ignore
          helperText={errors.guild_display_name}
          margin="normal"
        />
        <TextField
          label="Description"
          variant="standard"
          fullWidth
          name="guild_description"
          value={form.guild_description}
          onChange={handleChange}
          // @ts-ignore
          error={!!errors.guild_description}
          // @ts-ignore
          helperText={errors.guild_description}
          margin="normal"
          multiline
        />
        <Stack
          direction="row"
          sx={{
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <TextField
            label="Guild Image Link"
            variant="standard"
            fullWidth
            name="guild_avatar"
            value={form.guild_avatar || ''}
            onChange={handleChange}
            margin="normal"
          />
          {form.guild_avatar && (
            <Avatar
              alt={form?.guild_avatar?.charAt(0)}
              sizes="small"
              src={form.guild_avatar}
              sx={{ width: 48, height: 48 }}
            />
          )}
        </Stack>

        <TextField
          label="Discord Sever Id"
          variant="standard"
          fullWidth
          name="guild_discord_server_id"
          value={form.guild_discord_server_id}
          onChange={handleChange}
          margin="normal"
          // @ts-ignore
          error={!!errors.guild_discord_server_id}
          // @ts-ignore
          helperText={errors.guild_discord_server_id}
        />

        <FormControlLabel
          control={
            <Switch
              color="warning"
              name="guild_accepting_applications"
              checked={form.guild_accepting_applications}
              onChange={handleToggle}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          }
          label="Guild Accepting Applications"
        />
      </form>
    </CustomDialog>
  );
};

export default CreateGuildForm;
