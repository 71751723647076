import { Favorite, Home, Star, Work } from '@mui/icons-material';
import AddCircleOutline from '@mui/icons-material/AddCircleOutline';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import { Select } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Step from '@mui/material/Step';
import StepConnector, {
  stepConnectorClasses,
} from '@mui/material/StepConnector';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useData } from 'context/DataContext';
import { useToast } from 'context/ToastContext';
import { useEffect, useRef, useState } from 'react';

const iconOptions = {
  Favorite: { name: 'Favorite', icon: <Favorite /> },
  Star: { name: 'Star', icon: <Star /> },
  Work: { name: 'Work', icon: <Work /> },
  Home: { name: 'Home', icon: <Home /> },
};

const useDebounce = (callback, delay) => {
  const timeoutRef = useRef(null);

  const debouncedFunction = (...args) => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      callback(...args);
    }, delay);
  };

  useEffect(() => {
    return () => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    };
  }, [delay]);

  return debouncedFunction;
};

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#784af4',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#784af4',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
    ...theme.applyStyles('dark', {
      borderColor: theme.palette.grey[800],
    }),
  },
}));

export default function DynamicGoalStepper() {
  const [steps, setSteps] = useState([]);
  const toast = useToast();
  const { guildData, createGoal, updateGoal, deleteGoal } = useData();

  const activeStep = getActiveIndex();
  useEffect(() => {
    if (guildData.Guild_Goals) {
      setSteps(guildData.Guild_Goals);
    }

    return () => {};
  }, [guildData.Guild_Goals]);

  // Debounce async task
  const debouncedAsyncTask = useDebounce(async (index, key, value) => {
    const response = await updateGoal({
      id: guildData.Guild_Goals[index].id,
      data: { [key]: value },
    });
    if (response.success) {
      toast.success('Updated stored');
    } else {
      toast.error(`Failed - ${response.errorCode}`);
    }
    // await new Promise(async (resolve) => {
    //   setTimeout(resolve, 1000);

    // }); // Simulate async task
  }, 300);

  const handleStepChange = (index, key, value) => {
    // Immediate state update
    const updatedSteps = [...steps];
    updatedSteps[index][key] = value;
    setSteps(updatedSteps);

    // Schedule debounced async task
    debouncedAsyncTask(index, key, value);
  };
  const toggleStatus = async (index) => {
    const updatedSteps = [...steps];
    const newStatus =
      updatedSteps[index].status === 'in-progress'
        ? 'completed'
        : 'in-progress';
    updatedSteps[index].status = newStatus;

    const response = await updateGoal({
      id: guildData.Guild_Goals[index].id,
      data: {
        status: newStatus,
      },
    });
    if (response.success) {
      toast.success('Updated Status');
    } else {
      toast.error(`Failed - ${response.errorCode}`);
    }
    setSteps(updatedSteps);
  };

  const addStep = async () => {
    const response = await createGoal({
      label: `Step ${guildData.Guild_Goals.length + 1}`,
      goal: 0,
      icon: 'Favorite',
      status: 'in-progress',
    });
    if (response.success) {
      toast.success('Success');
    } else {
      toast.error(`Failed - ${response.errorCode}`);
    }
  };

  const removeStep = async (index) => {
    const updatedSteps = steps.filter((_, i) => i !== index);
    setSteps(updatedSteps);
    const response = await deleteGoal({ id: guildData.Guild_Goals[index].id });
    if (response.success) {
      toast.success('Deleted');
    } else {
      toast.error(`Failed - ${response}`);
    }
  };

  function getActiveIndex(arr) {
    return steps.findIndex((obj) => obj.status === 'in-progress');
  }
  return (
    <Box sx={{ width: '100%' }}>
      {/* Dynamic Stepper */}
      {/* <Stepper activeStep={activeStep}> */}
      <Stepper
        alternativeLabel
        activeStep={activeStep}
        connector={<QontoConnector />}
      >
        {steps.map((step, index) => (
          <Step key={index}>
            <StepLabel
              icon={step.icon}
              optional={
                <Box
                  sx={{
                    textAlign: 'center',
                    flexDirection: 'column',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {/* Status Chip */}
                  <Chip
                    color={
                      step.status === 'in-progress' ? 'warning' : 'success'
                    }
                    label={
                      step.status === 'in-progress'
                        ? 'In Progress'
                        : 'Completed'
                    }
                    onClick={() => toggleStatus(index)}
                    sx={{
                      maxWidth: '100px',
                      mt: 1,
                      mb: 1,
                      backgroundColor:
                        step.status === 'in-progress' ? 'yellow' : 'green',
                      color: '#000',
                      fontWeight: 'bold',
                      cursor: 'pointer',
                    }}
                  />
                  {/* Goal Value */}
                  {step.goal && (
                    <Typography variant="caption" color="text.secondary">
                      Goal: {step.goal}
                    </Typography>
                  )}
                </Box>
              }
            >
              {step.label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>

      {/* Form for dynamic steps */}
      <Box
        sx={{
          mt: 4,
          display: 'flex',
          overflowX: 'auto',
          gap: 2,
          py: 2,
          px: 1,
        }}
      >
        {steps.map((step, index) => (
          <Box
            key={index}
            sx={{
              width: 300,
              border: '1px solid #ddd',
              borderRadius: 2,
              p: 2,
              flexShrink: 0,
              position: 'relative',
            }}
          >
            <TextField
              label="Step Title"
              value={step.label}
              onChange={(e) => handleStepChange(index, 'label', e.target.value)}
              fullWidth
              sx={{ mb: 2 }}
            />
            <TextField
              label="Goal Value"
              value={step.goal}
              onChange={(e) => handleStepChange(index, 'goal', e.target.value)}
              type="number"
              fullWidth
              sx={{ mb: 2 }}
            />
            <Select
              value={step.icon}
              onChange={(e) =>
                handleStepChange(
                  index,
                  'icon',
                  iconOptions[e.target.value].name
                )
              }
              fullWidth
              displayEmpty
            >
              {Object.values(iconOptions).map((option) => (
                <MenuItem key={option.name} value={option.name}>
                  {option.icon}
                  {option.name}
                </MenuItem>
              ))}
            </Select>
            <IconButton
              onClick={() => removeStep(index)}
              color="error"
              disabled={steps.length === 1}
              sx={{ mt: 2 }}
            >
              <DeleteOutline />
            </IconButton>
          </Box>
        ))}
      </Box>

      <Button
        onClick={addStep}
        variant="outlined"
        startIcon={<AddCircleOutline />}
        sx={{ mt: 2 }}
      >
        Add Step
      </Button>
    </Box>
  );
}
