import PropTypes from 'prop-types';
import { PieChart } from '@mui/x-charts/PieChart';
import { useDrawingArea } from '@mui/x-charts/hooks';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';

const StyledText = styled('text', {
  shouldForwardProp: (prop) => prop !== 'variant',
})(({ theme }) => ({
  textAnchor: 'middle',
  dominantBaseline: 'central',
  fill: (theme.vars || theme).palette.text.secondary,
  variants: [
    {
      props: {
        variant: 'primary',
      },
      style: {
        fontSize: theme.typography.h5.fontSize,
      },
    },
    {
      props: ({ variant }) => variant !== 'primary',
      style: {
        fontSize: theme.typography.body2.fontSize,
      },
    },
    {
      props: {
        variant: 'primary',
      },
      style: {
        fontWeight: theme.typography.h5.fontWeight,
      },
    },
    {
      props: ({ variant }) => variant !== 'primary',
      style: {
        fontWeight: theme.typography.body2.fontWeight,
      },
    },
  ],
}));

function PieCenterLabel({ primaryText, secondaryText }) {
  const { width, height, left, top } = useDrawingArea();
  const primaryY = top + height / 2 - 10;
  const secondaryY = primaryY + 24;

  return (
    <>
      <StyledText variant="primary" x={left + width / 2} y={primaryY}>
        {primaryText}
      </StyledText>
      <StyledText variant="secondary" x={left + width / 2} y={secondaryY}>
        {secondaryText}
      </StyledText>
    </>
  );
}

PieCenterLabel.propTypes = {
  primaryText: PropTypes.string.isRequired,
  secondaryText: PropTypes.string.isRequired,
};

const colors = [
  'hsl(220, 20%, 65%)',
  'hsl(220, 20%, 42%)',
  'hsl(220, 20%, 35%)',
  'hsl(220, 20%, 25%)',
];

export default function CustomPieChart({ title, data, applyFilter }) {
  const totalMembersWithClassRole = data.reduce(
    (acc, item) => acc + item.value,
    0
  );

  const getArcLabel = (params) => {
    const percent = params.value / totalMembersWithClassRole;
    return `${(percent * 100).toFixed(0)}%`;
  };

  return (
    <Card
      variant="outlined"
      sx={{
        display: 'flex',
        flex: 0.6,
        flexDirection: 'column',
        // gap: '8px',
        // flexGrow: 1,
      }}
    >
      <CardContent>
        {/* <Typography component="h2" variant="subtitle2">
          {title}
        </Typography> */}
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <PieChart
            onItemClick={(e, p, item) =>
              applyFilter({
                columnField: 'in_game_class_role',
                searchValue: item.label,
              })
            }
            colors={colors}
            margin={{
              left: 80,
              right: 80,
              top: 80,
              bottom: 80,
            }}
            series={[
              {
                arcLabel: getArcLabel,
                data,
                innerRadius: 55,
                outerRadius: 100,
                paddingAngle: 0,
                highlightScope: { faded: 'global', highlighted: 'item' },
              },
            ]}
            height={300}
            width={260}
            slotProps={{
              legend: {
                padding: { top: 8 },
                position: { horizontal: 'middle', vertical: 'bottom' },
                direction: 'row',
              },
              // legend: { hidden: true },
            }}
          >
            {/* <PieCenterLabel
              primaryText={totalMembersWithClassRole}
              secondaryText="Total"
            /> */}
          </PieChart>
        </Box>
      </CardContent>
    </Card>
  );
}
