import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { Box, Button, Stack } from '@mui/material';
import { DataGrid, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { useData } from 'context/DataContext';
import {
  renderAttendance,
  renderAvatar,
  renderClass,
} from 'internals/data/gridData';
import './dataGridStyle.css'; // Import custom CSS file

// https://mui.com/x/react-data-grid/style/ style class cell colors

const CustomToolbar = ({ setFilterModel }) => {
  return (
    <Stack sx={{ p: 1 }} direction="row">
      <GridToolbarQuickFilter sx={{ pr: 1 }} />

      <Button
        variant="outlined"
        startIcon={<RestartAltIcon />}
        onClick={() => setFilterModel({ items: [] })} // clear filter
      >
        Reset Filters
      </Button>
    </Stack>
  );
};

function getMemberColumns({ userRank }) {
  let memberArray = [
    {
      field: 'discord_avatar',
      headerName: '',
      flex: 0.1,
      minWidth: 50,
      renderCell: (params) => renderAvatar(params),
    },
    {
      field: 'discord_display_name',
      headerName: 'Discord Name',
      flex: 0.5,
      minWidth: 100,
      renderCell: (params) => params.value || 'not provided',
    },
    {
      field: 'in_game_name',
      headerName: 'IGN',
      flex: 0.4,
      minWidth: 150,
      renderCell: (params) => params.value || 'Not Provided',
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 0.4,
      minWidth: 150,
      renderCell: (params) => renderAttendance(params) || 'Not Provided',
    },
    {
      field: 'in_game_class_role',
      headerName: 'Role',
      flex: 0.1,
      minWidth: 110,
      headerAlign: 'right',
      align: 'right',
      renderCell: (params) => params.value || 'Not Provided',
    },
    {
      field: 'in_game_class',
      headerName: 'Class',
      flex: 0.3,
      minWidth: 150,
      renderCell: (params) => renderClass(params) || 'Not Provided',
    },
  ];

  return memberArray;
}

export default function GuildAttendanceGrid({
  data,
  filterModel,
  setFilterModel,
}) {
  // @ts-ignore
  const { userSettings } = useData();
  if (!data) {
    return null;
  }

  const columns = getMemberColumns({ userRank: userSettings?.user_role });

  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        tableLayout: 'fixed',
      }}
    >
      <DataGrid
        align="center"
        // hideFooter
        // checkboxSelection
        // @ts-ignore
        columns={columns}
        getRowId={(item) => item.discord_id}
        rows={data}
        initialState={{
          pagination: { paginationModel: { pageSize: 20 } },
        }}
        pageSizeOptions={[10, 20, 50]}
        filterModel={filterModel}
        slotProps={{ toolbar: { setFilterModel } }}
        slots={{
          toolbar: CustomToolbar,
        }}
        onFilterModelChange={(newModel) => setFilterModel(newModel)}
      />
    </Box>
  );
}
