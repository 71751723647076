import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';

const CustomDialog = ({
  open,
  onClose,
  title,
  children,
  actions,
  ...other
}) => {
  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose} {...other}>
      {title && (
        <DialogTitle sx={{ textAlign: 'center', pb: 2 }}>{title}</DialogTitle>
      )}
      {children && (
        <DialogContent sx={{ typography: 'body2' }} className="overRideScroll">
          {children}
        </DialogContent>
      )}
      <DialogActions>{actions}</DialogActions>
    </Dialog>
  );
};

export default CustomDialog;
