import { SvgIcon } from '@mui/material';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { ReactComponent as icon } from 'assets/icons/ic-content.svg';

// ----------------------------------------------------------------------

export function EmptyContent({
  sx = null,
  action = null,
  filled = null,
  slotProps = null,
  description = null,
  title = 'No data',
  ...other
}) {
  return (
    <Stack
      flexGrow={1}
      alignItems="center"
      justifyContent="center"
      sx={{
        px: 3,
        height: 1,
        ...(filled && {
          borderRadius: 2,
        }),
        ...sx,
      }}
      {...other}
    >
      <img src="https://media.tenor.com/gx1kZF3d8W8AAAAi/flexo4.gif" />

      {title && (
        <Typography
          variant="h6"
          component="span"
          sx={{
            mt: 1,
            textAlign: 'center',
            ...slotProps?.title,
            color: 'text.disabled',
          }}
        >
          {title.toUpperCase()}
        </Typography>
      )}

      {description && (
        <Typography
          variant="caption"
          sx={{
            mt: 1,
            textAlign: 'center',
            color: 'text.disabled',
            ...slotProps?.description,
          }}
        >
          {description}
        </Typography>
      )}

      {action && action}
    </Stack>
  );
}
