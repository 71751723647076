// @ts-nocheck
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import { dividerClasses } from '@mui/material/Divider';
import { listClasses } from '@mui/material/List';
import ListItemIcon, { listItemIconClasses } from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MuiMenuItem from '@mui/material/MenuItem';
import { paperClasses } from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { useData } from 'context/DataContext';
import { useToast } from 'context/ToastContext';
import { useBoolean } from 'hooks';
import { useState } from 'react';
import MenuButton from '../legacy/MenuButton';

const MemberAppActions = ({ handleAction, isBlocking, confirm }) => {
  return (
    <>
      <MenuItem
        onClick={handleAction}
        sx={{
          [`& .${listItemIconClasses.root}`]: {
            ml: '16px', // auto
            minWidth: 0,
          },
          '&:hover': {
            backgroundColor: 'error.main', // Change background color on hover to error color theme
          },
        }}
      >
        <ListItemText>Remove</ListItemText>
        <ListItemIcon>
          <PersonRemoveIcon fontSize="small" />
        </ListItemIcon>
      </MenuItem>

      {/* <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Confirm Remove User"
        action={
          <LoadingButton
            //onClick={() => handleDelete('REJECTED')}
            type="submit"
            variant="contained"
            color="inherit"
            sx={{
              [`& .${listItemIconClasses.root}`]: {
                ml: '16px', //auto
                minWidth: 0,
              },
              '&:hover': {
                backgroundColor: 'error.main',
              },
            }}
            loading={isBlocking}
          >
            Remove
          </LoadingButton>
        }
        content="This will delete the application"
      /> */}
    </>
  );
};

const GuildAppActions = ({ handleAction, isBlocking, confirm, discord_id }) => {
  return (
    <>
      <MenuItem
        onClick={() => handleAction({ status: 'ACCEPTED', discord_id })}
        sx={{
          [`& .${listItemIconClasses.root}`]: {
            ml: '16px',
            minWidth: 0,
          },
          '&:hover': {
            backgroundColor: 'success.main',
          },
        }}
      >
        <ListItemText>Accept</ListItemText>
        <ListItemIcon>
          <PersonAddAltIcon fontSize="small" />
        </ListItemIcon>
      </MenuItem>
      <MenuItem
        onClick={() => handleAction({ status: 'DECLINED', discord_id })}
        sx={{
          [`& .${listItemIconClasses.root}`]: {
            ml: '16px',
            minWidth: 0,
          },
          '&:hover': {
            backgroundColor: 'error.main',
          },
        }}
      >
        <ListItemText>Decline</ListItemText>
        <ListItemIcon>
          <PersonRemoveIcon fontSize="small" />
        </ListItemIcon>
      </MenuItem>
    </>
  );
};

const MenuItem = styled(MuiMenuItem)({
  margin: '2px 0',
});

export default function GuildApplicationMenu({
  mode,
  guild_application_id,
  discord_id,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isBlocking, setIsBlocking] = useState(false);
  // @ts-ignore
  const { respondGuildApplication, leaveGuild, deleteGuildApplication } =
    useData();
  const confirm = useBoolean();
  const toast = useToast();

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  async function removeFromList(guild_application_id) {
    return await deleteGuildApplication({
      guild_application_id,
    });
  }

  const handleUsersGuildApplication = async ({ status, discord_id }) => {
    setIsBlocking(true);
    // DELETE FROM GUILD
    const result = await respondGuildApplication({
      guild_application_id,
      status,
      discord_id,
    });

    const didDeleteGuildApplication =
      await removeFromList(guild_application_id);
    if (didDeleteGuildApplication?.success) {
      toast.success(`Application ${status.toLowerCase()}`);
    } else {
      toast.error(
        `Application ${status.toLowerCase()} - Error - message a dev`
      );
    }
    setIsBlocking(false);
  };

  const handleRemoveGuildApplication = async () => {
    setIsBlocking(true);
    const didDeleteGuildApplication =
      await removeFromList(guild_application_id);

    if (didDeleteGuildApplication?.success) {
      toast.success('Application removed');
    } else {
      toast.error('Error removing application - message a dev');
    }
    setIsBlocking(false);
  };

  return (
    <>
      <MenuButton
        aria-label="Open menu"
        onClick={handleClick}
        sx={{ borderColor: 'transparent' }}
      >
        <MoreVertRoundedIcon />
      </MenuButton>
      <Menu
        anchorEl={anchorEl}
        id="menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        sx={{
          [`& .${listClasses.root}`]: {
            padding: '4px',
          },
          [`& .${paperClasses.root}`]: {
            padding: 0,
          },
          [`& .${dividerClasses.root}`]: {
            margin: '4px -4px',
          },
        }}
      >
        {mode === 'MEMBER' && (
          <MemberAppActions
            handleAction={handleRemoveGuildApplication}
            confirm={confirm}
          />
        )}
        {['OWNER', 'ADMIN'].includes(mode) && (
          <GuildAppActions
            handleAction={handleUsersGuildApplication}
            confirm={confirm}
            discord_id={discord_id}
          />
        )}
      </Menu>
    </>
  );
}

// FIX
