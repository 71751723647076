import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { STRINGS } from 'components/config';
import { useState } from 'react';

const { iconMap } = STRINGS;

const IconPickerDialog = ({ open, onClose, onSelectIcon, initialIcon }) => {
  const [selectedIcon, setSelectedIcon] = useState(
    initialIcon || Object.values(iconMap)[0]
  );

  const handleIconClick = (icon) => {
    setSelectedIcon(icon);
    onSelectIcon(icon);
    onClose(); // Close the dialog
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Select an Icon</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          {Object.values(iconMap).map(({ name, Icon }) => (
            <Grid size={{ xs: 3 }} item key={name}>
              <IconButton
                onClick={() => {
                  handleIconClick({ name, Icon });
                }}
                style={{
                  border:
                    selectedIcon.name === name
                      ? '2px solid black'
                      : '1px solid #ccc',
                  borderRadius: 8,
                  padding: 8,
                }}
              >
                <Icon style={{ fontSize: 32 }} />
              </IconButton>
            </Grid>
          ))}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default IconPickerDialog;
