import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { LoadingButton } from '@mui/lab';
import { dividerClasses } from '@mui/material/Divider';
import { listClasses } from '@mui/material/List';
import ListItemIcon, { listItemIconClasses } from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MuiMenuItem from '@mui/material/MenuItem';
import { paperClasses } from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { STRINGS } from 'components/config';
import { getDiscordInviteLinkCode } from 'components/guildHelpers/discordInfoFetch';
import { useData } from 'context/DataContext';
import { useToast } from 'context/ToastContext';
import { useBoolean } from 'hooks';
import { useRouter } from 'hooks/use-router';
import { useState } from 'react';
import { ConfirmDialog } from '../ConfirmDialog';
import MenuButton from '../legacy/MenuButton';

const MenuItem = styled(MuiMenuItem)({
  margin: '2px 0',
});

const { PATHS } = STRINGS;

export default function GuildOptionsMenu() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isBlocking, setIsBlocking] = useState(false);
  // @ts-ignore
  const { deleteGuild, guildData } = useData();
  const confirm = useBoolean();
  const toast = useToast();
  const router = useRouter();
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = async () => {
    setIsBlocking(true);
    const response = await deleteGuild();
    if (response.success) {
      toast.success('Success');
      router.push(PATHS.home);
    } else {
      toast.error(`Failed to delete guild - ${response.errorCode}`);
    }
    setIsBlocking(false);

    return confirm.onFalse();
  };

  const handleClickGuildInvite = () => {
    navigator.clipboard.writeText(
      `${window.location.protocol}//${window.location.host}` +
        PATHS.applications.root +
        `/${guildData.guild_discord_server_id}` +
        `/${getDiscordInviteLinkCode(guildData.guild_discord_invite_link)}`
    );
    return toast.success('Invite link copied');
  };

  return (
    <>
      <MenuButton
        aria-label="Open menu"
        onClick={handleClick}
        sx={{ borderColor: 'transparent' }}
      >
        <MoreVertRoundedIcon />
      </MenuButton>
      <Menu
        anchorEl={anchorEl}
        id="menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        sx={{
          [`& .${listClasses.root}`]: {
            padding: '4px',
          },
          [`& .${paperClasses.root}`]: {
            padding: 0,
          },
          [`& .${dividerClasses.root}`]: {
            margin: '4px -4px',
          },
        }}
      >
        {/* <MenuItem onClick={handleClose}>Profile</MenuItem> */}
        {/* <MenuItem onClick={handleClose}>My account</MenuItem> */}
        {/* <Divider /> */}
        {/* <MenuItem onClick={handleClose}>Add another account</MenuItem>
        <MenuItem onClick={handleClose}>Settings</MenuItem>
        <Divider /> */}
        <MenuItem
          onClick={handleClickGuildInvite}
          sx={{
            [`& .${listItemIconClasses.root}`]: {
              ml: '16px', // auto
              minWidth: 0,
            },
          }}
        >
          <ListItemText>Invite</ListItemText>
          <ListItemIcon>
            <PersonAddIcon fontSize="small" />
          </ListItemIcon>
        </MenuItem>

        <MenuItem
          onClick={() => confirm.onTrue()}
          sx={{
            [`& .${listItemIconClasses.root}`]: {
              ml: '16px', // auto
              minWidth: 0,
            },
            '&:hover': {
              backgroundColor: 'error.main', // Change background color on hover to error color theme
            },
          }}
        >
          <ListItemText>Delete</ListItemText>
          <ListItemIcon>
            <DeleteForeverIcon fontSize="small" />
          </ListItemIcon>
        </MenuItem>
      </Menu>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Confirm Delete"
        action={
          <LoadingButton
            onClick={handleDelete}
            type="submit"
            variant="contained"
            color="inherit"
            sx={{
              [`& .${listItemIconClasses.root}`]: {
                ml: '16px', // auto
                minWidth: 0,
              },
              '&:hover': {
                backgroundColor: 'error.main',
              },
            }}
            loading={isBlocking}
          >
            Delete
          </LoadingButton>
        }
        content="This action can not be undone"
      />
    </>
  );
}
