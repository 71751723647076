import { useEffect, useState } from 'react';

import { LoadingScreen } from 'components/other/LoadingScreen';
import { useData } from 'context/DataContext';
import { useRouter } from 'hooks/use-router';

// ----------------------------------------------------------------------

export function GuildGuard({ children }) {
  const router = useRouter();

  const { guildData, userSettings } = useData();

  const [isChecking, setIsChecking] = useState(true);

  const checkPermissions = async () => {
    if (guildData === null || guildData?.length !== undefined) {
      // router.replace('/home');
      if (userSettings.guild_discord_server_id === null) {
        setIsChecking(false);
      }
      return;
    }

    setIsChecking(false);
  };

  useEffect(() => {
    checkPermissions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [guildData]);

  if (isChecking) {
    return <LoadingScreen text={'Loading Guild Data'} />;
  }

  return <>{children}</>;
}

export default GuildGuard;
