import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { Box, Button, Chip, Stack } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import GuildApplicationMenu from 'components/menu/GuildApplicationMenu';
import { EmptyContent } from 'components/empty-content';
import dayjs from 'dayjs';
import { renderClass } from 'internals/data/gridData';
import { useState } from 'react';

// https://mui.com/x/react-data-grid/style/ style class cell colors

const CustomToolbar = ({ setFilterModel }) => {
  return (
    <Stack sx={{ p: 1 }} direction="row">
      <Button
        variant="outlined"
        startIcon={<RestartAltIcon />}
        onClick={() => setFilterModel({ items: [] })} // clear filter
      >
        Reset Filters
      </Button>
    </Stack>
  );
};

// const statusDict = {
//   ACCEPTED: 'ACCEPTED',
//   PENDING: 'PENDING',
//   REJECTED: 'REJECTED',
// };

function getColor(value) {
  switch (value) {
    case 'ACCEPTED':
      return 'success';
    case 'PENDING':
      return 'warning';
    case 'DECLINED':
      return 'error';
    default:
      return 'default';
  }
}

function getColumns(mode) {
  const applicationColumnArray = [
    {
      field: 'created_at',
      headerName: 'Applied at',
      headerAlign: 'left',
      align: 'left',
      flex: 0.5,
      minWidth: 220,
      renderCell: (params) =>
        dayjs(params.value).format('MMMM D, YYYY [at] h:mm A'),
      // type: 'string',
    },
    {
      field: 'in_game_class_role',
      headerName: 'Role',
      flex: 0.1,
      minWidth: 100,
    },
    {
      field: 'in_game_class',
      headerName: 'Class',
      headerAlign: 'left',
      align: 'left',
      flex: 0.2,
      minWidth: 100,
      renderCell: (params) => renderClass(params),
    },
    {
      field: 'discord_display_name',
      headerName: 'Discord Name',
      flex: 0.4,
      minWidth: 100,
      renderCell: (params) => params.value || 'Not provided',
    },
    {
      field: 'in_game_name',
      headerName: 'IGN',
      flex: 0.3,
      minWidth: 100,
      renderCell: (params) => params.value || 'Not provided',
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 0.2,
      minWidth: 100,
      renderCell: (params) => {
        const color = getColor(params.value.toUpperCase());
        return params.value ? (
          <Chip label={params.value} color={color} variant="outlined" />
        ) : (
          'not provided'
        );
      },
    },
    {
      field: '',
      headerName: 'actions',
      flex: 0.2,
      minWidth: 100,
      renderCell: (params) => (
        <GuildApplicationMenu
          mode={mode}
          guild_application_id={params.row.guild_application_id}
          discord_id={params.row.discord_id}
        />
      ),
    },
  ];
  if (mode === 'MEMBER') {
    applicationColumnArray.splice(0, 0, {
      field: 'guild_discord_server_id',
      headerName: 'Guild Name',
      flex: 0.2,
      minWidth: 100,
    });
  }
  return applicationColumnArray;
}

export default function GuildApplicationsGrid({ data, mode }) {
  const [filterModel, setFilterModel] = useState({
    items: [],
  });
  if (!data) {
    return null;
  }
 
  const columns = getColumns(mode);
  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        tableLayout: 'fixed',
      }}
    >
      <DataGrid
        align="center"
        // hideFooter
        // checkboxSelection
        // @ts-ignore
        columns={columns}
        getRowId={(item) => item.discord_id}
        rows={data}
        initialState={{
          sorting: {
            sortModel: [{ field: 'created_at', sort: 'desc' }],
          },
          // filter: {
          //   filterModel: {
          //     items: [
          //       {
          //         field: 'Status',
          //         operator: 'contains',
          //         value: 'PENDING',
          //       },
          //     ],
          //   },
          // },
          pagination: { paginationModel: { pageSize: 20 } },
        }}
        pageSizeOptions={[10, 20, 50]}
        slotProps={{ toolbar: { setFilterModel } }}
        slots={{
          toolbar: CustomToolbar,
          noRowsOverlay: () => <EmptyContent title="No Data" />,
          noResultsOverlay: () => <EmptyContent title="No results found" />,
        }}
        filterModel={filterModel}
        onFilterModelChange={(newModel) => setFilterModel(newModel)}

        // getRowClassName={(params) =>
        //   params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
        // }
        // disableColumnResize
        // density="compact"

        // slotProps={{
        //   filterPanel: {
        //     filterFormProps: {
        //       logicOperatorInputProps: {
        //         variant: 'outlined',
        //         size: 'small',
        //       },
        //       columnInputProps: {
        //         variant: 'outlined',
        //         size: 'small',
        //         sx: { mt: 'auto' },
        //       },
        //       operatorInputProps: {
        //         variant: 'outlined',
        //         size: 'small',
        //         sx: { mt: 'auto' },
        //       },
        //       valueInputProps: {
        //         InputComponentProps: {
        //           variant: 'outlined',
        //           size: 'small',
        //         },
        //       },
        //     },
        //   },
        // }}
      />
    </Box>
  );
}
