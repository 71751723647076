import { Avatar } from '@mui/material';
import { STRINGS } from 'components/config';

const { classIconMap, classColorMap } = STRINGS;

export const CustomAvatar = ({
  in_game_class,
  width = '36px',
  height = '36px',
}) => {
  const color = classColorMap[in_game_class.toUpperCase()];
  const result = classIconMap[in_game_class.toUpperCase()];
  return (
    <Avatar
      alt={in_game_class}
      src={result}
      sx={{
        border: `2px solid ${color}`,
        width: width,
        height: height,
        // fontSize: '0.85rem',
      }}
    />
  );
};
