import { Box, Typography, Avatar, Paper } from '@mui/material';
import { STRINGS } from 'components/config';

const {
  LINKS: {
    DISCORD: { ICON_LINK, BANNER_LINK },
  },
} = STRINGS;

const DiscordPreviewBanner = ({ data }) => {
  const guildIcon = ICON_LINK(data.guild.id, data.guild.icon);
  const bannerImage = BANNER_LINK(data.guild.id, data.guild.banner);
  const guildName = data.guild.name;
  const guildDescription = data.guild.description;

  return (
    <Paper
      elevation={4}
      sx={{
        position: 'relative',
        borderRadius: 3,
        overflow: 'hidden',
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
      }}
    >
      {/* Banner Image */}
      <Box
        sx={{
          height: { sm: 180 },
          backgroundImage: `url(${bannerImage})`,
          backgroundColor: 'grey',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          filter: 'brightness(0.5)',
        }}
      />

      {/* Content */}
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          color: 'white',
          textAlign: 'center',
          p: 2,
        }}
      >
        {/* Guild Icon */}
        <Avatar
          src={guildIcon}
          alt="Guild Icon"
          sx={{
            width: 100,
            height: 100,
            border: '3px solid white',
            mb: 2,
          }}
        />

        {/* Guild Name */}
        <Typography
          variant="h4"
          sx={{
            fontWeight: 'bold',
            textShadow: '2px 2px 4px rgba(0, 0, 0, 0.7)',
            mb: 1,
          }}
        >
          {guildName}
        </Typography>

        {/* Guild Description */}
        <Typography
          variant="body1"
          sx={{
            textShadow: '1px 1px 3px rgba(0, 0, 0, 0.6)',
            maxWidth: 600,
          }}
        >
          {guildDescription}
        </Typography>
      </Box>
    </Paper>
  );
};

export default DiscordPreviewBanner;
