import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import { Stack, Tooltip, Typography } from '@mui/material';
import { EventForm } from 'components/calendar/NewEventForm';
import { STRINGS } from 'components/config';
import { useData } from 'context/DataContext';
import { useToast } from 'context/ToastContext';
import { useParams } from 'hooks/use-params';
import { useRouter } from 'hooks/use-router';
import { useEffect, useState } from 'react';
const { PATHS, iconMap } = STRINGS;

let eventGuid = 0;
let todayStr = new Date().toISOString().replace(/T.*$/, ''); // YYYY-MM-DD of today

export function createEventId() {
  return String(eventGuid++);
}

export function FullCalendarView() {
  const [weekendsVisible, setWeekendsVisible] = useState(true);
  const [selectedEvent, setSelectedEvent] = useState([]);

  const params = useParams();
  const { event_id } = params;
  const router = useRouter();
  const toast = useToast();
  const [open, setOpen] = useState(false);
  const { guildData, userSettings, updateGuildEvent } = useData();
  const isManagement =
    userSettings.user_role.includes('owner') ||
    userSettings.user_role.includes('admin');

  function handleWeekendsToggle() {
    setWeekendsVisible(!weekendsVisible);
  }

  const handleEventDrop = async (info) => {
    const { event } = info;
    const { start, end, allDay } = event;
    const {
      event_id,
      // dkp_reward,
      // event_description,
      // event_location_link,
      // Guild_Event_Attendance,
    } = event.extendedProps;

    // Log the event's new date/time

    const response = await updateGuildEvent({ event_id, start, end, allDay });
    if (response.success) {
      toast.success('Success');
    } else {
      toast.error(`Failed = ${response.errorCode}`);
    }
  };

  function handleDateSelect(selectInfo) {
    if (isManagement) {
      setSelectedEvent(selectInfo);
      setOpen(true);
    }
  }

  function handleEventClick(clickInfo) {
    // const { title, start, end, allDay } = clickInfo.event;
    const { event_id } = clickInfo.event.extendedProps;

    router.push(PATHS.events.details(event_id));
  }

  useEffect(() => {
    if (event_id && !open) {
      const eventFind = guildData.Guild_Events.find(
        (event) => event.event_id === event_id
      );
      setSelectedEvent(eventFind);
      setOpen(true);
    }
    return () => {};
  }, [event_id]);

  if (guildData === null) {
    return null;
  }

  const events = guildData.Guild_Events;

  return (
    <Stack sx={{ height: '100%' }}>
      {/* <Sidebar
        weekendsVisible={weekendsVisible}
        handleWeekendsToggle={handleWeekendsToggle}
        currentEvents={currentEvents}
      /> */}

      <FullCalendar
        firstDay={1} // Start the week on Monday
        expandRows={true}
        height="100%"
        slotMinTime="00:00:00" // Start time of the day
        slotMaxTime="24:00:00" // End time of the day
        slotDuration="01:00:00" // Each slot represents 1 hour
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        headerToolbar={{
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay',
        }}
        initialView="timeGridWeek"
        allDaySlot={false}
        editable={true}
        selectable={true}
        selectMirror={true}
        dayMaxEvents={true}
        weekends={weekendsVisible}
        events={events}
        select={handleDateSelect}
        eventContent={renderEventContent} // custom render function
        eventClick={handleEventClick}
        eventDrop={handleEventDrop} // Trigger when an event is dropped
        /* you can update a remote database when these fire:
          eventAdd={function(){}}
          eventChange={function(){}}
          eventRemove={function(){}}
          */
      />

      <EventForm
        open={open}
        eventInfo={selectedEvent}
        onClose={() => {
          setOpen(false);
          router.push(PATHS.events.root);
          setSelectedEvent([]);
        }}
        isManagement={isManagement}
      ></EventForm>
    </Stack>
  );
}

const renderEventContent = ({ event }) => {
  const { event_description, event_status, event_icon } = event.extendedProps;
  const { Icon } = iconMap[event_icon] || {};
  const { title } = event;
  return (
    <Tooltip title={event_description}>
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        justifyContent="space-between"
        sx={{ width: '100%', height: '100%' }}
      >
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography variant="body2" style={{ fontWeight: 'bold' }}>
            {event.start.toLocaleTimeString([], {
              hour: '2-digit',
              minute: '2-digit',
            })}
          </Typography>

          <Typography variant="body2" style={{ fontStyle: 'italic' }}>
            {title}
          </Typography>
        </Stack>

        {/* Stack for Icons on the right */}
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          justifyContent="flex-end"
        >
          {Icon ? <Icon /> : null}
          {/* Conditional Icon based on status */}
          {event_status === 'PENDING' ? (
            <PauseCircleOutlineIcon />
          ) : (
            <DoneAllIcon />
          )}
        </Stack>
      </Stack>
    </Tooltip>
  );
};
