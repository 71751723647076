import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded';
import Divider, { dividerClasses } from '@mui/material/Divider';
import { listClasses } from '@mui/material/List';
import ListItemIcon, { listItemIconClasses } from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MuiMenuItem from '@mui/material/MenuItem';
import { paperClasses } from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { useState } from 'react';
import { useAuth } from '../../context/AuthContext';
import MenuButton from '../legacy/MenuButton';
import SettingsIcon from '@mui/icons-material/Settings';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

const MenuItem = styled(MuiMenuItem)({
  margin: '2px 0',
});

export default function OptionsMenu({
  handleClickOpenUserProfile,
  handleClickOpenUserSettings,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const { signOut } = useAuth();
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <MenuButton
        aria-label="Open menu"
        onClick={handleClick}
        sx={{ borderColor: 'transparent' }}
      >
        <MoreVertRoundedIcon />
      </MenuButton>
      <Menu
        anchorEl={anchorEl}
        id="menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        sx={{
          [`& .${listClasses.root}`]: {
            padding: '4px',
          },
          [`& .${paperClasses.root}`]: {
            padding: 0,
          },
          [`& .${dividerClasses.root}`]: {
            margin: '4px -4px',
          },
        }}
      >
        <MenuItem
          onClick={() => {
            handleClickOpenUserSettings();
            handleClose();
          }}
          sx={{
            [`& .${listItemIconClasses.root}`]: {
              ml: '16px', // auto
              minWidth: 0,
            },
          }}
        >
          <ListItemText>Settings</ListItemText>
          <ListItemIcon>
            <SettingsIcon fontSize="small" />
          </ListItemIcon>
        </MenuItem>

        <MenuItem
          onClick={() => {
            handleClickOpenUserProfile();
            handleClose();
          }}
          sx={{
            [`& .${listItemIconClasses.root}`]: {
              ml: '16px', // auto
              minWidth: 0,
            },
          }}
        >
          <ListItemText>Profile</ListItemText>
          <ListItemIcon>
            <AccountCircleIcon fontSize="small" />
          </ListItemIcon>
        </MenuItem>
        {/* <MenuItem onClick={handleClose}>My account</MenuItem> */}
        <Divider />
        {/* <MenuItem onClick={handleClose}>Add another account</MenuItem>
        <MenuItem onClick={handleClose}>Settings</MenuItem>
        <Divider /> */}
        <MenuItem
          onClick={signOut}
          sx={{
            [`& .${listItemIconClasses.root}`]: {
              ml: '16px', //auto
              minWidth: 0,
            },
          }}
        >
          <ListItemText>Logout</ListItemText>
          <ListItemIcon>
            <LogoutRoundedIcon fontSize="small" />
          </ListItemIcon>
        </MenuItem>
      </Menu>
    </>
  );
}
