import { Box, TextField } from '@mui/material';
import UserEditing from 'components/ui/UserEditing';
import { useData } from 'context/DataContext';
import { useToast } from 'context/ToastContext';
import { useEffect, useState } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';

import { LoadingButton } from '@mui/lab';
import CustomDialog from 'components/customDialog/customDialog';
import PartyAdd from 'components/guildPages/raidMaker/PartyAdd';
import { useBoolean } from 'hooks';
import { useParams } from 'hooks/use-params';
import PartyGroup from './PartyGroup';
import PlayerPool from './PlayerPool';

const generateMockPlayers = (count) => {
  return Array.from({ length: count }, (_, index) => ({
    id: `player-${index + 1}`,
    name: `Player ${index + 1}`,
  }));
};

const generateMockParties = (count) => {
  const parties = {};
  for (let i = 1; i <= count; i++) {
    parties[`party${i}`] = { name: `Party ${i}`, members: [] };
  }
  return parties;
};

const RaidMaker = () => {
  const params = useParams();
  const { raid_id } = params;
  const {
    guildData,
    addMemberToParty,
    removeMemberFromParty,
    updateMemberParty,
    updateRaid,
    userSettings,
    createParty,
    deleteParty,
  } = useData();

  const toast = useToast();
  // const [parties, setParties] = useState(generateMockParties(8));
  const openPartyCreate = useBoolean();
  const raidIndex = guildData.Guild_Raids.findIndex(
    (raid) => raid.raid_id === raid_id
  );
  const [fakeRaidData, setFakeRaidData] = useState(
    guildData.Guild_Raids[raidIndex] || []
  );
  const [newPartyName, setNewPartyName] = useState('');

  const currentRaid = fakeRaidData;
  const parties = fakeRaidData?.Guild_Parties || [];
  const [remainingPlayers, setRemainingPlayers] = useState([]);
  const isManagement =
    userSettings.user_role.includes('owner') ||
    userSettings.user_role.includes('admin');
  const isDragDisabled = !(
    isManagement &&
    currentRaid.is_editing_discord_id === userSettings.discord_id
  );

  useEffect(() => {
    if (guildData.Guild_Raids[raidIndex]) {
      setFakeRaidData({ ...guildData.Guild_Raids[raidIndex] });
      setRemainingPlayers(
        guildData.Guild_Raids[raidIndex].Guild_Parties.reduce(
          (remainingUsers, guild) => {
            return guild.Guild_Party_Members.reduce((filteredUsers, member) => {
              return filteredUsers.filter(
                (user) => user.discord_id !== member.discord_id
              );
            }, remainingUsers);
          },
          [...(guildData?.Guild_User_Manage || [])]
        )
      );

      // Cleanup function to clear the timeout if guildData changes before delay
      return () => {};
    }

    return () => {};
  }, [guildData.Guild_Raids[raidIndex]]);

  if (raidIndex === -1) {
    return null;
  }

  const handleChange = (event) => {
    setNewPartyName(event.target.value);
  };

  const addRemainingPlayer = (discord_id) => {
    const player = guildData.Guild_User_Manage.find(
      (user) => user.discord_id === discord_id
    );
    if (player && !remainingPlayers.some((p) => p.discord_id === discord_id)) {
      setRemainingPlayers([...remainingPlayers, player]);
      return;
    }
    return; // Return unchanged state if player is not found or already exists.
  };

  const removeRemainingPlayer = (discord_id) => {
    setRemainingPlayers(
      remainingPlayers.filter((player) => player.discord_id !== discord_id)
    );

    return;
  };

  const findParty = (index) => {
    return parties[index].party_id;
  };

  const findUUID = (index, member_discord_id) => {
    // Get user UUID in party for easy removal
    const member = parties[index].Guild_Party_Members.find(
      (user) => user.discord_id === member_discord_id
    );
    return member.id;
  };

  const handleDragEnd = async (result) => {
    const { source, destination, type } = result;
    const player_discord_id = result.draggableId;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === 'playerPool' &&
      source.droppableId === 'playerPool'
    ) {
      return;
    }

    // 1 Add to party from playerPool
    if (source.droppableId === 'playerPool') {
      const party_id = findParty(destination.droppableId);
      // Add destructively local
      parties[destination.droppableId].Guild_Party_Members.push({
        discord_id: player_discord_id,
      });
      // Add destructively local
      removeRemainingPlayer(player_discord_id);
      const response = await addMemberToParty({
        discord_id: player_discord_id,
        party_id,
        raid_id: currentRaid.raid_id,
      });
      if (response.success) {
        toast.success('Success');
      } else {
        toast.error(`Failed - ${response.errorCode}`);
      }
      return;
    }
    // 2 Remove Player from Party for play pool

    if (destination.droppableId === 'playerPool') {
      const remove_id = findUUID(source.droppableId, player_discord_id);
      addRemainingPlayer(player_discord_id);
      // Remove destructively local
      const index = parties[source.droppableId].Guild_Party_Members.findIndex(
        (member) => member.discord_id === player_discord_id
      );

      // If a member is found, remove it using splice
      if (index !== -1) {
        parties[source.droppableId].Guild_Party_Members.splice(index, 1);
      }
      // Remove destructively local
      const response = await removeMemberFromParty({ id: remove_id });
      if (response.success) {
        toast.success('Success');
      } else {
        toast.error(`Failed - ${response.errorCode}`);
      }
      return;
      // const response = await removeFromParty()
    }

    // 3 Add to party remove from another party
    if (
      destination.droppableId !== 'playerPool' &&
      source.droppableId !== 'playerPool'
    ) {
      const party_member_uuid = findUUID(source.droppableId, player_discord_id);
      const new_party_id = findParty(destination.droppableId);
      // Local destructive
      parties[destination.droppableId].Guild_Party_Members.push({
        discord_id: player_discord_id,
      });
      // Remove destructively local
      const index = parties[source.droppableId].Guild_Party_Members.findIndex(
        (member) => member.discord_id === player_discord_id
      );

      // If a member is found, remove it using splice
      if (index !== -1) {
        parties[source.droppableId].Guild_Party_Members.splice(index, 1);
      }
      // Local destructive
      const response = await updateMemberParty({
        id: party_member_uuid,
        party_id: new_party_id,
      });
      if (response.success) {
        toast.success('Success');
      } else {
        toast.error(`Failed - ${response.errorCode}`);
      }
    }
  };

  const updatePartyName = (partyId, newName) => {
    // setParties((prev) => ({
    //   ...prev,
    //   [partyId]: { ...prev[partyId], name: newName },
    // }));
  };

  const handleDeleteParty = async (party_id) => {
    const response = await deleteParty({ party_id });
    if (response.success) {
      toast.success('Success');
    } else {
      toast.error(`Failed - ${response.errorCode}`);
    }
  };

  const handleLockEdits = async (newMode) => {
    const response = await updateRaid({
      raid_id: currentRaid.raid_id,
      data: {
        is_editing_discord_id: newMode ? userSettings.discord_id : null,
      },
    });
    if (response.success) {
      toast.success('Editing');
    } else {
      toast.error(`Failed - ${response.errorCode}`);
    }
  };

  const handleNewPartyCreate = async () => {
    const response = await createParty({
      raid_id: currentRaid.raid_id,
      party_name: newPartyName,
      party_number: parties.length,
    });
    if (response.success) {
      toast.success('Success');
      openPartyCreate.onFalse();
    } else {
      toast.error(`Failed - ${response.errorCode}`);
    }
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'row',
          height: '100%',
          width: '100%',
          // border: '1px solid yellow',
        }}
      >
        {isManagement && (
          <Box
            sx={{
              // flex: 1,
              display: 'flex',
              height: '100%',
              // width: '100%',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <UserEditing
              loggedInUser={userSettings.discord_id}
              userIdEditing={currentRaid.is_editing_discord_id}
              handleToggleFunc={(newMode) => {
                handleLockEdits(newMode);
              }}
            />

            <PlayerPool
              players={remainingPlayers}
              isDragDisabled={isDragDisabled}
            />
          </Box>
        )}
        <PartyGroup
          isManagement={isManagement}
          handleDeleteParty={handleDeleteParty}
          guildData={guildData}
          isDragDisabled={isDragDisabled}
          parties={parties}
          updatePartyName={updatePartyName}
        />

        {parties.length < 8 && isManagement && !isDragDisabled && (
          <PartyAdd
            onClick={() => {
              openPartyCreate.onTrue();
            }}
          />
        )}
      </Box>
      <CustomDialog
        open={openPartyCreate.value}
        onClose={() => {
          openPartyCreate.onFalse();
          setNewPartyName('');
        }}
        title={`Create Party`}
        actions={
          <>
            <LoadingButton
              onClick={handleNewPartyCreate}
              variant="contained"
              color="inherit"
              fullWidth
              sx={{ mt: 2 }}
            >
              Create
            </LoadingButton>
            <LoadingButton
              onClick={openPartyCreate.onFalse}
              variant="contained"
              color="inherit"
              fullWidth
              sx={{ mt: 2 }}
            >
              Cancel
            </LoadingButton>
          </>
        }
      >
        <TextField
          slotProps={{
            input: {
              readOnly: !isManagement,
            },
          }}
          label="Party Name"
          variant="standard"
          fullWidth
          name="title"
          value={newPartyName}
          onChange={handleChange}
          // @ts-ignore

          // @ts-ignore

          margin="normal"
        />
      </CustomDialog>
    </DragDropContext>
  );
};

export default RaidMaker;
