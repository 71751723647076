// https://ashescodex.com/api/search
// {"query":"pick"}

export async function postQueryToAshesCodex({ query }) {
  try {
    const response = await fetch('https://ashescodex.com/api/search', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ query }),
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.status}`);
    }

    const data = await response.json();
    console.log(data);
    return data;
  } catch (error) {
    console.error('Error posting data:', error);
  }
}
