import { useEffect, useState } from 'react';

import { LoadingScreen } from 'components/other/LoadingScreen';
import { useAuth } from 'context/AuthContext';
import { useRouter } from 'hooks/use-router';
import { useLocation } from 'react-router-dom';

// ----------------------------------------------------------------------

export function AuthGuard({ children }) {
  const router = useRouter();

  const { user, loading } = useAuth();
  const location = useLocation();

  const [isChecking, setIsChecking] = useState(true);

  const checkPermissions = async () => {
    if (loading) {
      return;
    }

    if (user === null) {
      const redirectUrl = encodeURIComponent(
        location.pathname + location.search
      );
      router.replace(`/home?redirect_url=${redirectUrl}`);
      return;
    }

    setIsChecking(false);
  };

  useEffect(() => {
    checkPermissions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, loading]);

  if (isChecking) {
    return <LoadingScreen text={'Loading Auth'} />;
  }

  return <>{children}</>;
}

export default AuthGuard;
