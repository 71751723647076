import axios from 'axios';
import { useAuth } from 'context/AuthContext';
import { createContext, useContext } from 'react';

// Load webhooks from .env
const DISCORD_WEBHOOKS = {
  database: process.env.REACT_APP_DATABASE_LOGS,
  ui: process.env.REACT_APP_UI_LOGS,
};

// Validate webhook presence
const validateWebhook = (webhook) => {
  
  if (!webhook) {
    console.error('Webhook URL is not defined in the .env file.');
    throw new Error('Webhook URL is not defined.');
  }
};

// Function to format log messages with highlighting
const formatMessage = (message, level, category, user) => {
  const emoji = {
    info: 'ℹ️',
    warn: '⚠️',
    error: '❌',
    success: '✅',
    default: '📌',
  };

  const levelHighlight = `**[${level.toUpperCase()}]**`;
  const categoryHighlight = `__${category.toUpperCase()}__`;

  return `${emoji[level] || emoji.default} ${levelHighlight} ${categoryHighlight}: \`${message}\` ${user.user_metadata.full_name}(${user.user_metadata.provider_id})`;
};

// Send a log to Discord webhook
const sendLog = async (webhook, message, level, category, user) => {
  validateWebhook(webhook);

  const formattedMessage = formatMessage(message, level, category, user);

  try {
    await axios.post(webhook, {
      content: formattedMessage,
    });
  } catch (error) {
    console.error('Failed to send log to Discord:', error);
  }
};

// Logger context
const LoggerContext = createContext();

// Custom hook for using logger
export const useLogger = () => {
  const context = useContext(LoggerContext);
  if (!context) {
    throw new Error('useLogger must be used within a LoggerProvider');
  }
  return context;
};

// LoggerProvider component
export const LoggerProvider = ({ children }) => {
  const { user } = useAuth();

  const log = {
    database: {
      info: (message) =>
        sendLog(DISCORD_WEBHOOKS.database, message, 'info', 'database', user),
      warn: (message) =>
        sendLog(DISCORD_WEBHOOKS.database, message, 'warn', 'database', user),
      error: (message) =>
        sendLog(DISCORD_WEBHOOKS.database, message, 'error', 'database', user),
    },
    ui: {
      info: (message) =>
        sendLog(DISCORD_WEBHOOKS.ui, message, 'info', 'ui', user),
      warn: (message) =>
        sendLog(DISCORD_WEBHOOKS.ui, message, 'warn', 'ui', user),
      error: (message) =>
        sendLog(DISCORD_WEBHOOKS.ui, message, 'error', 'ui', user),
    },
  };

  return (
    <LoggerContext.Provider value={log}>{children}</LoggerContext.Provider>
  );
};
