import { useData } from 'context/DataContext';
import { lazy, Suspense } from 'react';
import { Outlet, useRoutes } from 'react-router-dom';

import { STRINGS } from 'components/config';
import AuthGuard from 'components/guards/AuthGuard';
import GuildGuard from 'components/guards/GuildGuard';
import RoleGuard from 'components/guards/RoleGuard';
import UserSettingGuard from 'components/guards/UserSettingsGuard';

import { GuildApplicationForm } from 'components/guildHelpers/GuildApplicationForm';
import AuctionPage from 'components/guildPages/Auctions';
import GuildCrafts from 'components/guildPages/crafting/GuildCrafts';
import { GuildDataLog } from 'components/guildPages/DataLog';
import EventAttendance from 'components/guildPages/EventAttendance';
import Events from 'components/guildPages/Events';
import DynamicGoalStepper from 'components/guildPages/goals/goalsMain';
import GuildApplications from 'components/guildPages/GuildApplications';
import GuildConfig from 'components/guildPages/GuildConfig';
import RaidMaker from 'components/guildPages/raidMaker/RaidMaker';
import HomePage from 'components/HomePage';
import { LoadingScreen } from 'components/other/LoadingScreen';
import Maintenance from 'components/other/Maintenance';
import PageNotFound from 'components/other/PageNotFound';

const { PATHS } = STRINGS;

// Lazy-loaded components
const MemberList = lazy(() => import('components/guildPages/MemberList'));
const TradeHub = lazy(() => import('components/guildPages/TradeHub/TradeHub'));
const RaidList = lazy(() => import('components/guildPages/raidMaker/RaidList'));

// Layout for Protected Routes
const layoutContent = (
  <Suspense fallback={<LoadingScreen text="Loading Visuals" />}>
    <Outlet />
  </Suspense>
);

const AppRoutes = () => {
  const { userSettings } = useData();
  const userRole = userSettings?.user_role?.toUpperCase(); // Get user's role

  const routes = [
    // ---------------------------
    // Unprotected Routes
    // ---------------------------
    {
      path: '/home',
      element: <HomePage />,
    },
    {
      path: PATHS.maintenance,
      element: <Maintenance />,
    },
    {
      path: '*',
      element: <PageNotFound />,
    },

    // ---------------------------
    // Semi-Protected Routes
    // ---------------------------
    {
      path: PATHS.applications.root,
      children: [
        {
          index: true,
          element: (
            <AuthGuard>
              <UserSettingGuard>
                <RoleGuard
                  allowedRoles={['ADMIN', 'OWNER', 'MEMBER']}
                  userRole={userRole}
                >
                  <GuildApplications />
                </RoleGuard>
              </UserSettingGuard>
            </AuthGuard>
          ),
        },
        {
          path: ':guild_discord_server_id/:guild_discord_invite_link',
          element: (
            <AuthGuard>
              <UserSettingGuard>
                <RoleGuard
                  allowedRoles={['ADMIN', 'OWNER', 'MEMBER']}
                  userRole={userRole}
                >
                  <GuildApplicationForm />
                </RoleGuard>
              </UserSettingGuard>
            </AuthGuard>
          ),
        },
      ],
    },

    // ---------------------------
    // Fully Protected Routes
    // ---------------------------
    {
      path: '/',
      element: (
        <AuthGuard>
          <UserSettingGuard>
            <GuildGuard>{layoutContent}</GuildGuard>
          </UserSettingGuard>
        </AuthGuard>
      ),
      children: [
        { path: PATHS.config, element: <GuildConfig /> },
        {
          path: PATHS.members,
          element: (
            <RoleGuard allowedRoles={PATHS.PERMS.MEMBERS} userRole={userRole}>
              <MemberList />
            </RoleGuard>
          ),
        },
        {
          path: PATHS.trades.root,
          children: [
            {
              index: true,
              element: (
                <RoleGuard
                  allowedRoles={PATHS.PERMS.TRADES}
                  userRole={userRole}
                >
                  <TradeHub />
                </RoleGuard>
              ),
            },
            {
              path: ':trade_id/',
              element: (
                <RoleGuard
                  allowedRoles={PATHS.PERMS.TRADES}
                  userRole={userRole}
                >
                  <TradeHub />
                </RoleGuard>
              ),
            },
          ],
        },
        {
          path: PATHS.logs,
          element: (
            <RoleGuard allowedRoles={PATHS.PERMS.LOGS} userRole={userRole}>
              <GuildDataLog />
            </RoleGuard>
          ),
        },
        {
          path: PATHS.goals,
          element: (
            <RoleGuard allowedRoles={PATHS.PERMS.GOALS} userRole={userRole}>
              <DynamicGoalStepper />
            </RoleGuard>
          ),
        },
        {
          path: PATHS.crafts,
          element: (
            <RoleGuard allowedRoles={PATHS.PERMS.CRAFTS} userRole={userRole}>
              <GuildCrafts />
            </RoleGuard>
          ),
        },
        {
          path: PATHS.events.root,
          children: [
            {
              index: true,
              element: (
                <RoleGuard
                  allowedRoles={PATHS.PERMS.EVENTS}
                  userRole={userRole}
                >
                  <Events />
                </RoleGuard>
              ),
            },
            {
              path: ':event_id/',
              element: (
                <RoleGuard
                  allowedRoles={PATHS.PERMS.EVENTS}
                  userRole={userRole}
                >
                  <Events />
                </RoleGuard>
              ),
            },
            {
              path: ':event_id/attendance',
              element: (
                <RoleGuard
                  allowedRoles={PATHS.PERMS.EVENTS}
                  userRole={userRole}
                >
                  <EventAttendance />
                </RoleGuard>
              ),
            },
          ],
        },
        {
          path: PATHS.auctions,
          element: (
            <RoleGuard allowedRoles={PATHS.PERMS.AUCTIONS} userRole={userRole}>
              <AuctionPage />
            </RoleGuard>
          ),
        },
        {
          path: PATHS.raid.root,
          children: [
            {
              index: true,
              element: (
                <RoleGuard
                  allowedRoles={PATHS.PERMS.EVENTS}
                  userRole={userRole}
                >
                  <RaidList />
                </RoleGuard>
              ),
            },
            {
              path: ':raid_id/',
              element: (
                <RoleGuard allowedRoles={PATHS.PERMS.RAID} userRole={userRole}>
                  <RaidMaker />
                </RoleGuard>
              ),
            },
          ],
        },
      ],
    },
  ];

  // Generate the route hierarchy
  return useRoutes(routes);
};

export default AppRoutes;
