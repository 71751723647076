import { Box, Dialog, DialogContent, DialogTitle } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useState } from 'react';

const colorOptions = [
  // Primary Colors
  '#E63946', // Red
  '#457B9D', // Blue
  '#2A9D8F', // Green
  '#E9C46A', // Yellow
  '#6A4C93', // Purple
  '#F4A261', // Orange

  // Neutral Colors
  '#E5E5E5', // Light Gray
  '#3A3A3A', // Dark Gray
  '#F6F1EB', // Soft Beige

  // Pastel Colors
  '#FF6B6B', // Pastel Red
  '#A8DADC', // Pastel Blue
  '#B7E4C7', // Pastel Green
  '#FDE68A', // Pastel Yellow
  '#D3BFFF', // Pastel Purple

  // Bright Accent Colors
  '#00B4D8', // Cyan
  '#FF3CAC', // Magenta
  '#A4FBA6', // Lime Green
  '#FFD700', // Bright Gold

  // Themed Suggestions
  '#4C1A57', // Dungeon Runs (Dark Purple)
  '#D72638', // PvP Events (Crimson Red)
  '#003366', // Raid Events (Midnight Blue)
  '#F88379', // Community Events (Coral Pink)
];

const ColorPickerDialog = ({ open, onClose, onSelectColor, initialColor }) => {
  const [selectedColor, setSelectedColor] = useState(
    initialColor || colorOptions[0]
  );

  const handleColorClick = (color) => {
    setSelectedColor(color);
    onSelectColor(color); // Pass selected color to parent
    onClose(); // Close the dialog
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Select a Color</DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          {colorOptions.map((color, index) => (
            <Grid size={{ xs: 4, sm: 3 }} item key={index}>
              <Box
                onClick={() => handleColorClick(color)}
                style={{
                  width: '100%',
                  height: 50,
                  backgroundColor: color,
                  borderRadius: 4,
                  cursor: 'pointer',
                  border:
                    selectedColor === color
                      ? '2px solid black'
                      : '1px solid #ccc',
                }}
              />
            </Grid>
          ))}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default ColorPickerDialog;
