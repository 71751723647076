import { Chip, IconButton, Typography } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import { STRINGS } from 'components/config';

const {
  classIconMap,
  classColorMap,
  LINKS,
  attendanceColorMap,
  attendanceContextMap,
} = STRINGS;

export function renderAvatar(params) {
  if (params.value == null) {
    return '';
  }
  const handleClickDiscordUser = () =>
    window.open(`${LINKS.DISCORD.BASE_URL}/users/${params.row.discord_id}`);
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        height: '100%',
        outline: 'none !important',
      }}
    >
      <IconButton style={{ borderRadius: '30px' }}>
        <Avatar
          onClick={handleClickDiscordUser}
          alt={params.row.discord_display_name}
          src={params.value}
          sx={{
            // backgroundColor: params.value.color,
            width: '32px',
            height: '32px',
            fontSize: '0.85rem',
            // cursor:'mo'
            outline: 'none !important',
          }}
        />
      </IconButton>
    </div>
  );
}

export function renderClass(params) {
  if (params.value == null) {
    return '';
  }
  const color = classColorMap[params.value.toUpperCase()];
  const result = classIconMap[params.value.toUpperCase()];
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        height: '100%',
      }}
    >
      <Avatar
        alt={params.row.in_game_class}
        src={result}
        sx={{
          border: `2px solid ${color}`,
          width: '36px',
          height: '36px',
          // fontSize: '0.85rem',
        }}
      />
      <Typography sx={{ ml: 2, color: color, fontWeight: 'bold' }}>
        {params.row.in_game_class}
      </Typography>
    </div>
  );
}

export function renderAttendance(params) {
  if (params.value == null) {
    return '';
  }
  const color = attendanceColorMap[params.value.toUpperCase()];
  const result = attendanceContextMap[params.value.toUpperCase()];
  return <Chip label={result} color={color} variant="outlined" />;
}
