import AssignmentRoundedIcon from '@mui/icons-material/AssignmentRounded';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import PeopleRoundedIcon from '@mui/icons-material/PeopleRounded';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import { useData } from 'context/DataContext';
import GroupsIcon from '@mui/icons-material/Groups';
import SettingsIcon from '@mui/icons-material/Settings';
import ViewTimelineIcon from '@mui/icons-material/ViewTimeline';
import DiscordBotCard from 'components/discordBotCard';
import { FaList } from 'react-icons/fa';
import { RiAuctionFill } from 'react-icons/ri';
import { NavLink, useLocation } from 'react-router-dom';
import { STRINGS } from './config';

const {
  PATHS: { PERMS },
} = STRINGS;

const filterItemsByRole = (items, user_role) => {
  return items.filter((item) => {
    const isAdminOrOwner = item.perms?.includes(user_role.toUpperCase());
    return !item.perms || isAdminOrOwner;
  });
};

const navBarListItems = [
  {
    text: 'Home',
    icon: <HomeRoundedIcon />,
  },
  {
    text: 'Members',
    icon: <PeopleRoundedIcon />,
    perms: PERMS.MEMBERS,
  },
  {
    text: 'Goals',
    icon: <PeopleRoundedIcon />,
    perms: PERMS.GOALS,
  },
  {
    text: 'Crafts',
    icon: <ViewTimelineIcon />,
    perms: PERMS.CRAFTS,
  },
  {
    text: 'Raid',
    icon: <GroupsIcon />,
    perms: PERMS.RAID,
  },
  {
    text: 'Events',
    icon: <CalendarMonthIcon />,
    perms: PERMS.EVENTS,
  },
  {
    text: 'Auctions',
    icon: <RiAuctionFill />,
    perms: PERMS.AUCTIONS,
  },
  {
    text: 'Trades',
    icon: <RiAuctionFill />,
    perms: PERMS.TRADES,
  },
  {
    text: 'Logs',
    icon: <FaList />,
    perms: PERMS.LOGS,
  },
  {
    text: 'Applications',
    icon: <AssignmentRoundedIcon />,
    perms: PERMS.APPLICATIONS,
  },
  {
    text: 'Config',
    icon: <SettingsIcon />,
    perms: PERMS.CONFIG,
  },
  // { text: 'Analytics', icon: <AnalyticsRoundedIcon /> },
];

//const secondaryListItems = [
// { text: 'Settings', icon: <SettingsRoundedIcon /> },
// { text: 'About', icon: <InfoRoundedIcon /> },
// { text: 'Feedback', icon: <HelpRoundedIcon /> },
//];

export default function MenuContent() {
  // @ts-ignore
  const { userSettings, guildData } = useData();
  const location = useLocation();

  if (!userSettings) {
    return null;
  }
  const { user_role } = userSettings;
  const filteredListItems = filterItemsByRole(navBarListItems, user_role);

  const showBotCard =
    guildData &&
    ['OWNER'].includes(user_role.toUpperCase()) &&
    guildData.verified === false;

  return (
    <Stack sx={{ flexGrow: 1, p: 1, justifyContent: 'space-between' }}>
      <List dense>
        {filteredListItems.map((item, index) => {
          return (
            <NavLink
              key={index}
              to={item.text.toLowerCase()}
              style={{ textDecoration: 'none', color: 'inherit' }} // Removes NavLink styles
            >
              <ListItem disablePadding sx={{ display: 'block', pb: '4px' }}>
                <ListItemButton
                  selected={location.pathname === '/' + item.text.toLowerCase()}
                >
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.text} />
                </ListItemButton>
              </ListItem>
            </NavLink>
          );
        })}
      </List>

      <List>
        {showBotCard && (
          <DiscordBotCard
            botName={'Ashes Of Creation Guild Manager'}
            botDescription={
              'For full functionality add the discord bot to your server and get access to a variety of /slash commands'
            }
            guildImage={guildData?.guild_avatar}
            botImage={
              'https://cdn.discordapp.com/icons/1295324299892363294/8ec38d152dc83ddaabf479ad97e1f532.webp?size=96'
            }
            inviteLink={
              'https://discord.com/oauth2/authorize?client_id=1304393157575970816&permissions=581429050338385&integration_type=0&scope=bot+applications.commands'
            }
          />
        )}
        {/* {secondaryListItems?.map((item, index) => (
          <ListItem key={index} disablePadding sx={{ display: 'block' }}>
            <ListItemButton>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItemButton>
          </ListItem>
        ))} */}
      </List>
    </Stack>
  );
}
