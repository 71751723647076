import { Box } from '@mui/material';

const Embedded = ({ url }) => {
 
  //   document.getElementById('iframe-id').contentWindow.postMessage(
  //     {
  //       type: 'applyStyles',
  //       styles: { backgroundColor: '#333', color: '#fff' },
  //     },
  //     'https://ashescodex.com'
  //   );

  //   window.addEventListener('message', (event) => {
  //     if (event.origin !== 'https://your-domain.com') return;
  //     const { type, styles } = event.data;
  //     if (type === 'applyStyles') {
  //       Object.assign(document.body.style, styles);
  //     }
  //   });

  return (
    <Box
      sx={{
        width: '100%',
        height: '400px', // Adjust height as needed
        // border: '1px solid #ccc',
        borderRadius: '8px',
        overflow: 'hidden',
      }}
    >
      <iframe
        id={'iframe-id'}
        src={url}
        width="100%"
        height="100%"
        style={{
          border: 'none',
        }}
        title="Ashes Codex Map"
        loading="lazy"
      ></iframe>
    </Box>
  );
};

export default Embedded;
