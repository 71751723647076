import { useState, useEffect } from 'react';

const useInactivity = (timeoutDuration = 60000) => {
  const [isInactive, setIsInactive] = useState(false);

  useEffect(() => {
    let timeout;

    const resetTimeout = () => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        setIsInactive(true); // Trigger inactivity action
      }, timeoutDuration);
    };

    const handleActivity = () => {
      if (isInactive) setIsInactive(false); // Reset inactivity state
      resetTimeout();
    };

    // Add event listeners for user activity
    window.addEventListener('mousemove', handleActivity);
    window.addEventListener('keydown', handleActivity);
    window.addEventListener('scroll', handleActivity);
    window.addEventListener('click', handleActivity);

    // Initialize the timer
    resetTimeout();

    // Cleanup on unmount
    return () => {
      clearTimeout(timeout);
      window.removeEventListener('mousemove', handleActivity);
      window.removeEventListener('keydown', handleActivity);
      window.removeEventListener('scroll', handleActivity);
      window.removeEventListener('click', handleActivity);
    };
  }, [isInactive, timeoutDuration]);

  return isInactive;
};

export default useInactivity;
