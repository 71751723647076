import { Box, Typography, Button } from '@mui/material';
import { styled } from '@mui/system';
import { STRINGS } from 'components/config';
import { FaDiscord } from 'react-icons/fa';
import ConstructionIcon from '@mui/icons-material/Construction';

const { LINKS } = STRINGS;

const Background = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100vh',
  padding: theme.spacing(4),
  textAlign: 'center',
}));

function Maintenance() {
  return (
    <Background>
      <ConstructionIcon style={{ height: '40px', width: '40px' }} />

      <Typography variant="h4" component="h1" gutterBottom>
        {`We'll Be Back Soon!`}
      </Typography>
      <Typography variant="body1" gutterBottom>
        {`Our website is currently undergoing maintenance. We apologise for the inconvenience. Please check back
        later, discord will have more info.`}
      </Typography>
      <Typography variant="body2" color="textSecondary" gutterBottom>
        If you have any urgent queries, please reach out to us.
      </Typography>
      <Button
        variant="contained"
        color="primary"
        startIcon={<FaDiscord />}
        href={LINKS.DISCORD.OFFICIAL_LINK_INVITE}
        sx={{ marginTop: 2 }}
      >
        Join the Discord
      </Button>
    </Background>
  );
}

export default Maintenance;
