import { Box, Button, Drawer, Typography } from '@mui/material';
import { postQueryToAshesCodex } from 'api/ashes-codex';
import { useState } from 'react';

const DevOverlay = () => {
  const [isOverlayOpen, setOverlayOpen] = useState(false);

  const toggleOverlay = () => {
    setOverlayOpen(!isOverlayOpen);
  };

  return (
    <>
      {/* Button in the top-right corner */}
      <Button
        onClick={toggleOverlay}
        variant="contained"
        color="primary"
        style={{
          position: 'fixed',
          bottom: 16,
          right: 16,
          zIndex: 1300, // Ensure it appears above other content
        }}
      >
        Dev Tools
      </Button>

      {/* Overlay Drawer */}
      <Drawer
        anchor="right"
        open={isOverlayOpen}
        onClose={toggleOverlay}
        PaperProps={{
          style: {
            width: '300px',
            padding: '16px',
          },
        }}
      >
        <Box>
          <Typography variant="h6">Dev Tools</Typography>
          <Typography variant="body2" color="textSecondary">
            Add your development components or tools here.
          </Typography>
          {/* Add your dev functionality below */}
          <Box mt={2}>
            <Button
              onClick={() => postQueryToAshesCodex({ query: 'wood' })}
              variant="outlined"
              color="secondary"
            >
              Ashes Codex Call
            </Button>
            <Button variant="outlined" color="secondary">
              Do someting
            </Button>
            <Button variant="outlined" color="secondary">
              Do someting
            </Button>
          </Box>
        </Box>
      </Drawer>
    </>
  );
};

export default DevOverlay;
