// @ts-ignore
import { Button, Chip, Stack, Typography } from '@mui/material';
// @ts-ignore

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { CustomBarStackChart } from 'components/charts/CustomBarStackChart';
import CustomPieChart from 'components/charts/CustomPieChart';
import GuildAttendanceGrid from 'components/charts/GuildAttendanceGrid';
import { STRINGS } from 'components/config';
import { useData } from 'context/DataContext';
import { useToast } from 'context/ToastContext';
import dayjs from 'dayjs';
import { useParams } from 'hooks/use-params';
import { useState } from 'react';
const { eventStatusColorMap } = STRINGS;
function countByKey(data, key) {
  // Count the occurrences of each unique value for the given key
  if (data) {
    const keyCounts = data.reduce((counts, item) => {
      const keyValue = item[key];
      counts[keyValue] = (counts[keyValue] || 0) + 1;
      return counts;
    }, {});

    // Convert counts to the desired array format
    return Object.entries(keyCounts).map(([label, value]) => ({
      label,
      value,
    }));
  }
  return [];
}

const EventAttendance = () => {
  const [filterModel, setFilterModel] = useState({
    items: [],
  });
  // @ts-ignore
  const params = useParams();
  const toast = useToast();

  const { guildData } = useData();
  if (!guildData) {
    return null;
  }

  const { event_id } = params;

  const eventFind = guildData.Guild_Events.find(
    (event) => event.event_id === event_id
  );
  if (!eventFind) {
    return null;
  }
  if (eventFind?.Guild_Event_Attendance === undefined) {
    return null;
  }

  const filteredArray = guildData?.Guild_User_Manage?.filter((item) =>
    eventFind.Guild_Event_Attendance.some(
      (discordObj) => discordObj.discord_id === item.discord_id
    )
  );

  const flattenedData = filteredArray.map((item) => {
    const { Users, ...restOfItem } = item; // Destructure to exclude `Users`

    return {
      ...restOfItem, // Include other properties of `item`
      ...Users, // Spread properties of `Users`
      ...eventFind.Guild_Event_Attendance.find(
        (user) => user.discord_id === item.discord_id
      ), // Merge data from Guild_Event_Attendance
    };
  });

  const classData = countByKey(flattenedData, 'in_game_class');
  const classRoleData = countByKey(flattenedData, 'status');

  // Handle filter button click
  const applyFilter = ({ columnField, searchValue }) => {
    setFilterModel({
      items: [
        {
          field: columnField,
          operator: 'contains',
          value: searchValue,
        },
      ],
    });
  };

  if (!guildData.Guild_User_Manage) {
    return null;
  }

  return (
    <Stack
      direction="column"
      sx={{
        height: '100%',
        flex: 1,

        justifyContent: 'space-between',
      }}
    >
      <Stack direction="row" sx={{ pb: 1 }}>
        <Typography variant="h5">{`Event > ${eventFind?.title}`}</Typography>
        <Chip
          sx={{ ml: 2 }}
          label={eventFind.event_status}
          color={eventStatusColorMap[eventFind.event_status]}
        />
        <Typography sx={{ ml: 2 }} variant="h5">
          {dayjs(eventFind.start).format('MMMM D, YYYY [at] h:mm A')} -{' '}
          {dayjs(eventFind.end).format('MMMM D, YYYY [at] h:mm A')}
        </Typography>
        <Button
          variant="outlined"
          endIcon={<ContentCopyIcon />}
          onClick={() => {
            navigator.clipboard.writeText(event_id);
            toast.success('Copied');
          }}
          sx={{ ml: 'auto', mr: 0 }}
        >
          Copy ID
        </Button>
      </Stack>
      <Stack
        direction="row"
        sx={{
          height: '90%',
          flex: 1,
          gap: 1,
          justifyContent: 'space-between',
        }}
      >
        <GuildAttendanceGrid
          setFilterModel={setFilterModel}
          data={flattenedData}
          filterModel={filterModel}
        />
        <Stack
          direction="column"
          sx={{
            gap: 1,
            height: '100%',
            display: 'flex',
            flex: 1,
            // width: '100%',
          }}
        >
          {/* <ChartUserByCountry title={'CLASS'} data={classData} /> */}
          <CustomBarStackChart data={classData} applyFilter={applyFilter} />
          <CustomPieChart
            title={'CLASS ROLE'}
            data={classRoleData}
            applyFilter={applyFilter}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};
// TODO: ADD HEAT MAP OF CLASS VS CLASS_ROLE

export default EventAttendance;
