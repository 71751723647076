import LoadingButton from '@mui/lab/LoadingButton';
import {
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from '@mui/material';
import { STRINGS } from 'components/config';
import CustomDialog from 'components/customDialog/customDialog';
import { fetchGuildInfoWithCodeOnly } from 'components/guildHelpers/discordInfoFetch';
import { useData } from 'context/DataContext';
import { useToast } from 'context/ToastContext';
import { useRouter } from 'hooks/use-router';
import { useEffect, useState } from 'react';
import DiscordPreviewBanner from './DiscordPreviewBanner';
import { Padding } from '@mui/icons-material';

const { classRoleMap, classNameMap, PATHS } = STRINGS;

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
      // Padding: '4px',
    },
  },
};

const UserProfileForm = ({ open, onClose, action, title, params }) => {
  // @ts-ignore
  const [form, setForm] = useState({
    in_game_name: '',
    in_game_class: '',
    in_game_class_role: '',
  });
  const [isBlocking, setIsBlocking] = useState(false);
  const [errors, setErrors] = useState({});
  const router = useRouter();
  const toast = useToast();
  const [discordGuildInfo, setDiscordGuildInfo] = useState(null);
  const { updateUserSettings, createGuildApplication, userSettings } =
    useData();

  useEffect(() => {
    setForm({
      in_game_name: userSettings?.in_game_name,
      in_game_class: userSettings?.in_game_class,
      in_game_class_role: userSettings?.in_game_class_role,
    });

    return () => {};
  }, [userSettings]);

  useEffect(() => {
    async function checkDiscord() {
      const res = await fetchGuildInfoWithCodeOnly(
        params.guild_discord_invite_link
      );
      setDiscordGuildInfo(res);
    }
    if (open && params?.guild_discord_server_id) {
      checkDiscord();
    }
    return () => {};
  }, [open]);

  useEffect(() => {
    //redirect if they have a guild
    const pathIncludesApplication = router.path?.includes('/applications');
    const doesHaveGuild = ['GUILD_MEMBER', 'OWNER', 'ADMIN'].includes(
      userSettings?.user_role.toUpperCase()
    );
    if (doesHaveGuild && pathIncludesApplication) {
      toast.error('YOU ALREADY HAVE A GUILD');
      router.push(PATHS.home);
      return () => {};
    }
    return () => {};
  }, []);

  if (!userSettings) {
    return <></>;
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm((prev) => ({ ...prev, [name]: value }));
    if (errors[name]) {
      setErrors((prev) => ({ ...prev, [name]: '' }));
    }
  };

  const validate = () => {
    const newErrors = {};
    if (!form.in_game_name)
      newErrors.guild_display_name = 'In game name is required';
    if (!form.in_game_class) newErrors.guild_description = 'Class required';
    if (!form.in_game_class_role)
      newErrors.guild_discord_invite_link = 'Class Role required';
    return newErrors;
  };

  const handleClick = async (e) => {
    e.preventDefault();
    const newErrors = validate();
    let responseGuild;
    let responseUser;
    if (Object.keys(newErrors).length === 0) {
      setIsBlocking(true);
      // APPLY
      if (action === 'APPLY') {
        responseGuild = await createGuildApplication({
          ...form,
          guild_discord_server_id: discordGuildInfo.guild.id,
        });
        responseUser = await updateUserSettings({ ...form });
        if (responseGuild.success && responseUser.success) {
          toast.success('Applied');
          setIsBlocking(false);
          onClose();
          return router.push(PATHS.applications.root);
        } else {
          toast.error('Failed');
          return;
        }
      }

      // SAVE
      if (action === 'SAVE') {
        responseUser = await updateUserSettings({ ...form });
      }
      if (responseUser.success) {
        toast.success('Updated');
        return setTimeout(() => {
          setIsBlocking(false);
          onClose();
        }, 600);
      }

      toast.error(`Failed - ${responseGuild.errorCode}`);
      setIsBlocking(false);
    } else {
      return setErrors(newErrors);
    }
  };

  // discordGuildInfo

  return (
    <CustomDialog
      open={open}
      title={title}
      onClose={onClose}
      actions={
        <LoadingButton
          type="submit"
          onClick={(e) => handleClick(e)}
          variant="contained"
          color="inherit"
          fullWidth
          sx={{ mt: 2 }}
          loading={isBlocking}
        >
          {action === 'APPLY' ? 'APPLY' : 'SAVE'}
        </LoadingButton>
      }
    >
      {action === 'APPLY' && discordGuildInfo && (
        <DiscordPreviewBanner data={discordGuildInfo} />
      )}
      <form onSubmit={(e) => handleClick(e)}>
        <TextField
          label="In Game Name"
          variant="standard"
          fullWidth
          name="in_game_name"
          value={form?.in_game_name || ''}
          onChange={handleChange}
          // @ts-ignore
          error={!!errors.in_game_name}
          // @ts-ignore
          helperText={errors.in_game_name}
          margin="normal"
        />

        <InputLabel id="in_game_class-label">Class</InputLabel>
        <Select
          style={{ outline: 'none' }}
          labelId="in_game_class-label"
          id="in_game_class"
          name="in_game_class"
          label="Class"
          value={form?.in_game_class || ''}
          onChange={handleChange}
          MenuProps={MenuProps}
          sx={{ width: '100%' }}
        >
          {Object.entries(classNameMap).map(([key, value]) => (
            <MenuItem key={key} value={key}>
              {value.toUpperCase()}
            </MenuItem>
          ))}
        </Select>

        <InputLabel id="in_game_class_role-label">Class role</InputLabel>
        <Select
          style={{ outline: 'none' }}
          labelId="in_game_class_role-label"
          id="in_game_class_role"
          name="in_game_class_role"
          label="Class role"
          value={form?.in_game_class_role || ''}
          onChange={handleChange}
          MenuProps={MenuProps}
          sx={{ width: '100%' }}
        >
          {Object.entries(classRoleMap).map(([key, value]) => (
            <MenuItem key={key} value={key} className="removeOutline">
              {value.toUpperCase()}
            </MenuItem>
          ))}
        </Select>
      </form>
    </CustomDialog>
  );
};

export default UserProfileForm;
