import AddIcon from '@mui/icons-material/Add'; // Import the Add icon
import { Box, Card, IconButton } from '@mui/material';

const PLAYER_CARD_WIDTH = 100;
const PLAYER_CARD_HEIGHT = 50;
const PARTY_PADDING = 2;
const PARTY_WIDTH = 170;
const PARTY_HEIGHT = '100%';

const PartyAdd = ({ onClick }) => (
  <Box
    sx={{
      display: 'flex',
      gap: 2,
      height: '100%',
      pl: 2,
    }}
  >
    <Card
      sx={{
        flex: '0 0 auto',
        width: PARTY_WIDTH,
        height: PARTY_HEIGHT,
        borderRadius: 2,
        p: PARTY_PADDING,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: PARTY_PADDING,
        justifyContent: 'center', // Center content vertically
      }}
    >
      {/* Centered Plus Icon */}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: 48,
          height: 48,
          borderRadius: '20%',
          border: '2px dashed grey', // Optional: dashed border for a button-like appearance
          cursor: 'pointer',
        }}
      >
        <IconButton
          onClick={() => {
            onClick();
          }}
        >
          <AddIcon fontSize="large" sx={{ color: 'grey' }} />
        </IconButton>
      </Box>
    </Card>
  </Box>
);

export default PartyAdd;
