import TextField from '@mui/material/TextField';

const CustomNumberInput = ({
  label,
  value,
  onChange,
  min = 0,
  max = 99999,
  name,
  helperText,
  variant
}) => {
  const handleChange = (event) => {
    const inputValue = event.target.value;
    const name = event.target.name;

    // Remove all non-numeric characters (except for an optional leading minus for negative numbers)
    const sanitizedValue = inputValue.replace(/[^0-9]/g, '');

    // Allow empty value (to let user clear the input)
    if (sanitizedValue === '') {
      onChange(name, '');
      return;
    }

    // Convert the sanitized value to a number
    const numericValue = Number(sanitizedValue);

    // Enforce min and max constraints
    if (numericValue < min) {
      onChange(name, min);
    } else if (numericValue > max) {
      onChange(name, max);
    } else {
      onChange(name, numericValue);
    }
  };

  return (
    <TextField
      name={name}
      type="text"
      label={label}
      value={value}
      onChange={handleChange}
      //   error={error}
      helperText={helperText}
      variant={variant}
      fullWidth
      margin="normal"
      onBlur={handleChange}
    />
  );
};

export default CustomNumberInput;
