import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Chip,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useState } from 'react';

const auctionItems = [
  // {
  //   id: 1,
  //   name: 'Vintage Watch',
  //   image: 'https://via.placeholder.com/200', // Replace with actual image URL
  //   currentBid: '$250',
  //   tags: ['Classic', 'Luxury', 'Antique'],
  // },
  // {
  //   id: 2,
  //   name: 'Painting by XYZ',
  //   image: 'https://via.placeholder.com/200',
  //   currentBid: '$1,000',
  //   tags: ['Art', 'Masterpiece', 'Gallery'],
  // },
  // {
  //   id: 3,
  //   name: 'Handcrafted Vase',
  //   image: 'https://via.placeholder.com/200',
  //   currentBid: '$300',
  //   tags: ['Craft', 'Ceramic', 'Unique'],
  // },
  // {
  //   id: 4,
  //   name: 'Leather Jacket',
  //   image: 'https://via.placeholder.com/200',
  //   currentBid: '$150',
  //   tags: ['Fashion', 'Leather', 'Vintage'],
  // },
  // {
  //   id: 5,
  //   name: 'Antique Lamp',
  //   image: 'https://via.placeholder.com/200',
  //   currentBid: '$500',
  //   tags: ['Home Decor', 'Antique', 'Vintage'],
  // },
  // {
  //   id: 6,
  //   name: 'Sculpture by Artist A',
  //   image: 'https://via.placeholder.com/200',
  //   currentBid: '$2,500',
  //   tags: ['Art', 'Sculpture', 'Modern'],
  // },
  // {
  //   id: 7,
  //   name: 'Gold Necklace',
  //   image: 'https://via.placeholder.com/200',
  //   currentBid: '$1,500',
  //   tags: ['Jewelry', 'Gold', 'Luxury'],
  // },
  // {
  //   id: 8,
  //   name: 'Vintage Camera',
  //   image: 'https://via.placeholder.com/200',
  //   currentBid: '$350',
  //   tags: ['Photography', 'Vintage', 'Collectible'],
  // },
];

const AuctionPage = () => {
  const [search, setSearch] = useState('');

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const filteredItems = auctionItems.filter((item) =>
    item.name.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <Box sx={{}}>
      {/* Search Bar */}
      <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
        <Button variant="outlined" color="primary" sx={{ height: '100%' }}>
          Clear search
        </Button>
        <TextField
          label="Search items"
          variant="outlined"
          size="small"
          value={search}
          onChange={handleSearchChange}
          sx={{ flexGrow: 1 }}
        />
        <Button variant="outlined" color="primary" sx={{ height: '100%' }}>
          Create Auction
        </Button>
      </Box>

      {/* Auction Items Grid */}
      <Grid container spacing={3}>
        {filteredItems.map((item) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={item.id}>
            <Paper elevation={24}>
              <Card sx={{ height: '100%' }}>
                <CardMedia
                  component="img"
                  height="200"
                  image={item.image}
                  alt={item.name}
                />
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    {item.name}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    gutterBottom
                  >
                    Current Bid: {item.currentBid}
                  </Typography>
                  <Stack direction="row" spacing={1}>
                    {item.tags.map((tag, index) => (
                      <Chip label={tag} key={index} />
                    ))}
                  </Stack>
                </CardContent>
              </Card>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default AuctionPage;
