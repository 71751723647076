import { Box, Button, Container, Stack, Typography } from '@mui/material';
import { STRINGS } from 'components/config';
import { useAuth } from 'context/AuthContext';
import { FaDiscord } from 'react-icons/fa';
import { VscDebugRestart } from 'react-icons/vsc';
import { isDev } from 'utils/config';
import { useLogger } from 'utils/logger';

const { LINKS } = STRINGS;

const ErrorFallback = (props) => {
  const logger = useLogger();
  const { signOut } = useAuth();

  const { error, resetErrorBoundary } = props;

  if (error && !isDev) {
    logger.ui.error(`${props.error.stack.toString()} | ErrorFallback React`);
  }

  function handleClickDiscordSupport() {
    window.open(
      `${LINKS.DISCORD.BASE_URL}/channels/${LINKS.DISCORD.SERVER_ID}/${LINKS.DISCORD.CHANNEL.SUPPORT}`
    );
  }

  function handleReset() {
    sessionStorage.clear();
    signOut();
    return resetErrorBoundary();
  }

  return (
    <Container
      maxWidth="sm"
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        width: '100vw',
        textAlign: 'center',
      }}
    >
      <Box
        sx={{
          p: 3,
          border: '2px solid white',
          borderRadius: '8px',
          mb: 2,
          backgroundColor: 'hsl(220, 30%, 6%);',
        }}
      >
        <Typography variant="h4" color="white" gutterBottom>
          If you see this something went wrong. Please contact support, then
          press the refresh button
        </Typography>
        <Stack
          sx={{
            gap: 2,
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Button
            variant="outlined"
            color="inherit"
            onClick={handleClickDiscordSupport}
            style={{ fontWeight: 'bold', width: '50%' }}
            startIcon={<FaDiscord />}
          >
            Contact Discord Support
          </Button>
          <Button
            variant="outlined"
            color="inherit"
            onClick={handleReset}
            style={{ fontWeight: 'bold', width: '50%' }}
            startIcon={<VscDebugRestart />}
          >
            Refresh Data & Reload
          </Button>
        </Stack>
      </Box>
    </Container>
  );
};

export default ErrorFallback;
