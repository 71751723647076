import { Avatar, Box, Card, Typography } from '@mui/material';
import { CustomAvatar } from 'components/ui';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { SiBlockbench } from 'react-icons/si';

const PLAYER_CARD_WIDTH = 160;
const PLAYER_CARD_HEIGHT = 100;

export const PlayerCard = ({ provided, player }) => {
  return (
    <Card
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      sx={{
        mb: 1,
        width: PLAYER_CARD_WIDTH,
        height: PLAYER_CARD_HEIGHT,
        display: 'flex',
        flexDirection: 'row', // Arrange items horizontally
        alignItems: 'flex-start', // Align items to the top
        bgcolor: '#e0f7fa',
        mx: 'auto',
        p: 2, // Adds padding for better spacing
      }}
    >
      {/* Left Section: Text */}
      <Box
        flex="1"
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        justifyContent="flex-start"
      >
        <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1 }}>
          {player.Users.in_game_name}
        </Typography>
        <Typography variant="body2">{player.Users.in_game_class}</Typography>
      </Box>

      {/* Right Section: Media */}
      <Box
        display="flex"
        flexDirection="column"
        alignItems="flex-end"
        justifyContent="flex-start"
      >
        <Avatar
          alt={player.Users.discord_avatar}
          src={player.Users.discord_avatar}
          sx={{
            width: 30,
            height: 30,
            border: '2px solid #757575',
            mb: 1,
          }}
        />
        <CustomAvatar
          width="30px"
          height="30px"
          in_game_class={player.Users.in_game_class}
        />
      </Box>
    </Card>
  );
};

const PlayerPool = ({ players, isDragDisabled }) => (
  <Droppable droppableId="playerPool" direction="vertical">
    {(provided) => (
      <Box
        sx={{ textAlign: 'center' }}
        style={{
          height: '100%',
          // width: '100%',
          // border: '1px dashed grey',
          // borderRadius: '8px',
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          // justifyContent: 'center',
        }}
      >
        <SiBlockbench size={50} />
        <Typography variant="h6" sx={{ mb: 1 }}>
          {players.length} RESERVE BENCH
        </Typography>

        <Box
          ref={provided.innerRef}
          {...provided.droppableProps}
          sx={{
            width: '100%',
            height: '100%',
            borderRadius: 2,
            overflowY: 'auto',
            p: 1,
            // border: '1px solid #ccc',
            // bgcolor: '#f9f9f9',
            border: '1px dashed grey',
          }}
        >
          {players?.map((player, index) => (
            <Draggable
              isDragDisabled={isDragDisabled}
              key={player.discord_id}
              draggableId={player.discord_id}
              index={index}
            >
              {(provided) => <PlayerCard provided={provided} player={player} />}
            </Draggable>
          ))}
          {provided.placeholder}
        </Box>
      </Box>
    )}
  </Droppable>
);

export default PlayerPool;
