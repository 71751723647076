import { STRINGS } from 'components/config';
import { createContext, useContext, useState } from 'react';
import { getInitialData } from 'utils/sessionStorage';

const { sessionStorageMap } = STRINGS;
// @ts-ignore
const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const [appData, setAppData] = useState(
    getInitialData(sessionStorageMap.appData)
  );
  const userLocale = navigator.language || 'en-US'; // Default to 'en-US' if locale is not found

  return (
    <AppContext.Provider value={{ appData, userLocale }}>
      {children}
    </AppContext.Provider>
  );
};

export const useApp = () => useContext(AppContext);
